import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { BaseComponent } from '../base.component';

declare let window: any;

@Component({
  selector: 'app-instagram-block',
  templateUrl: './instagram-block.component.html',
  styleUrls: ['./instagram-block.component.scss'],
})
export class InstagramBlockComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('crowdRiffScriptContainer', { static: true }) crowdRiffScriptContainer: ElementRef;

  public title: string;
  public hashtag: string;
  public crowdRiffId: string;
  public buttonLink: string;

  constructor(private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.crowdRiffId = this.componentObject.crowdriffId ? this.componentObject.crowdriffId : undefined;
    this.title = this.componentObject.title ? this.componentObject.title : '';
    this.hashtag = this.componentObject.hashtag ? this.componentObject.hashtag : '';
    this.buttonLink = this.componentObject.link ? this.componentObject.link : undefined;
  }

  ngAfterViewInit(): void {
    if (this.crowdRiffId) {
      if (this.utilsService.isBrowser()) {
        this.initInstafeed(this.crowdRiffId);
        if (window.crowdriff__gallery && !window.crowdriff__gallery.interval) {
          window.crowdriff__gallery.interval = setInterval(window.crowdriff__gallery.processQueue, 1000);
        }
      }
    }
  }

  initInstafeed(id: string): void {
    const crowdriffScript: HTMLScriptElement = document.createElement('script');
    crowdriffScript.id = id;
    crowdriffScript.async = true;
    crowdriffScript.src = `https://starling.crowdriff.com/js/crowdriff.js`;
    this.crowdRiffScriptContainer.nativeElement.appendChild(crowdriffScript);
  }

  ngOnDestroy(): void {
    if (this.utilsService.isBrowser() && window.crowdriff__gallery && window.crowdriff__gallery.rendered) {
      window.crowdriff__gallery.rendered = window.crowdriff__gallery.rendered.filter(
        (id: string) => id !== this.crowdRiffId.replace('-init__', '__')
      );
    }
  }
}
