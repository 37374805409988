<section class="team-box" *ngIf="title || subtitle || image?.url">
  <div class="container">
    <h2 class="team-box__title" *ngIf="title">{{ title }}</h2>
    <p class="team-box__subtitle" *ngIf="subtitle">{{ subtitle }}</p>

    <div class="team-box__image-wrapper" *ngIf="image?.url">
      <a [href]="link" *ngIf="link" class="team-box__link"></a>
      <div
        class="team-box__image"
        [ngStyle]="{ 'background-image': 'url(' + image.url + ')' } | ssrEmptyString"
        corporateImageLazyLoad
        [attr.aria-label]="image?.image?.title"
        *ngIf="image?.url"
      ></div>
      <div class="team-box__content" *ngIf="imageTitle || imageSubtitle">
        <span class="team-box__image-title" *ngIf="imageTitle">{{ imageTitle }}</span>
        <span class="team-box__image-subtitle" *ngIf="imageSubtitle">{{ imageSubtitle }}</span>
      </div>
    </div>

    <a [href]="link" *ngIf="link" class="button button-secondary">
      <ng-container *ngIf="!linkText">{{ 'label.see-all' | translate }}</ng-container>
      <ng-container *ngIf="linkText">{{ linkText }}</ng-container>
    </a>
  </div>
</section>
