<div class="cardwdesc-wrapper" [ngClass]="{ 'full-width': isFullWidth }">
  <div class="detailed-card-top">
    <a *ngIf="data?.image && data?.routerLink" [routerLink]="data.routerLink" [attr.arial-label]="data?.image?.image?.title">
      <ng-container [ngTemplateOutlet]="cardTopContent" [ngTemplateOutletContext]="{ data: data }"></ng-container>
    </a>
    <a
      *ngIf="data?.image && data?.customUrl"
      [href]="data.customUrl"
      [target]="data.newTab ? '_blank' : ''"
      [attr.arial-label]="data?.image?.image?.title"
    >
      <ng-container [ngTemplateOutlet]="cardTopContent" [ngTemplateOutletContext]="{ data: data }"></ng-container>
    </a>

    <button *ngIf="data?.favorite" class="fav-button" (click)="favButtonAction()">
      <i class="icon icon-heart-empty-white" *ngIf="data.favorite === 'false'"></i>
      <i class="icon icon-heart-full-red" *ngIf="data.favorite === 'true'"></i>
    </button>
  </div>
  <div class="content-box" *ngIf="data?.title || data?.lead" [ngClass]="{ 'is-slider': isSlider }">
    <div class="title" *ngIf="data.title">{{ data.title }}</div>
    <div class="content" *ngIf="data.lead">{{ data.lead }}</div>
  </div>
  <div class="button-wrapper">
    <a
      *ngIf="data?.routerLink && !data?.customUrl"
      [routerLink]="data.routerLink"
      [attr.aria-label]="data.routerLink[1] + '/' + data.routerLink[2]"
      class="button button-text"
      [ngClass]="{ 'is-slider': isSlider }"
      [appBackgroundColor]="'white'"
    >
      <ng-container [ngTemplateOutlet]="button"></ng-container>
    </a>
    <a
      *ngIf="data?.routerLink && data?.customUrl"
      [href]="data.customUrl"
      [attr.aria-label]="data.customUrl"
      [target]="data.newTab ? '_blank' : ''"
      class="button button-text"
      [appBackgroundColor]="'white'"
    >
      <ng-container [ngTemplateOutlet]="button"></ng-container>
    </a>
  </div>
</div>

<ng-template #button>
  <span class="button-label">{{ 'label.read-more' | translate }}</span>
  <i class="icon icon-small icon-arrow-right-more-red icon-after"></i>
</ng-template>

<ng-template #cardTopContent let-data="data">
  <app-spice-of-europe-logo *ngIf="data?.isSpice"></app-spice-of-europe-logo>
  <div class="bg" [ngStyle]="{ 'background-image': 'url(' + data.image.url + ')' } | ssrEmptyString">
    <div class="bottom" *ngIf="tag">
      <div *ngIf="!data?.tag" class="tag" [appBackgroundColor]="tag.color">{{ tag.tag | translate }}</div>
      <div *ngIf="data?.tag" class="tag" [appBackgroundColor]="tag.color">{{ tag.tag }}</div>
    </div>
  </div>
</ng-template>
