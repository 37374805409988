import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api.service';

@Injectable()
export class BaseResolver implements Resolve<any> {
  constructor(private readonly apiService: ApiService) {}

  resolve(): Observable<any> | Promise<any> | any {
    return this.apiService.getMenuTreeIndex$().pipe(
      concatMap((res) => {
        if (res) {
          const groupKeys = Object.keys(res.data);
          return forkJoin([
            of(res),
            ...groupKeys.map((groupKey) =>
              this.apiService.getMenuTreeList$(groupKey).pipe(
                catchError((err) => of(err)),
                map((response) => ({ groupKey, ...response }))
              )
            ),
          ]);
        }
        return of(null);
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
