<section class="map desktop" id="{{ componentObject | titleToId }}">
  <div *ngIf="componentObject.thematicIcon" [ngClass]="'specicon ' + componentObject.thematicIcon.key" class="specicon"></div>
  <div class="map-container">
    <ng-container *ngIf="!isBudapest" [ngTemplateOutlet]="headSection"></ng-container>
    <div class="map-wrapper" [ngClass]="{ 'greater-budapest': !isBudapest, budapest: isBudapest }">
      <div class="background-container">
        <ng-container *ngIf="isBudapest" [ngTemplateOutlet]="headSection"></ng-container>
        <button *ngFor="let destination of destinations" class="city-button {{ destination?.className }}" (click)="navigate(destination)">
          <div class="icon" [ngClass]="destination?.thematicIcon?.key"></div>
          <div class="container">
            <span class="text">{{ destination?.title }}</span>
          </div>
        </button>
      </div>
    </div>
    <div class="button-container">
      <p class="text">
        {{ componentObject?.subComponents[0].mapIconSubtitle0 }}
        <br />
        {{ componentObject?.subComponents[0].mapIconSubtitle1 }}
        <br />
        {{ componentObject?.subComponents[0].mapIconSubtitle2 }}
      </p>
      <button class="change-button" (click)="changeMap()">
        <div class="poser">
          <div [ngClass]="{ 'greater-budapest': !isBudapest, budapest: isBudapest }"></div>
        </div>
      </button>
    </div>
  </div>
</section>

<section class="map mobile">
  <ng-container [ngTemplateOutlet]="headSection"></ng-container>
  <div class="head-container">
    <button class="info-button" [ngClass]="{ active: isBudapest }" (click)="changeMapMobile(true)">
      {{ 'label.budapest' | translate }}
    </button>
    <button class="info-button" [ngClass]="{ active: !isBudapest }" (click)="changeMapMobile(false)">
      {{ 'label.greater.budapest' | translate }}
    </button>
  </div>
  <div #carouselFirst class="map-carousel" [ngClass]="{ active: isBudapest }">
    <ng-container
      [ngTemplateOutlet]="infoWindow"
      [ngTemplateOutletContext]="{ cityData: destination }"
      *ngFor="let destination of destinationsBudapest"
    ></ng-container>
  </div>
  <div #carouselSecond class="map-carousel" [ngClass]="{ active: !isBudapest }">
    <ng-container
      [ngTemplateOutlet]="infoWindow"
      [ngTemplateOutletContext]="{ cityData: destination }"
      *ngFor="let destination of destinationsGreaterBudapest"
    ></ng-container>
  </div>
</section>

<ng-template #headSection>
  <div class="head-section">
    <h2>{{ componentObject?.title | uppercase }}</h2>
    <p class="description">{{ componentObject?.subtitle }}</p>
  </div>
</ng-template>

<ng-template #infoWindow let-cityData="cityData">
  <div class="info-window">
    <div class="icon" [ngClass]="cityData?.thematicIcon?.key"></div>
    <div class="info-container" (click)="navigate(cityData)">
      <p class="city-title">{{ cityData?.title }}</p>
    </div>
  </div>
</ng-template>
