export interface RoutesMap {
  Article: string;
  Collection: string;
  Destination: string;
  Trip: string;
  CategoryDescription: string;
  Event: string;
  DocumentsByGroup: string[];
  DocumentsByDestination: string[];
  Documents: string;
}

export const ROUTES_MAP: RoutesMap = {
  Article: 'articles',
  Collection: 'collection',
  Destination: 'destination',
  Trip: 'trips',
  CategoryDescription: 'category',
  Event: 'events',
  DocumentsByGroup: ['downloads', 'group'],
  DocumentsByDestination: ['downloads', 'destination'],
  Documents: 'downloads',
};
