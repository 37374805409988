import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { RouteMapService } from '../../services/route-map.service';

declare let $: any;

@Component({
  selector: 'app-trip-program',
  templateUrl: './trip-program.component.html',
  styleUrls: ['./trip-program.component.scss'],
})
export class TripProgramComponent implements OnInit, AfterViewInit {
  internalLink: string[] | null;
  externalLink: string;
  noUrl: boolean;
  images: any = [];
  slickSlider: any;

  @ViewChild('carouselElement') carouselElement: ElementRef;

  @Input() first = false;
  @Input() contentRight = false;
  @Input() data: any;

  constructor(private readonly routeMap: RouteMapService, private readonly utilsService: TrendencyUtilsService) {}

  ngOnInit(): void {
    this.internalLink =
      this.data.subComponents[0].contentPage && this.data.subComponents[0].contentPage !== null
        ? this.routeMap.getRoute(this.data.subComponents[0])
        : null;
    this.externalLink =
      this.data.subComponents[0].contentType && this.data.subComponents[0].customUrl !== null ? this.data.subComponents[0].customUrl : null;
    this.noUrl = this.internalLink === null && this.externalLink === null;

    for (const sub of this.data.subComponents) {
      if (sub.image) {
        this.images.push(sub);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.images.length > 1 && this.utilsService.isBrowser()) {
      this.slickSlider = $(this.carouselElement.nativeElement).not('.slick-initialized').slick({
        dots: false,
        autoplay: false,
        lazyLoad: 'ondemand',
        centerMode: false,
        slidesToShow: 1,
        speed: 200,
        autoplaySpeed: 3000,
        variableWidth: false,
        arrows: true,
        infinite: true,
      });
    }
  }
}
