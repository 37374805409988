import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-team-box',
  templateUrl: './team-box.component.html',
  styleUrls: ['./team-box.component.scss'],
})
export class TeamBoxComponent extends BaseComponent implements OnInit {
  title: string;
  subtitle: string;
  image: any;
  imageTitle: string;
  imageSubtitle: string;
  link: string;
  linkText: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.subtitle = this.componentObject?.subtitle;
    this.imageTitle = this.componentObject?.imageTitle;
    this.imageSubtitle = this.componentObject?.imageSubtitle;
    this.link = this.componentObject?.link;
    this.linkText = this.componentObject?.buttonText;
    this.image = this.componentObject?.image;
  }
}
