<section class="info-cards" [ngClass]="{ green: isGreen }">
  <div class="section-head margin-b" *ngIf="title || subtitle">
    <h2 *ngIf="title" class="title" id="{{ componentObject | titleToId }}">{{ title }}</h2>
    <p *ngIf="subtitle" class="desc subtitle">{{ subtitle }}</p>
  </div>
  <app-info-card-collection [cards]="filteredCards"></app-info-card-collection>
  <div class="bottom-button-box" *ngIf="showLoadMore">
    <button class="button load-button button-secondary" [appBackgroundColor]="'white'" (click)="loadMore()">
      {{ 'label.load-more' | translate }}
    </button>
  </div>
</section>
