import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '../../../../../trendency/utils';
import { IBusinessCard } from '../../shared.definitions';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-contact-collection',
  templateUrl: './contact-collection.component.html',
  styleUrls: ['./contact-collection.component.scss'],
})
export class ContactCollectionComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('contactSlider', { static: true }) contactSlider: ElementRef;
  title: string;
  subtitle: string;
  cards: IBusinessCard[];

  constructor(private readonly trendencyUtilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.subtitle = this.componentObject?.subtitle;
    this.cards = this.componentObject?.subComponents;
  }

  ngAfterViewInit(): void {
    if (this.trendencyUtilsService.isBrowser()) {
      this.initSlick();
    }
  }

  initSlick(): void {
    if (this.contactSlider?.nativeElement) {
      $(this.contactSlider.nativeElement).not('.slick-initialized').slick({
        dots: true,
        autoplay: false,
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        arrows: false,
      });
    }
  }
}
