<div class="detailed-card-wrapper">
  <div class="detailed-card-top {{ respStyle }}">
    <a class="bg" [ngStyle]="{ 'background-image': 'url(' + data.image + ')' } | ssrEmptyString" corporateImageLazyLoad></a>
    <div class="bottom" *ngIf="data.tag">
      <div class="tag" [style]="'background-color: #' + data?.color">{{ data.tag }}</div>
    </div>
    <button class="fav-button" (click)="favButtonAction()">
      <i class="icon icon-heart-empty-white" *ngIf="data.favorite === 'false'"></i>
      <i class="icon icon-heart-full-red" *ngIf="data.favorite === 'true'"></i>
    </button>
  </div>
  <div class="content-box">
    <div class="title">{{ data.name }}</div>
    <a class="link" href="{{ data.website }}" target="_blank">Website <i class="icon icon-arrow-right-more-red"></i></a>
    <div class="content-title">contact:</div>
    <div class="content" [innerHTML]="data.contact"></div>
  </div>
  <button class="button button-secondary">Details</button>
</div>
