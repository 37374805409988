import { Pipe, PipeTransform } from '@angular/core';
import * as fs from 'filesize';

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  transform(value: number): string | undefined {
    if (value) {
      return fs(value);
    }
    return undefined;
  }
}
