import { Component, Input, OnInit } from '@angular/core';
import { IProgressBarOptions } from '../../loading.definitions';
import { TrendencyProgressBarService } from '../../services/progress-bar.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'trendency-progressbar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class TrendencyProgressBarComponent implements OnInit {
  constructor(public progressBarService: TrendencyProgressBarService) {}

  private defaultOptions: IProgressBarOptions;

  @Input() public options: IProgressBarOptions;

  ngOnInit(): void {
    this.defaultOptions = {
      value: undefined,
    };
    this.options = Object.assign(this.defaultOptions, this.options || {});
  }
}
