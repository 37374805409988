<div class="ginop-header" [ngClass]="{ small: small }">
  <span
    class="ginop-header-image"
    *ngIf="imageUrl"
    [style.background-image]="'url(' + imageUrl + ')' | ssrEmptyString"
    [attr.aria-label]="imageAlt"
    role="img"
  ></span>
  <div class="ginop-header-logo-positioner">
    <div class="ginop-header-logo-row">
      <div class="ginop-header-logo">
        <i class="icon icon-logo-en"></i>
      </div>
    </div>
  </div>
  <h1 class="ginop-title" *ngIf="!small && title">{{ title }}</h1>
  <h3 class="ginop-subtitle" *ngIf="subTitle">{{ subTitle }}</h3>
</div>
