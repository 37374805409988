<div class="business-card" *ngIf="card">
  <span class="business-card__title" *ngIf="card?.title">{{ card.title }}</span>

  <div class="business-card__personal">
    <div class="business-card__name" *ngIf="card?.firstName || card?.lastName">
      <span *ngIf="card?.firstName">{{ card.firstName }}</span>
      <span *ngIf="card?.lastName">{{ card.lastName }}</span>
    </div>
    <span class="business-card__info-type" *ngIf="card?.infoType">{{ card.infoType }}:</span>
    <a [href]="'mailto: ' + card.email" class="business-card__email" *ngIf="card?.email">{{ card.email }}</a>
  </div>
</div>
