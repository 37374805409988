import { Component, Input } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-detailed-card',
  templateUrl: './detailed-card.component.html',
  styleUrls: ['./detailed-card.component.scss'],
})
export class DetailedCardComponent {
  @Input() respStyle = 'resp-style-a';
  @Input() data: any;
  isLoggedIn = false;
  isAlreadyFav = false;

  constructor(private readonly sharedService: SharedService) {}

  favButtonAction(): void {
    if (this.isLoggedIn) {
      if (this.isAlreadyFav) {
        this.setActiveDeleteElementPopup(true);
      }
    } else {
      this.setActiveLoginPopup(true);
    }
  }

  setActiveLoginPopup(active: boolean): void {
    this.sharedService.setActiveLoginPopup(active);
  }

  setActiveDeleteElementPopup(active: boolean): void {
    this.sharedService.setActiveDeleteElementPopup(active);
  }
}
