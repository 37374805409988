import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { TrendencyUtilsService } from '@trendency/utils';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
})
export class YoutubePlayerComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @ViewChild(YouTubePlayer) youtubePlayer!: YouTubePlayer;
  private isLoaded = false;
  @Input() videoId: string | null;
  playerConfig = {
    controls: 1,
    mute: 0,
    autoplay: 0,
    disablekb: 0,
    modestbranding: 1,
    rel: 0,
  };
  isMobile: boolean;

  constructor(
    private readonly sharedService: SharedService,
    private readonly utilsService: TrendencyUtilsService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.isMobile = this.sharedService.isMobile();
  }

  ngOnInit(): void {
    if (!this.isLoaded && this.utilsService.isBrowser()) {
      const tag = this.document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.isLoaded = true;
    }
  }

  playVideo(): void {
    this.youtubePlayer.playVideo();
  }

  stopVideo(): void {
    this.youtubePlayer.pauseVideo();
  }
}
