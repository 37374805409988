<header class="desktop" id="header-top" [ngClass]="{ home: isHomePage }">
  <div class="head-top">
    <div class="wrapper container">
      <div class="left">
        <ng-container [ngTemplateOutlet]="officialTravelGuide"></ng-container>
        <ng-container [ngTemplateOutlet]="b2b"></ng-container>
      </div>
      <div class="right">
        <app-weather-widget [routerLink]="['/', currentLang, 'ROUTES.weather' | translate]"></app-weather-widget>
        <app-language-switcher></app-language-switcher>
      </div>
    </div>
  </div>
  <div *ngIf="!isFormElementsVisible" class="head-main" [ngClass]="{ sticky: isSticky }">
    <ng-container *ngIf="isHomePage">
      <div class="custom-gradient" *ngIf="gradient" aria-hidden="true"></div>

      <span
        *ngIf="imageUrl && !youtubeUrl"
        [ngStyle]="{ opacity: headOpacity, 'background-image': 'url(' + imageUrl + ')' | ssrEmptyString }"
        [attr.aria-label]="imageAlt"
        aria-hidden="true"
        role="img"
        class="bgImage"
        corporateImageLazyLoad
      ></span>

      <app-video-player *ngIf="youtubeUrl" [url]="youtubeUrl"></app-video-player>

      <div class="hero-content" [ngClass]="{ 'no-events': headOpacity === 0 }">
        <div [ngStyle]="{ opacity: headOpacity }" class="bgColor" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="group group275" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="group group276" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="group group156" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="specicon {{ thematicIconKey }}" aria-hidden="true"></div>
        <h1 *ngIf="title" class="home-title">{{ title }}</h1>
        <div *ngIf="!isSticky" class="home-location">
          <div class="wrapper">
            <div class="map-titles">
              <span>{{ mapIconSubtitle0 }}</span>
              <span>{{ mapIconSubtitle1 }}</span>
              <span>{{ mapIconSubtitle2 }}</span>
            </div>
            <i class="icon {{ mapIconKey }}"></i>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="main-nav" #mainNav>
      <ng-container [ngTemplateOutlet]="brandLogo"></ng-container>
      <ng-container *ngFor="let item of menu">
        <ng-container
          [ngTemplateOutlet]="menuItemDesktop"
          [ngTemplateOutletContext]="{ id: item.id, menu: item.menu, color: item.color, label: item.label }"
          *ngIf="item?.menu"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</header>

<header class="mobile" [ngClass]="{ sticky: isStickyMobile }">
  <div class="fixed-spacer"></div>
  <div class="wrapper">
    <div class="head-top">
      <div class="left">
        <app-brand-logo (click)="closeMobileMenu()"></app-brand-logo>
      </div>
      <div class="right">
        <button
          (click)="toggleMobileMenu()"
          [attr.aria-pressed]="isMobileMenuOpen$ | async"
          [attr.aria-label]="'label.toggle-menu-button' | translate"
        >
          <i class="icon icon-burger-white" *ngIf="(isMobileMenuOpen$ | async) === false"></i>
          <i class="icon icon-close-white" *ngIf="isMobileMenuOpen$ | async"></i>
        </button>
      </div>
    </div>

    <div class="mobile-nav-content" [ngClass]="{ opened: isMobileMenuOpen$ | async }">
      <div class="nav-main-page">
        <div class="top">
          <div class="main-links">
            <ng-container *ngFor="let item of menu">
              <ng-container
                [ngTemplateOutlet]="menuItemMobile"
                [ngTemplateOutletContext]="{ id: item.id, menu: item.menu, color: item.color, label: item.label }"
                *ngIf="item?.menu"
              ></ng-container>
            </ng-container>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-link-list">
            <ng-container [ngTemplateOutlet]="b2b"></ng-container>
          </div>
          <div class="bottom-socials">
            <div class="left">
              <app-social-icons [isRoundedMobile]="true"></app-social-icons>
            </div>
            <div class="right"></div>
          </div>
          <app-weather-widget [routerLink]="['/', 'weather']"></app-weather-widget>
          <app-language-switcher></app-language-switcher>
        </div>
      </div>
    </div>
  </div>

  <div
    class="mobile-head-image"
    [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' } | ssrEmptyString"
    *ngIf="isHomePage"
    [attr.aria-label]="imageAlt"
    aria-hidden="true"
    corporateImageLazyLoad
  >
    <div class="custom-gradient" *ngIf="gradient" aria-hidden="true"></div>
    <div class="home-title" *ngIf="title">{{ title }}</div>
    <div *ngIf="thematicIconKey" class="specicon mobile {{ thematicIconKey }}" aria-hidden="true"></div>
  </div>
</header>

<ng-template #menuTreeLinkMobil let-menuTree="menuTree" let-visible="visible">
  <ul class="link-list">
    <li *ngFor="let item of menuTree">
      <a
        *ngIf="item?.customUrl"
        (click)="closeMobileMenu()"
        [href]="item.customUrl"
        [target]="item.targetBlank ? '_blank' : '_self'"
        [tabindex]="visible ? 0 : -1"
        >{{ item.label }}</a
      >
      <a *ngIf="item?.routerLink" (click)="closeMobileMenu()" [routerLink]="item.routerLink" [tabindex]="visible ? 0 : -1">{{
        item.label
      }}</a>
      <a
        *ngIf="!item.customUrl && !item?.routerLink"
        (click)="closeMobileMenu()"
        [routerLink]="item.routerLink"
        [tabindex]="visible ? 0 : -1"
        >{{ item.label }}</a
      >
    </li>
  </ul>
</ng-template>

<ng-template #menuTreeItem let-menuTree="menuTree" let-visible="visible">
  <ul *ngFor="let item of menuTree">
    <li>
      <a
        [tabindex]="visible ? 0 : -1"
        *ngIf="item?.customUrl"
        [href]="item.customUrl"
        [target]="item.targetBlank ? '_blank' : '_self'"
        class="big"
        >{{ item.label }}</a
      >
      <a [tabindex]="visible ? 0 : -1" *ngIf="item?.routerLink" [routerLink]="item.routerLink" class="big">{{ item.label }}</a>
      <a [tabindex]="visible ? 0 : -1" *ngIf="!item?.customUrl && !item?.routerLink" class="big">{{ item.label }}</a>
    </li>
    <li *ngFor="let child of item.children">
      <a
        [tabindex]="visible ? 0 : -1"
        *ngIf="child?.customUrl"
        [href]="child.customUrl"
        [target]="child.targetBlank ? '_blank' : '_self'"
        >{{ child.label }}</a
      >
      <a [tabindex]="visible ? 0 : -1" *ngIf="child?.routerLink" [routerLink]="child.routerLink">{{ child.label }}</a>
    </li>
  </ul>
</ng-template>

<ng-template #menuRecommendationItem let-menuRecommendation="menuRecommendation" let-visible="visible">
  <ng-container *ngFor="let recommendation of menuRecommendation">
    <a
      [tabindex]="visible ? 0 : -1"
      *ngIf="recommendation?.customUrl"
      class="image-link"
      [href]="recommendation.customUrl"
      [target]="recommendation.targetBlank ? '_blank' : '_self'"
    >
      <div class="image" [ngStyle]="{ 'background-image': recommendation.templateUrl } | ssrEmptyString" corporateImageLazyLoad></div>
      <div class="title">{{ recommendation.label }}</div>
    </a>
    <a [tabindex]="visible ? 0 : -1" *ngIf="recommendation?.routerLink" class="image-link" [routerLink]="recommendation.routerLink">
      <div class="image" [ngStyle]="{ 'background-image': recommendation.templateUrl } | ssrEmptyString" corporateImageLazyLoad></div>
      <div class="title">{{ recommendation.label }}</div>
    </a>
  </ng-container>
</ng-template>

<ng-template #officialTravelGuide>
  <a [href]="currentLang === 'hu' ? 'https://csodasmagyarorszag.hu' : '/' + currentLang" class="custom-link" target="_self">{{ 'HEADER.the-official-travel-guide' | translate }}</a>
</ng-template>

<ng-template #linkConventions>
  <a href="https://hcb.hu" class="custom-link" target="_blank">{{ 'label.conventions' | translate }}</a>
</ng-template>

<ng-template #b2b>
  <a href="https://visithungary.com/b2b" class="custom-link" target="_blank">B2B</a>
</ng-template>

<ng-template #contactUs>
  <a [routerLink]="['/contact'] | localize" class="custom-link">{{ 'label.contact-us' | translate }}</a>
</ng-template>

<ng-template #brandLogo>
  <a [routerLink]="['/'] | localize" class="brand-logo no-text" [attr.aria-label]="'404.back-to-main-page' | translate">
    <i class="icon icon-logo-notext"></i>
  </a>
</ng-template>

<ng-template #menuItemDesktop let-id="id" let-menu="menu" let-color="color" let-btnLabel="label">
  <ng-container
    *ngIf="menu?.tree?.left?.length || menu?.tree?.right?.length || menu?.recommendationsLeft?.length || menu?.recommendationsRight?.length"
  >
    <button class="button button-spec" [appBackgroundColor]="color" [class]="color" (click)="toggleNavContent(id)">
      {{ btnLabel | translate }}
      <i class="icon icon-arrow-right-button-white" [ngClass]="{ opened: actualNavId === id }"></i>
    </button>
    <div class="nav-content-wrapper" [ngClass]="{ opened: actualNavId === id }">
      <div class="nav-content" [appBackgroundColor]="color" [class]="color">
        <div class="row">
          <div class="col-lg-3">
            <ng-container
              *ngIf="menu?.tree?.left?.length"
              [ngTemplateOutlet]="menuTreeItem"
              [ngTemplateOutletContext]="{ menuTree: menu.tree.left, visible: actualNavId === id }"
            ></ng-container>
          </div>
          <div class="col-lg-3">
            <ng-container
              *ngIf="menu?.tree?.right?.length"
              [ngTemplateOutlet]="menuTreeItem"
              [ngTemplateOutletContext]="{ menuTree: menu.tree.right, visible: actualNavId === id }"
            ></ng-container>
          </div>
          <div class="col-lg-6">
            <div class="image-link-group half" *ngIf="menu?.recommendationsLeft?.length || menu?.recommendationsRight?.length">
              <ng-container
                [ngTemplateOutlet]="menuRecommendationItem"
                [ngTemplateOutletContext]="{ menuRecommendation: menu.recommendationsLeft, visible: actualNavId === id }"
              ></ng-container>
              <ng-container
                [ngTemplateOutlet]="menuRecommendationItem"
                [ngTemplateOutletContext]="{ menuRecommendation: menu.recommendationsRight, visible: actualNavId === id }"
              ></ng-container>
            </div>
          </div>
        </div>
        <div class="col-12 bottom-wrapper">
          <button class="close-button" (click)="closeNavContent()" [tabindex]="actualNavId === id ? 0 : -1">
            {{ 'label.close' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #menuItemMobile let-id="id" let-menu="menu" let-color="color" let-btnLabel="label">
  <ng-container *ngIf="menu?.tree?.left?.length || menu?.tree?.right?.length">
    <button class="main-link" [class]="color" [appBackgroundColor]="color" (click)="toggleNavContent(id)">
      {{ btnLabel | translate }} <i class="icon icon-arrow-right-button-white"></i>
    </button>
    <div class="nav-subpage" [class]="color" [appBackgroundColor]="color" [ngClass]="{ opened: actualNavId === id }">
      <button class="back-button" (click)="closeNavContent()">
        <i class="icon icon-arrow-left-white"></i>{{ 'label.back' | translate }}
      </button>
      <ng-container
        *ngIf="menu?.tree?.left?.length"
        [ngTemplateOutlet]="menuTreeLinkMobil"
        [ngTemplateOutletContext]="{ menuTree: menu?.tree?.left, visible: actualNavId === id }"
      ></ng-container>
      <ng-container
        *ngIf="menu.tree.right?.length"
        [ngTemplateOutlet]="menuTreeLinkMobil"
        [ngTemplateOutletContext]="{ menuTree: menu.tree.right, visible: actualNavId === id }"
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
