import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomUrlService {
  isReallyCustomUrl(url: string | string[]): boolean | null {
    if (!url) {
      return null;
    }

    if (Array.isArray(url)) {
      return false;
    }

    return !url?.includes(environment.domain as string);
  }

  getUrlPath(url: string | string[]): string[] | string | null {
    if (!url) {
      return null;
    }

    if (Array.isArray(url) || this.isReallyCustomUrl(url)) {
      return url;
    }

    const path = url?.replace(environment.domain as string, '');
    let pathArr: string[];

    if (path.includes('/')) {
      if (path.includes('?')) {
        pathArr = path.split('?')[0].split('/');
      } else {
        pathArr = path.split('/');
      }
    } else {
      pathArr = [path];
    }

    pathArr.unshift('/');
    return pathArr;
  }

  getUrlParams(url: string): any {
    if (!url) {
      return null;
    }

    if (!url.includes('?')) {
      return null;
    }

    const paramsArr = url.split('?')[1].split('&');
    const params: any = {};
    for (const param of paramsArr) {
      const split = param.split('=');
      params[split[0]] = split[1];
    }

    return params;
  }
}
