import { Injectable } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITag } from '../shared.definitions';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private readonly loginPopupSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loginPopup$: Observable<boolean> = this.loginPopupSubject$.asObservable();

  private readonly deleteElementPopupSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public deleteElementPopup$: Observable<boolean> = this.deleteElementPopupSubject$.asObservable();

  constructor(private readonly utilsService: TrendencyUtilsService) {}

  setActiveLoginPopup(active: boolean): void {
    this.loginPopupSubject$.next(active);
  }

  setActiveDeleteElementPopup(active: boolean): void {
    this.deleteElementPopupSubject$.next(active);
  }

  isMobile(): boolean {
    return this.utilsService.isBrowser() && window.innerWidth <= 768;
  }

  getCollectionUUID(snapshot: any, type: string): string | null {
    const body = snapshot?.data?.data?.data?.body;
    if (!body) {
      return null;
    }
    const collection = body.find((item: any) => item.type === type);
    return collection?.uuid ?? null;
  }

  isStartWithHttpOrHttps(link: string): boolean {
    return link?.indexOf('http://') === 0 || link?.indexOf('https://') === 0;
  }

  isStaticClip(str: string): string | undefined {
    const staticClipKeys = [
      'budapest.mp4',
      'sarvar.mp4',
      'gyor.mp4',
      'gyula.mp4',
      'matra-bukk.mp4',
      'pecs.mp4',
      'sopron.mp4',
      'szeged.mp4',
      'home-csodas.webm',
      'home-visit.webm',
      'tokaj.mp4',
      'balaton.mp4',
      'debrecen.mp4',
      'visit-tel22.mp4',
      'csodas-tel22.mp4',
    ];

    const clip = str && staticClipKeys.find((item) => item.substr(0, item.indexOf('.')) === str);

    if (clip) {
      return `/assets/clips/${clip}`;
    }

    return undefined;
  }

  getClipFromSourceOrLink(str: string): string | undefined {
    if (this.isStartWithHttpOrHttps(str)) {
      return str;
    }

    if (this.isStaticClip(str)) {
      return str;
    }

    return undefined;
  }

  getTagColorAndValue(data: any): ITag {
    const purple = '7C51A1';
    const red = 'EF4E69';
    const green = '38BA9B';
    const blueLight = '6499D1';
    const orange = 'F2994A';

    const obj: ITag = {
      tag: '',
      color: '',
    };

    if (data?.event) {
      obj.tag = 'CARD.event';
      obj.color = purple;
      return obj;
    }
    if (data?.tag) {
      obj.tag = data.tag;
      obj.color = blueLight;
      return obj;
    }
    if (data?.color) {
      switch (data.color.value) {
        case red: {
          obj.tag = 'CARD.where-to-go';
          obj.color = red;
          break;
        }
        case purple: {
          obj.tag = 'CARD.what-to-do';
          obj.color = purple;
          break;
        }
        case green: {
          obj.tag = 'CARD.plan-your-trip';
          obj.color = green;
          break;
        }
        case blueLight: {
          obj.tag = 'CARD.hungary-for';
          obj.color = blueLight;
          break;
        }
        case orange: {
          obj.tag = 'Budapest';
          obj.color = orange;
          break;
        }
      }
    } else {
      obj.tag = 'CARD.wow';
      obj.color = blueLight;
    }

    return obj;
  }
}
