import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TrendencyUtilsService } from '@trendency/utils';
import { BaseComponent } from '../base.component';

declare let $: any;
@Component({
  selector: 'app-spice-map-new',
  templateUrl: './spice-map-new.component.html',
  styleUrls: ['./spice-map-new.component.scss'],
})
export class SpiceMapNewComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('carouselFirst', { static: true }) carouselFirst: ElementRef;
  @ViewChild('carouselSecond', { static: true }) carouselSecond: ElementRef;

  destinations: any[];
  destinationsBudapest: any[];
  destinationsGreaterBudapest: any[];
  isBudapest = true;

  constructor(private readonly utilsService: TrendencyUtilsService, private readonly router: Router) {
    super();
  }

  ngOnInit(): void {
    this.initDestinations();
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick(this.carouselFirst.nativeElement);
    }
  }

  initDestinations(): void {
    this.destinationsBudapest = [];
    this.destinationsGreaterBudapest = [];
    if (this.componentObject.subComponents[0]) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.subComponents[0],
        className: 'airport',
      });
    }
    if (this.componentObject.subComponents[1]) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.subComponents[1],
        className: 'dunakanyar',
      });
    }
    if (this.componentObject.subComponents[2]) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.subComponents[2],
        className: 'etyek',
      });
    }
    if (this.componentObject.subComponents[3]) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.subComponents[3],
        className: 'godollo',
      });
    }
    if (this.componentObject.subComponents[4]) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.subComponents[4],
        className: 'szentendre',
      });
    }
    if (this.componentObject.subComponents[5]) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.subComponents[5],
        className: 'velence',
      });
    }
    if (this.componentObject.subComponents[6]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[6],
        className: 'buda-hills',
      });
    }
    if (this.componentObject.subComponents[7]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[7],
        className: 'obuda',
      });
    }
    if (this.componentObject.subComponents[8]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[8],
        className: 'margaret-island',
      });
    }
    if (this.componentObject.subComponents[9]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[9],
        className: 'parliament',
      });
    }
    if (this.componentObject.subComponents[10]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[10],
        className: 'city-park',
      });
    }
    if (this.componentObject.subComponents[11]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[11],
        className: 'castle',
      });
    }
    if (this.componentObject.subComponents[12]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[12],
        className: 'jewish-quarter',
      });
    }
    if (this.componentObject.subComponents[13]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[13],
        className: 'palace-quarter',
      });
    }
    if (this.componentObject.subComponents[14]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[14],
        className: 'gellert',
      });
    }
    if (this.componentObject.subComponents[15]) {
      this.destinationsBudapest.push({
        ...this.componentObject.subComponents[15],
        className: 'palace-arts',
      });
    }
    this.destinations = this.destinationsBudapest;
  }

  changeMap(): void {
    if (this.isBudapest) {
      this.isBudapest = false;
      this.destinations = this.destinationsGreaterBudapest;
    } else {
      this.isBudapest = true;
      this.destinations = this.destinationsBudapest;
    }
  }

  changeMapMobile(isBudapest: boolean): void {
    if (isBudapest !== this.isBudapest) {
      this.isBudapest = isBudapest;
      this.initSlick(this.carouselSecond.nativeElement);
      if (this.isBudapest) {
        this.destinations = this.destinationsBudapest;
      } else {
        this.destinations = this.destinationsGreaterBudapest;
      }
    }
  }

  initSlick(element: any): void {
    $(element).not('.slick-initialized').slick({
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      infinite: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      nextArrow: false,
      prevArrow: false,
    });
  }

  navigate(destination: any): void {
    let url = '';
    if (destination.subComponents[0].contentPage) {
      url = '/' + destination.subComponents[0].contentType.key + '/' + destination.subComponents[0].contentPage.slug;
      this.router.navigateByUrl(url.toLowerCase());
    } else {
      url = destination.subComponents[0].customUrl;
      if (destination.subComponents[0].newTab) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  }
}
