import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { en_GB, NzI18nService } from 'ng-zorro-antd/i18n';
import { filter, pairwise, takeUntil } from 'rxjs/operators';

import { TrendencyUtilsService } from '@trendency/utils';
import { Subject } from 'rxjs';
import { BaseService } from './core/components/base/base.service';
import { CustomUrlService } from './shared/services/custom-url.service';

export const destroy$ = new Subject();

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <corporate-fullscreen-loader></corporate-fullscreen-loader>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private readonly i18n: NzI18nService,
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
    private readonly utilsService: TrendencyUtilsService,
    private readonly baseService: BaseService,
    private readonly customUrlService: CustomUrlService
  ) {}

  ngOnInit(): void {
    this.i18n.setLocale(en_GB);

    this.baseService.fetchInitApi();
    this.baseService.fetchTranslations();
    this.baseService.subscribeToLocalizeEvents();

    this.router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        pairwise(),
        takeUntil(destroy$)
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !== event.routerEvent.urlAfterRedirects.split('?')[0]) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });

    this.utilsService.removeInitLoader();
  }

  @HostListener('window:click', ['$event'])
  checkCustomUrl(e: any): void {
    const path = e.composedPath() as Array<any>;
    const firstAnchor = path?.find((p) => p?.tagName?.toLowerCase() === 'a');
    const href = firstAnchor?.getAttribute('href');

    if (href?.indexOf('http') === 0 && !href?.includes('/documents')) {
      if (this.customUrlService.isReallyCustomUrl(href)) {
        window.open(href);
      } else {
        const path = this.customUrlService.getUrlPath(href);
        const params = this.customUrlService.getUrlParams(href);
        this.router.navigate(<any>path, { queryParams: params });
      }

      e.preventDefault();
    }
  }

  ngOnDestroy(): void {
    destroy$.next(true);
    destroy$.complete();
  }
}
