import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-h1-plus-lead',
  templateUrl: './h1-plus-lead.component.html',
  styleUrls: ['./h1-plus-lead.component.scss'],
})
export class H1PlusLeadComponent extends BaseComponent implements OnInit {
  title: string;
  lead: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.lead = this.componentObject?.text;
  }
}
