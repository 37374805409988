<section class="article-lead">
  <div class="container">
    <div class="left">
      <span class="lead-text" [innerHTML]="shortenedLead"></span>
      <span *ngIf="showReadMore" class="more" [appBackgroundColor]="'white'" (click)="onReadMore()">
        {{ 'TEXT.readMore' | translate }}
        <i class="icon icon-arrow-down-more-red"></i>
      </span>
      <span *ngIf="showReadLess" class="less" [appBackgroundColor]="'white'" (click)="onReadLess()">
        {{ 'TEXT.readLess' | translate }}
        <i class="icon icon-arrow-down-more-red"></i>
      </span>
    </div>
    <div class="right">
      <div class="map-widget-sidebar" *ngIf="lat && lng">
        <app-map-button-widget [buttonLabel]="label" [buttonUrl]="url" [lat]="lat" [lng]="lng"></app-map-button-widget>
      </div>
    </div>
  </div>
</section>
