import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../core/components/base/base.service';

@Component({
  selector: 'app-social-icons',
  templateUrl: './social-icons.component.html',
  styleUrls: ['./social-icons.component.scss'],
})
export class SocialIconsComponent {
  @Input() isRoundedDesktop: boolean;
  @Input() isRoundedMobile: boolean;
  @Input() isShowLinkedIn: boolean;

  get footerData$(): Observable<any> {
    return this.baseService.footerData$;
  }

  constructor(private readonly baseService: BaseService) {}
}
