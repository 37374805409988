import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-destination-icon',
  templateUrl: './destination-icon.component.html',
  styleUrls: ['./destination-icon.component.scss'],
})
export class DestinationIconComponent extends BaseComponent {
  @Input() key: string;

  constructor() {
    super();
  }
}
