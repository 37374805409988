import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TrendencyReqService } from '../../../../trendency/http';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { IContentListParams, IFilterOption } from '../shared.definitions';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly reqService: TrendencyReqService, private readonly translate: TranslateService) {}

  get lang(): string {
    return this.translate.currentLang;
  }

  getAllLangTranslations$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/list-translations`);
  }

  getTranslations$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/translations`);
  }

  getInit$(): Observable<any> {
    return this.reqService.get(`init`);
  }

  getContentList$(listType: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content/${listType}`);
  }

  getContentListByParams$(listType: string, params?: IContentListParams): Observable<any> {
    let url = `/${this.lang}/content/${listType}`;

    if (!params) {
      return this.reqService.get(url);
    }

    if (params?.rowTo_limit !== undefined && params.rowTo_limit >= 0) {
      url += `?rowFrom_limit=${params.rowFrom_limit}`;
    }
    if (params?.rowTo_limit) {
      url += `&rowTo_limit=${params.rowTo_limit}`;
    }
    if (params?.collection) {
      url += `&collection=${params.collection}`;
    }
    if (params?.global_filter) {
      url += `&global_filter=${params.global_filter}`;
    }
    if (params?.storyIdeasTags_filter) {
      params.storyIdeasTags_filter.forEach((id) => {
        url += `&storyIdeasTags_filter[]=${id}`;
      });
    }

    return this.reqService.get(url);
  }

  getContentPreview$(previewHash: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content-preview/${previewHash}`);
  }

  getContent$(pageType: string, slug: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content/${pageType}/${slug}/view`);
  }

  getDocumentsByGroup$(groupUuid: string, limit: number): Observable<any> {
    return this.reqService.get(`/${this.lang}/documents-by-group/${groupUuid}?offset_limit=${limit}`);
  }

  getDocumentsByDestination$(destinationTagUuid: string, limit: number): Observable<any> {
    return this.reqService.get(`/${this.lang}/documents-by-destination/${destinationTagUuid}?offset_limit=${limit}`);
  }

  getDocumentGroups$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/document-groups`);
  }

  getMenuTreeIndex$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/menu/tree`);
  }
  getFormInfos$(id: string, queryParams: { expires?: string; signature?: string; previewHash?: string }): Observable<any> {
    let url = `/hu/forms/${id}`;

    if (queryParams) {
      const params = new URLSearchParams();

      if (queryParams.expires) {
        params.set('expires', queryParams.expires.trim());
      }

      if (queryParams.signature) {
        params.set('signature', queryParams.signature.trim());
      }

      if (queryParams.previewHash) {
        params.set('previewHash', queryParams.previewHash.trim());
      }

      url += '?' + params.toString();
    }

    return this.reqService.get(url);
  }
  getFormContent$(slug: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/content/Form/${slug}/view`);
  }
  postForm$(
    id: string,
    fields: any,
    queryParams: { expires?: string; signature?: string; submitSignature?: string; previewHash?: string }
  ): Observable<any> {
    let url = `/hu/forms/${id}/submit`;

    if (queryParams) {
      const params = new URLSearchParams();

      if (queryParams.expires) {
        params.set('expires', queryParams.expires);
      }

      if (queryParams.submitSignature) {
        params.set('signature', queryParams.submitSignature);
      }

      url += '?' + params.toString();
    }

    return this.reqService.post(url, { fields });
  }

  getMenuTreeList$(groupKey: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/menu/group/${groupKey}/recommendations`);
  }

  getTrips$(
    from: number,
    to: number,
    collectionUUID?: string,
    term?: string,
    destinations?: IFilterOption[],
    tripLengths?: IFilterOption[],
    targetAudiences?: IFilterOption[]
  ): Observable<any> {
    let url = `/${this.lang}/content/Trips?rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (collectionUUID) {
      url += `&collection=${collectionUUID}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (tripLengths) {
      tripLengths
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&tripLength_filter[]=${f.id}`;
        });
    }
    if (targetAudiences) {
      targetAudiences
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&targetAudienceTags_filter[]=${f.id}`;
        });
    }
    return this.reqService.get(url);
  }

  getArticles$(
    from: number,
    to: number,
    collectionUUID?: string,
    term?: string,
    destinations?: IFilterOption[],
    thematics?: IFilterOption[]
  ): Observable<any> {
    let url = `/${this.lang}/content/Articles?rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (collectionUUID) {
      url += `&collection=${collectionUUID}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (thematics) {
      thematics
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&thematicTags_filter[]=${f.id}`;
        });
    }
    return this.reqService.get(url);
  }

  getEvents$(
    from: number,
    to: number,
    collectionUUID?: string,
    term?: string,
    destinations?: IFilterOption[],
    thematics?: IFilterOption[],
    dateRange?: Date[],
    isArchive?: boolean
  ): Observable<any> {
    let url = `/${this.lang}/content/Events?rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (collectionUUID) {
      url += `&collection=${collectionUUID}`;
    }
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&destinationTags_filter[]=${f.id}`;
        });
    }
    if (thematics) {
      thematics
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&thematicTags_filter[]=${f.id}`;
        });
    }
    if (dateRange && dateRange.length > 0) {
      const gt = new DateFormatPipe().transform(dateRange[0], 'YYYY-MM-DD');
      const lt = new DateFormatPipe().transform(dateRange[1], 'YYYY-MM-DD');
      url += `&eventDateGt_filter=${gt}&eventDateLt_filter=${lt}`;
    }
    if (isArchive) {
      url += `&isArchive=1`;
    }

    return this.reqService.get(url);
  }

  getAttractionSearch$(slug: string): Observable<any> {
    return this.reqService.get(`/hu/attraction-search/${slug}`);
  }

  getAttractionSearchFilter$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/search-filter/`);
  }

  getSearch$(slug: string, page?: number): Observable<any> {
    let url = `/hu/search/${slug}`;
    if (page) {
      url += `?page=${page}`;
    }
    return this.reqService.get(url);
  }

  getGinopRegForm$(type: string, uuid: string): Observable<any> {
    return this.reqService.get(`/en/${type}/registration?pageUuid=${uuid}`);
  }

  postGinopRegistration$(type: string, uuid: string, data: object): Observable<any> {
    return this.reqService.post(`/en/${type}/registration?pageUuid=${uuid}`, data);
  }

  getImageByUuid$(uuid: string): Observable<any> {
    return this.reqService.get(`/en/media/image-variant/${uuid}/details`);
  }

  getGinopDocumentByUuid$(uuid: string): Observable<any> {
    return this.reqService.get(`/en/documents/preview/${uuid}`);
  }

  getFile$(uuid: string): Observable<any> {
    return this.reqService.get(`/${this.lang}/documents/preview/${uuid}`);
  }

  getMiceCocktailReg$(): Observable<any> {
    return this.reqService.get(`/en/cocktail/registration`);
  }

  postMiceCocktailReg$(data: object): Observable<any> {
    return this.reqService.post(`/en/cocktail/registration`, data);
  }

  public getApplications$(
    uuid: string,
    from: number,
    to: number,
    term?: string,
    destinations?: IFilterOption[],
    summerServices?: IFilterOption[]
  ): Observable<any> {
    let url = `/${this.lang}/summer-accomodation/${uuid}/applications/en?listingInfo&rowFrom_limit=${from}&rowTo_limit=${to}`;
    if (term) {
      url += `&global_filter=${term}`;
    }
    if (destinations) {
      destinations
        .filter((f) => f.checked)
        .forEach((f) => {
          let id = 'null';
          if (f.id !== 0) {
            id = String(f.id);
          }
          url += `&destinationTag_filter[]=${id}`;
        });
    }
    if (summerServices) {
      summerServices
        .filter((f) => f.checked)
        .forEach((f) => {
          url += `&${f.id}=true`;
        });
    }
    return this.reqService.get(url);
  }

  fileUpload$(formData: FormData): Observable<any> {
    return this.reqService.post(`/${this.lang}/kpp/document-upload`, formData);
  }

  getFormList$(): Observable<any> {
    return this.reqService.get(`/${this.lang}/kpp/forms`);
  }
}
