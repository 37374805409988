import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateUniversalLoader } from '@trendency/translations';
import { TrendencyUtilsService } from '@trendency/utils';
import { Observable, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { IMenu } from '../../../shared/shared.definitions';
import { BaseService } from '../base/base.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnChanges, OnInit, OnDestroy {
  @Output() openHeaderNav = new EventEmitter();
  @Input() footerMenu: any;
  initialLanguageHasBeenSet = false;
  menu: Array<IMenu>;
  routerSub: Subscription;
  currentLanguageSub: Subscription;
  getTranslationSub: Subscription;
  showPopup = false;
  popupTitle = '';
  popupClose = '';
  popupButtonLabel = '';
  popupDescription = '';
  popupButtonUrl = '';
  loadedLanguage = '';

  paths = [
    '/',
    '/minden-csepp-viz-uj-elmenyt-rejt',
    '/de',
    '/pl',
    '/cs',
    '/he',
    '/make-a-splash-this-summer',
    '/de/mach-diesen-sommer-einen-sprung-ins-kuhle-nass',
    '/cs/vyrazte-letos-v-lete-k-vode',
    '/de/mach-diesen-sommer-einen-sprung-ins-kuhle-nass',
    '/make-a-splash-this-summer',
    '/pl/oddaj-sie-letniemu-szalenstwu',
    '/cs/vyrazte-letos-v-lete-k-vode',
    '/he/%D7%98%D7%91%D7%9C%D7%95-%D7%A2%D7%A6%D7%9E%D7%9B%D7%9D-%D7%91%D7%9E%D7%A2%D7%99%D7%99%D7%A0%D7%95%D7%' +
      'AA-%D7%94%D7%97%D7%9E%D7%99%D7%9D-%D7%95%D7%AA%D7%A0%D7%95-%D7%9C%D7%9E%D7%99%D7%9D-%D7%9C%D7%94%D7%A9%D7%99%D7%' +
      '91-%D7%9C%D7%9B%D7%9D-%D7%90%D7%AA-%D7%A0%D7%A2%D7%95%D7%A8%D7%99%D7%9B%D7%9D',
    '/he/טבלו-עצמכם-במעיינות-החמים-ותנו-למים-להשיב-לכם-את-נעוריכם',
  ];

  get footerData$(): Observable<any> {
    return this.baseService.footerData$;
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly baseService: BaseService,
    private readonly utilsService: TrendencyUtilsService,
    private readonly router: Router,
    private readonly loader: TranslateUniversalLoader
  ) {}

  ngOnInit(): void {
    this.routerSub = this.router.events
      .pipe(
        delay(10),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.utilsService.isBrowser() && this.paths.includes(window.location.pathname)) {
          this.checkIfTranslationMissingThenLoad(this.loadedLanguage);
        }
        this.popup();
      });
    this.currentLanguageSub = this.baseService.currentLanguage$.subscribe((lang) => {
      if (this.initialLanguageHasBeenSet === false) {
        this.checkIfTranslationMissingThenLoad(lang.locale);
        this.initialLanguageHasBeenSet = true;
        return;
      }
      this.loadedLanguage = lang.locale;
      localStorage.removeItem('wow-popup-closed');
    });
    this.popup();
  }

  ngOnChanges(): void {
    if (this.footerMenu) {
      this.menu = [
        {
          id: 1,
          label: this.translate.instant('CARD.what-to-do'),
          color: 'purple',
          menu: this.footerMenu?.footer_what_to_do,
        },
        {
          id: 2,
          label: this.translate.instant('CARD.where-to-go'),
          color: 'red',
          menu: this.footerMenu?.footer_where_to_go,
        },
        {
          id: 3,
          label: this.translate.instant('CARD.plan-your-trip'),
          color: 'green',
          menu: this.footerMenu?.footer_plan_your_trip,
        },
        {
          id: 4,
          label: this.translate.instant('CARD.hungary-for'),
          color: 'blue-light',
          menu: this.footerMenu?.footer_hungary_for,
        },
        {
          id: 5,
          label: 'Budapest',
          color: 'orange',
          menu: this.footerMenu?.footer_budapest,
        },
      ];
    }
  }

  popup(): void {
    if (this.utilsService.isBrowser() && this.paths.includes(window.location.pathname)) {
      setTimeout(() => {
        this.showPopup = !localStorage.getItem('wow-popup-closed');
        localStorage.setItem('wow-popup-closed', 'true');
      }, 2000);
    }
  }

  openHeaderNavContent(navId: number): void {
    this.openHeaderNav.emit(navId);
  }

  public closePopup(): void {
    this.showPopup = false;
  }

  clickStop(event: Event): void {
    event.stopPropagation();
  }

  translatePopupTitles(): void {
    this.popupTitle = this.translate.instant('popup.title');
    this.popupClose = this.translate.instant('popup.close');
    this.popupButtonLabel = this.translate.instant('popup.buttonlabel');
    this.popupDescription = this.translate.instant('popup.description');
    this.popupButtonUrl = this.translate.instant('popup.buttonurl');
  }

  checkIfTranslationMissingThenLoad(language: string): void {
    if (this.translate.instant('popup.title') === 'popup.title' || this.translate.instant('popup.title') === null) {
      this.getTranslationSub = this.loader.getTranslation(language).subscribe((lang) => {
        this.popupTitle = lang['popup.title'];
        this.popupClose = lang['popup.close'];
        this.popupButtonLabel = lang['popup.buttonlabel'];
        this.popupDescription = lang['popup.description'];
        this.popupButtonUrl = lang['popup.buttonurl'];
      });
    } else {
      this.translatePopupTitles();
    }
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    this.currentLanguageSub.unsubscribe();
    this.getTranslationSub?.unsubscribe();
  }
}
