import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrendencyUtilsService } from '../../../../../trendency/utils';
import { ApiService } from '../../services/api.service';
import { IFile } from '../../shared.definitions';
import { BaseComponent } from '../base.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-link-collection',
  templateUrl: './link-collection.component.html',
  styleUrls: ['./link-collection.component.scss'],
})
export class LinkCollectionComponent extends BaseComponent implements OnInit, OnDestroy {
  title: string;
  subComponents: any;
  destroy$ = new Subject<boolean>();

  constructor(private readonly apiService: ApiService, private readonly trendencyUtilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.subComponents = this.componentObject?.subComponents;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getNiceId(str: string): string {
    return this.trendencyUtilsService.generateSlug(str);
  }

  scrollTo(target: string): void {
    if (this.trendencyUtilsService.isBrowser() && target) {
      const headerDesktop = document.querySelector('header.desktop .main-nav') as HTMLElement;
      const headerMobile = document.querySelector('header.mobile') as HTMLElement;
      const element = document.getElementById(target);
      if (element) {
        let y = element.getBoundingClientRect().top + window.scrollY;
        y = y - headerMobile.offsetHeight - headerDesktop.offsetHeight;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }

  getFilePathFromDocument(document: IFile): void {
    if (this.trendencyUtilsService.isBrowser() && document?.uuid) {
      this.apiService.getFile$(document?.uuid).pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if (res?.path) {
          window.open(res?.path, '_blank');
        }
      });
    }
  }
}
