import { Component, Input } from '@angular/core';
import { IContentLang } from '../../shared.definitions';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
})
export class FlagComponent {
  @Input() language: IContentLang | null;
}
