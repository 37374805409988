<section class="tiled-cards">
  <div class="wrapper" *ngIf="view === 'desktop'">
    <div class="title">
      <h3>march 03</h3>
    </div>
    <div class="card-list">
      <ng-container *ngFor="let card of data.list">
        <div class="card-container">
          <div class="card-date-row"><i class="icon icon-calendar-purple"></i> 2020. March 02. - April 15.</div>
          <div class="card-location-row"><i class="icon icon-pin-purple"></i> Budapest</div>
          <div class="card-wrapper">
            <app-link-card [data]="card"></app-link-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mobile-wrapper" *ngIf="view === 'mobile'">
    <div class="title">
      <h3>march 03</h3>
    </div>
    <div #tiledCardsObjElement class="tiled-cards-obj">
      <ng-container *ngFor="let card of data.list">
        <div>
          <div class="mobile-slide">
            <app-link-card [data]="card"></app-link-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
