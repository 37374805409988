import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrendencyGoogleMapService {
  public googleAPIisReady = new BehaviorSubject<boolean>(false);

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public setGoogleAPItoReady() {
    this.googleAPIisReady.next(true);
  }

  initGoogle() {
    let googleMapsScript = this.document.getElementById('googleMapsScript');

    if (googleMapsScript == null) {
      googleMapsScript = this.document.createElement('script');
      googleMapsScript.id = 'googleMapsScript';
      (window as any)['initMap'] = () => this.setGoogleAPItoReady();
      googleMapsScript.setAttribute(
        'src',
        'https://maps.google.com/maps/api/js?key=' + environment.googleApiKey + '&libraries=places&callback=initMap'
      );
      googleMapsScript.setAttribute('async', '');
      googleMapsScript.setAttribute('defer', '');
      document.head.appendChild(googleMapsScript);
    }
  }
}
