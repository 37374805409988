import { FormStepperComponent } from '../features/form-elements/form-stepper/form-stepper.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { AnchorBlockComponent } from './components/anchor-block/anchor-block.component';
import { ApplicationCollectionComponent } from './components/application-collection/application-collection.component';
import { ArticleCollectionComponent } from './components/article-collection/article-collection.component';
import { ArticleLeadComponent } from './components/article-lead/article-lead.component';
import { B2bCollectionComponent } from './components/b2b-collection/b2b-collection.component';
import { BigpicCarouselComponent } from './components/bigpic-carousel/bigpic-carousel.component';
import { ButtonComponent } from './components/button/button.component';
import { CenteredCarouselComponent } from './components/centered-carousel/centered-carousel.component';
import { ContactCollectionComponent } from './components/contact-collection/contact-collection.component';
import { ContentImageComponent } from './components/content-image/content-image.component';
import { DetailedSliderComponent } from './components/detailed-slider/detailed-slider.component';
import { EventCollectionComponent } from './components/event-collection/event-collection.component';
import { FaceCollectionComponent } from './components/face-collection/face-collection.component';
import { FancyH1Component } from './components/fancy-h1/fancy-h1.component';
import { FancyH2Component } from './components/fancy-h2/fancy-h2.component';
import { FancyH3Component } from './components/fancy-h3/fancy-h3.component';
import { GalleryCarouselComponent } from './components/gallery-carousel/gallery-carousel.component';
import { H1PlusLeadComponent } from './components/h1-plus-lead/h1-plus-lead.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { InstagramBlockComponent } from './components/instagram-block/instagram-block.component';
import { LinkCollectionComponent } from './components/link-collection/link-collection.component';
import { LookAroundLinkComponent } from './components/look-around-link/look-around-link.component';
import { MainCarouselComponent } from './components/main-carousel/main-carousel.component';
import { MapComponent } from './components/map/map.component';
import { MarketResearchComponent } from './components/market-research/market-research.component';
import { MoveAroundComponent } from './components/move-around/move-around.component';
import { PageHeadNewComponent } from './components/page-head-new/page-head-new.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { PlanCardsComponent } from './components/plan-cards/plan-cards.component';
import { PodcastComponent } from './components/podcast/podcast.component';
import { PracticalInfoComponent } from './components/practical-info/practical-info.component';
import { SeasonCardsComponent } from './components/season-cards/season-cards.component';
import { ShareBarComponent } from './components/share-bar/share-bar.component';
import { SpiceMapComponent } from './components/spice-map/spice-map.component';
import { TeamBoxComponent } from './components/team-box/team-box.component';
import { TextComponent } from './components/text/text.component';
import { TitleLeadLinkComponent } from './components/title-lead-link/title-lead-link.component';
import { TitleTextComponent } from './components/title-text/title-text.component';
import { TripCardsComponent } from './components/trip-cards/trip-cards.component';
import { TripCollectionComponent } from './components/trip-collection/trip-collection.component';
import { TripDayComponent } from './components/trip-day/trip-day.component';
import { TripInfoComponent } from './components/trip-info/trip-info.component';
import { VideoPlayerNewComponent } from './components/video-player-new/video-player-new.component';
import { WeatherInfoStaticComponent } from './components/weather-info-static/weather-info-static.component';

export const COMPONENT_MAP: { [key: string]: any } = {
  'Body.TitleText': TitleTextComponent,
  'Body.Text': TextComponent,
  'Body.ContentHero': PageHeadComponent,
  'Body.MainPageHero': PageHeadComponent,
  'Body.Form.Form': FormStepperComponent,
  'Body.Group.Carousel.Row': BigpicCarouselComponent,
  'Body.Group.MoveAround.Row': MoveAroundComponent,
  'Body.Group.SeasonHighlight.Row': SeasonCardsComponent,
  'Body.Group.PlanYourTrip.Row': PlanCardsComponent,
  'Body.Group.ItineraryTip.Row': TripCardsComponent,
  'Body.DestinationMap': MapComponent,
  'Body.Group.DestinationMap.Row': MapComponent,
  'Body.Group.Discovery.Row': MainCarouselComponent,
  'Body.LeadMap': ArticleLeadComponent,
  'Body.Group.CategoryBox.Row': InfoCardsComponent,
  'Body.TakeLookAround': LookAroundLinkComponent,
  'Body.Group.YouMayAlsoLikeSelect.Row': DetailedSliderComponent,
  'Body.ShareBar': ShareBarComponent,
  'Body.Group.Gallery.ImageBox': ContentImageComponent,
  'Body.Gallery': GalleryCarouselComponent,
  'Body.TripInfoHeader': TripInfoComponent,
  'Body.Group.TripDay.Day': TripDayComponent,
  'Body.DuringYourVisit': ActivitiesComponent,
  'Body.Collection.TrippCollection': TripCollectionComponent,
  'Body.Collection.ArticleCollection': ArticleCollectionComponent,
  'Body.Collection.EventCollection': EventCollectionComponent,
  'Body.Collection.SummerCampaignCollection': ApplicationCollectionComponent,
  'Body.Group.ThingsToDo.Row': CenteredCarouselComponent,
  'Body.PracticalInfo': PracticalInfoComponent,
  'Body.AnchorBlock': AnchorBlockComponent,
  'Body.InstagramFeed': InstagramBlockComponent,
  'Body.HungaryForHero': PageHeadNewComponent,
  'Body.Button': ButtonComponent,
  'Body.WeatherInfoEmptyComponent': WeatherInfoStaticComponent,
  'Body.SpiceBudapestDestinationMap': SpiceMapComponent,
  'Body.B2bTeamBox': TeamBoxComponent,
  'Body.TitleLeadLink': TitleLeadLinkComponent,
  'Body.FancyH1': FancyH1Component,
  'Body.FancyH1Lead': H1PlusLeadComponent,
  'Body.FancyH2': FancyH2Component,
  'Body.FancyH2TextLink': FancyH2Component,
  'Body.TitleMiddleText': FancyH2Component,
  'Body.FancyH3Paragraph': FancyH3Component,
  'Body.Group.GreenCard.Row': ContactCollectionComponent,
  'Body.Group.B2bTable.Row': FaceCollectionComponent,
  'Body.B2bLinkCategoryTree': LinkCollectionComponent,
  'Body.Collection.B2bArticleCollection': B2bCollectionComponent,
  'Body.Collection.B2bNewsCollection': B2bCollectionComponent,
  'Body.Collection.B2bEventCollection': B2bCollectionComponent,
  'Body.Podcast': PodcastComponent,
  'Body.Media': VideoPlayerNewComponent,
  'Body.MediaSimple': VideoPlayerNewComponent,
  'Body.Group.MarketResearch.Row': MarketResearchComponent,
  'Body.Group.GreaterBudapest.Row': SpiceMapComponent,
  'Body.Group.B2bResearchLinkText.Row': MarketResearchComponent,
};
