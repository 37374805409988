import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-gallery-carousel',
  templateUrl: './gallery-carousel.component.html',
  styleUrls: ['./gallery-carousel.component.scss'],
})
export class GalleryCarouselComponent extends BaseComponent implements OnInit, AfterViewInit {
  items: any[];

  @ViewChild('galleryElement', { static: true }) galleryElement: ElementRef;

  constructor(private readonly translateService: TranslateService, private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject && this.componentObject.subComponents) {
      this.items = this.componentObject.subComponents;
    }
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  initSlick(): void {
    const nextLabel = this.translateService.instant('label.next');
    const prevLabel = this.translateService.instant('label.prev');
    $(this.galleryElement.nativeElement).slick({
      centerMode: true,
      centerPadding: '0px',
      slidesToShow: 1,
      autoplay: true,
      dots: true,
      speed: 200,
      variableWidth: true,
      accessibility: false,
      nextArrow: `<button class="slick-next slick-arrow" aria-label="${nextLabel}" type="button">${nextLabel}</button>`,
      prevArrow: `<button class="slick-prev slick-arrow" aria-label="${prevLabel}" type="button">${prevLabel}</button>`,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1,
          },
        },
      ],
    });
  }
}
