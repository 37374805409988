import { Component, Input } from '@angular/core';
import { IBusinessCard } from '../../shared.definitions';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss'],
})
export class BusinessCardComponent extends BaseComponent {
  @Input() card: IBusinessCard;

  constructor() {
    super();
  }
}
