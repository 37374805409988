import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TrendencyFullscreenLoaderService } from '../../services/fullscreen-loader.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'corporate-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss'],
})
export class TrendFullscreenLoaderComponent implements OnInit, OnDestroy {
  public showLoader: boolean;
  private subscription: Subscription;

  constructor(private fullscreenLoaderService: TrendencyFullscreenLoaderService) {}

  ngOnInit(): void {
    this.subscription = this.fullscreenLoaderService.showLoader.subscribe((show) => {
      this.showLoader = show;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public show() {
    this.fullscreenLoaderService.show();
  }

  public hide() {
    this.fullscreenLoaderService.hide();
  }
}
