<section class="season-cards">
  <div class="centered-carousel-head">
    <h2 id="{{ componentObject | titleToId }}">{{ title }}</h2>
    <p class="desc">{{ subtitle }}</p>
  </div>

  <div #seasonCaruselElement class="season-carusel">
    <div *ngFor="let card of cards; let i = index">
      <div class="season-card">
        <div
          class="content"
          [ngStyle]="{ 'background-image': 'url(' + card.image.url + ')' } | ssrEmptyString"
          [attr.aria-label]="card?.image?.image?.title"
          corporateImageLazyLoad
        >
          <div class="centered" *ngIf="card.functionalIcon">
            <i class="icon {{ card.functionalIcon.key }}"></i>
            <p>{{ card.title }}</p>
          </div>
          <div class="hover-content" [ngClass]="{ spring: i === 0, summer: i === 1, autumn: i === 2, winter: i === 3 }">
            <div class="inner-logo" *ngIf="card.functionalIcon">
              <i class="icon {{ card.functionalIcon.key }}"></i>
              <p>{{ card.title }}</p>
            </div>
            <div class="card-content">
              <p *ngIf="card.subtitle0">{{ card.subtitle0 }}</p>
              <p *ngIf="card.subtitle1">{{ card.subtitle1 }}</p>
              <p *ngIf="card.subtitle2">{{ card.subtitle2 }}</p>
            </div>
            <a
              class="button button-primary"
              [appBackgroundColor]="'red'"
              *ngIf="card.customUrl"
              [href]="card.customUrl"
              [target]="card.newTab ? '_blank' : ''"
              >{{ card.buttonLabel ? card.buttonLabel : 'details' }}</a
            >
            <a
              class="button button-primary"
              [appBackgroundColor]="'red'"
              *ngIf="card.routerLink && !card.customUrl"
              [routerLink]="card.routerLink"
              >{{ card.buttonLabel ? card.buttonLabel : 'details' }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="'specicon ' + thematicIconKey" class="specicon"></div>
</section>
