<section class="bottom-footer" [appBackgroundColor]="'conf-blue-b'">
  <div class="mobile-only" *ngIf="isSocialActive">
    <app-social-icons></app-social-icons>
  </div>
  <div class="wrapper">
    <div class="left">© 2012-{{ currentYear }} {{ 'label.hungarian-tourism-agency' | translate }}</div>
    <div class="right">
      <a [routerLink]="['/' | localize, getGeneratedSlug('label.imprint' | translate)]" class="link">{{ 'label.imprint' | translate }}</a>
      <a [routerLink]="['/' | localize, getGeneratedSlug('label.privacy-notice' | translate)]" class="link">{{
        'label.privacy-notice' | translate
      }}</a>
      <a [routerLink]="['/' | localize, getGeneratedSlug('label.declaration-of-consent' | translate)]" class="link">{{
        'label.declaration-of-consent' | translate
      }}</a>
    </div>
  </div>
</section>
