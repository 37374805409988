import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ComponentMapService } from '../../../shared/services/component-map.service';
import { SharedService } from '../../../shared/services/shared.service';
import { IContentLang, IMenu } from '../../../shared/shared.definitions';
import { BaseService } from '../base/base.service';
import { WeatherService } from 'src/app/shared/services/weather.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('mainNav', { static: true }) mainNavObj: ElementRef;
  @Input() headerMenu: any;
  @Input() languages: IContentLang[];

  isSticky = true;
  isStickyMobile = true;
  isHomePage = true;
  isFormElementsVisible = false;
  actualNavId = 0;
  headOpacity = 1;
  currentLang: string;
  lastScrollPos = 0;
  prevScrollPos = 0;
  title: string;
  mapIconSubtitle0: string;
  mapIconSubtitle1: string;
  mapIconSubtitle2: string;
  mapIconKey: string;
  thematicIconKey: string;
  imageUrl: string;
  imageAlt: string;
  gradient: boolean;
  youtubeUrl: string | false | undefined;

  menu: Array<IMenu>;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly componentMapService: ComponentMapService,
    private readonly translate: TranslateService,
    private readonly sharedService: SharedService,
    private readonly baseService: BaseService,
    private readonly weatherService: WeatherService
  ) {
    this.renderer.listen('window', 'scroll', () => {
      this.stickyHandle();
      this.handleScrollDirection();
    });

    this.renderer.listen('window', 'click', (evt) => {
      this.globalMouseClick(evt);
    });
  }

  get isMobileMenuOpen$(): Observable<boolean> {
    return this.baseService.isMobileMenuOpen$;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.onInitExtended();
      });

    this.componentMapService.mainPageHero$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.title = value?.componentObject?.title;
        this.thematicIconKey = value?.componentObject?.thematicIcon?.key;
        this.mapIconKey = value?.componentObject?.mapIcon?.key;
        this.mapIconSubtitle0 = value?.componentObject?.mapIconSubtitle0;
        this.mapIconSubtitle1 = value?.componentObject?.mapIconSubtitle1;
        this.mapIconSubtitle2 = value?.componentObject?.mapIconSubtitle2;
        this.imageUrl =
          this.sharedService.isMobile() && value.componentObject?.mobileThumbnailImage?.url
            ? value.componentObject?.mobileThumbnailImage?.url
            : value.componentObject?.image?.url;
        this.imageAlt =
          this.sharedService.isMobile() && value.componentObject?.mobileThumbnailImage?.image?.title
            ? value.componentObject?.mobileThumbnailImage?.image?.title
            : value.componentObject?.image?.image?.title;
        this.gradient = value?.componentObject?.gradient || value.componentObject?.gradient == null;
        this.youtubeUrl = !this.sharedService.isMobile() && this.sharedService.getClipFromSourceOrLink(value?.componentObject?.youtubeLink);
      }
    });

    this.baseService.formElementsVisible$.pipe(takeUntil(this.destroy$)).subscribe((visible) => {
      this.isFormElementsVisible = visible;
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd && !event['url'].includes('form')) {
        this.baseService.setFormElementsVisibility(false);
      }
    });

    this.weatherService.getCurrentLocationAndWeather();
  }

  ngOnChanges(): void {
    this.onInitExtended();
    this.menu = [
      {
        id: 1,
        label: this.translate.instant('CARD.what-to-do'),
        color: 'purple',
        menu: this.headerMenu?.header_what_to_do,
      },
      {
        id: 2,
        label: this.translate.instant('CARD.where-to-go'),
        color: 'red',
        menu: this.headerMenu?.header_where_to_go,
      },
      {
        id: 3,
        label: this.translate.instant('CARD.plan-your-trip'),
        color: 'green',
        menu: this.headerMenu?.header_plan_your_trip,
      },
      {
        id: 4,
        label: this.translate.instant('CARD.hungary-for'),
        color: 'blue-light',
        menu: this.headerMenu?.header_hungary_for,
      },
      {
        id: 5,
        label: 'Budapest',
        color: 'orange',
        menu: this.headerMenu?.header_budapest,
      },
    ];
  }

  onInitExtended(): void {
    const url = this.router.routerState.snapshot.url;
    const actualPath = url.split('?')[0].split('/');
    const actualLang = (this.currentLang = this.translate.currentLang);
    this.setDocumentLang(actualLang);
    this.isHomePage = actualPath.length === 2 && ((actualLang === 'en' && actualPath[1] === '') || actualPath[1] === actualLang);

    if (isPlatformBrowser(this.platformId)) {
      this.stickyHandle();
      this.handleScrollDirection();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  closeMobileMenu(): void {
    this.actualNavId = 0;
    this.baseService.toggleMobileMenu(false);
  }

  toggleMobileMenu(): void {
    this.baseService.toggleMobileMenu();
  }

  closeNavContent(): void {
    this.actualNavId = 0;
  }

  toggleNavContantOutCall(event: any): void {
    this.baseService.toggleMobileMenu(true);
    this.toggleNavContent(event);
  }

  toggleNavContent(id: number): void {
    if (id !== this.actualNavId) {
      this.actualNavId = id;
    } else {
      this.closeNavContent();
    }

    if (this.isHomePage) {
      this.mainNavObj.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  stickyHandle(): void {
    const scrollPos = window.scrollY || window.pageYOffset;
    this.stickyHandleDesktop(scrollPos);
    this.stickyHandleMobile(scrollPos);
    this.lastScrollPos = scrollPos;
  }

  handleScrollDirection(): void {
    if (isPlatformBrowser(this.platformId)) {
      const newScrollPos = window.pageYOffset;
      if (newScrollPos > this.prevScrollPos && !this.isHomePage) {
        this.closeNavContent();
      } else if (newScrollPos > 731 && this.isHomePage && window.innerWidth > 768) {
        this.closeNavContent();
      }
      this.prevScrollPos = newScrollPos;
    }
  }

  stickyHandleMobile(scrollPos: number): void {
    const border = 0;

    if (scrollPos === 0) {
      this.isStickyMobile = true;
    } else if (scrollPos > border && this.lastScrollPos < scrollPos) {
      if (!this.baseService.isMobileMenuOpen()) {
        this.isStickyMobile = false;
      }
    } else {
      this.isStickyMobile = true;
    }
  }

  stickyHandleDesktop(scrollPos: number): void {
    let border = 36;
    const page = document.getElementsByClassName('app-root')[0] as HTMLElement;
    if (this.isHomePage) {
      const pageWidth = page.offsetWidth;
      border = pageWidth * 0.27;

      let val = border - scrollPos;
      if (val < 0) {
        val = 0;
      }

      const opBorder = border * 0.7;
      this.headOpacity = val / opBorder / 0.5;
    }
    if (scrollPos > border && !this.isSticky) {
      this.isSticky = true;
    } else if (scrollPos <= border && this.isSticky) {
      this.isSticky = false;
    }
  }

  globalMouseClick(event: any): void {
    if (this.baseService.isMobileMenuOpen()) {
      return;
    }

    if (this.actualNavId !== 0) {
      const elem = event.target;

      if (elem.className.indexOf('nav-content-wrapper') !== -1) {
        this.actualNavId = 0;
        return;
      }

      const mainNavParent = this.getClosest(elem, 'main-nav');
      if (mainNavParent === null) {
        this.actualNavId = 0;
      }

      if (elem.tagName === 'A' || (elem.parentNode && elem.parentNode.tagName === 'A')) {
        this.actualNavId = 0;
      }
    }
  }

  getClosest = (elem: any, classToTest: any): any => {
    while (elem) {
      elem = elem.parentNode;

      if (elem?.className === 'app-root') {
        return null;
      }

      if (elem?.className) {
        if (elem.className.indexOf(classToTest) !== -1) {
          return elem;
        }
      }
    }

    return null;
  };

  setDocumentLang(lang: string): void {
    this.document.documentElement.lang = lang;
  }
}
