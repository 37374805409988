<section class="filter">
  <div class="wrapper row">
    <div class="col-12 title hidden-mobile">Are you looking for something specific?</div>

    <div class="col-lg-3 hidden-mobile">
      <div class="search-input">
        <i class="icon icon-search"></i>
        <input type="text" placeholder="Find a city" />
        <i class="icon icon-arrow-right-white"></i>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <button class="selectlike-button white type-b" (click)="toggleFilter(0)" [ngClass]="{ active: filterOpened[0] }">
        <i class="icon icon-pin-white hidden-desktop"></i>
        Destination
      </button>
    </div>

    <div class="col-12 col-lg-3">
      <button class="selectlike-button white type-b" (click)="toggleFilter(1)" [ngClass]="{ active: filterOpened[1] }">
        <i class="icon icon-summer-bold hidden-desktop"></i>
        Date
      </button>
    </div>

    <div class="col-12 col-lg-3">
      <button class="selectlike-button white type-b" (click)="toggleFilter(2)" [ngClass]="{ active: filterOpened[2] }">
        <i class="icon icon-filter hidden-desktop"></i>
        Type/Topic
      </button>
    </div>

    <div class="col-12 dropdown-poser">
      <div class="selectlike-content-type-a content_0" (mouseleave)="closeAllFilter()" [ngClass]="{ opened: filterOpened[0] }">
        <div class="row dropdown-content-row">
          <div class="col-12 hidden-desktop">
            <div class="search-input">
              <i class="icon icon-search"></i>
              <input type="text" placeholder="Find a city" />
              <i class="icon icon-arrow-right-white"></i>
            </div>
          </div>

          <div class="col-12 col-lg-2">
            <div class="type-title"><i class="icon icon-pin-white"></i>Nearby</div>
          </div>
          <div class="col-12 col-lg-5">
            <a class="link">Grand Budapest</a>
            <a class="link">Balaton and its surroundings</a>
            <a class="link">Tokaj and its surroundings</a>
            <a class="link">Pécs and its surroundings</a>
            <a class="link">Debrecen and its surroundings</a>
          </div>
          <div class="col-12 col-lg-5">
            <a class="link">Sopron and its surroundings</a>
            <a class="link">Eger and its surroundings</a>
            <a class="link">Győr and its surroundings</a>
            <a class="link">Szeged and its surroundings</a>
            <a class="link">Gyula and its surroundings</a>
          </div>
        </div>
      </div>

      <div class="selectlike-content-type-a content_1" (mouseleave)="closeAllFilter()" [ngClass]="{ opened: filterOpened[1] }">
        <div class="row dropdown-content-row">Date cucok</div>
      </div>

      <div class="selectlike-content-type-a content_2" (mouseleave)="closeAllFilter()" [ngClass]="{ opened: filterOpened[2] }">
        <div class="row dropdown-content-row">
          <div class="checkfilters">
            <div class="row check-row">
              <div class="col-12 col-md-4">
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_1" />
                  <label for="check_1">Castle</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_2" />
                  <label for="check_2">Stronghold stronghold stronghold</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_3" />
                  <label for="check_3">Palace</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_4" />
                  <label for="check_4">Ruin</label>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_1" />
                  <label for="check_1">Castle</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_2" />
                  <label for="check_2">Stronghold</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_3" />
                  <label for="check_3">Palace</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_4" />
                  <label for="check_4">Ruin</label>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_1" />
                  <label for="check_1">Castle</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_2" />
                  <label for="check_2">Stronghold</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_3" />
                  <label for="check_3">Palace</label>
                </div>
                <div class="custom-checkbox">
                  <input type="checkbox" id="check_4" />
                  <label for="check_4">Ruin</label>
                </div>
              </div>
            </div>

            <div class="checkfilter-bottom-buttons">
              <button class="clear-button"><i class="icon icon-close-white"></i>Clear</button>
              <button class="button button-primary white-purple">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="active-filter-list">
  <div class="wrapper">
    <div class="filter-type">
      <div class="filter-tag red"><button class="filter-off">x</button>Budapest and its surroundings</div>
    </div>
    <div class="filter-type">
      <div class="filter-tag purple"><button class="filter-off">x</button>Festival, classical & temporary</div>
      <div class="filter-tag purple"><button class="filter-off">x</button>SCience</div>
      <div class="filter-tag purple"><button class="filter-off">x</button>International</div>
      <div class="filter-tag purple"><button class="filter-off">x</button>Conference</div>
    </div>
  </div>
</div>
