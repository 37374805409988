import { Injectable, Inject, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TrendencyStorageService } from '../../utils';

@Injectable()
export class TrendencyHttpInterceptor implements HttpInterceptor {

  constructor(
    private readonly storageService: TrendencyStorageService,
    private readonly translateService: TranslateService,
    @Inject('REQUEST') @Optional() private readonly request: any // a szerver oldali REQUEST a server.ts-ből
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({withCredentials: true});
    const locale = this.translateService ? this.translateService.currentLang : 'hu';
    const tokenName = 'jwt_token'; // az authentikációhoz használt, cookieban tárolt token neve

    // Kiszedi a tokent a cookie-k közül, attól függően hogy szerver vagy kliens oldalon vagyunk
    const cookies = this.request ? (this.request.headers['cookie'] ? this.request.headers['cookie'] : '') : document.cookie;
    const token = this.storageService.getCookie(tokenName, cookies);

    // Beállítja az Authorization és Locale headert a requestekben
    req = req.clone({
      setHeaders: {
        Authorization: (token ? token : ''),
        Locale: (locale ? locale : '')
      }
    });

    return next.handle(req);
  }
}
