<footer class="ginop-footer">
  <div class="ginop-footer-top">
    <a class="ginop-footer-logo" href="https://mtu.gov.hu" target="_blank"><i class="icon icon-mtu"></i></a>
  </div>
  <div class="ginop-footer-bottom">
    <div class="contact-us" *ngIf="mailto">
      For further information please <br />
      <a [href]="'mailto: ' + mailto" class="contact-link">contact us</a>
    </div>
  </div>
</footer>
