<div class="trip-program" [ngClass]="{ first: first, 'content-right': contentRight }">
  <i *ngIf="data.functionalIcon" class="icon icon-top {{ 'red-' + data.functionalIcon.key }}"></i>
  <div class="program-distance">{{ data.functionalTitle }}</div>
  <div class="program-content">
    <div class="content-decoration"></div>
    <div class="content">
      <a
        [routerLink]="internalLink"
        class="trip-program-title"
        *ngIf="internalLink !== null"
        [target]="data.subComponents[0].newTab ? '_blank' : '_self'"
      >
        <h3>{{ data.title }}</h3>
      </a>
      <a [href]="externalLink" class="trip-program-title" *ngIf="externalLink !== null">
        <h3>{{ data.title }}</h3>
      </a>
      <div class="trip-program-title" *ngIf="noUrl">
        <h3>{{ data.title }}</h3>
      </div>
      <div class="trip-program-description">
        <div class="inner-content">
          <span class="content-text" [innerHTML]="data.lead"></span>
          <a *ngIf="data.buttonLabel && data.buttonUrl" target="_blank" [href]="data.buttonUrl" class="link about-link">
            {{ data.buttonLabel }}
            <i class="icon icon-arrow-right-more-red"></i>
          </a>
        </div>

        <div *ngIf="data.duration" class="trip-program-duration">
          <i class="icon icon-time"></i>
          {{ data.duration }}
        </div>
      </div>
    </div>
    <div class="program-media">
      <ng-container *ngTemplateOutlet="innerProgramMedia"></ng-container>
    </div>
  </div>
</div>

<ng-template #innerProgramMedia>
  <ng-container *ngFor="let sub of images">
    <span
      *ngIf="images.length < 2"
      [ngStyle]="{ 'background-image': 'url(' + sub.image.url + ')' | ssrEmptyString }"
      corporateImageLazyLoad
      [attr.aria-label]="sub?.image?.image?.title"
      role="img"
      class="media"
    ></span>
  </ng-container>
  <div #carouselElement class="carousel" *ngIf="images.length > 1">
    <div *ngFor="let sub of images">
      <div class="slide-media-wrapper">
        <span
          *ngIf="sub?.image?.url"
          [ngStyle]="{ 'background-image': 'url(' + sub.image.url + ')' } | ssrEmptyString"
          [attr.aria-label]="sub?.image?.image?.title"
          role="img"
          class="media"
        ></span>
      </div>
    </div>
  </div>
</ng-template>
