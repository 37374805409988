<section class="info-card-collection" *ngIf="cards?.length">
  <div class="container">
    <div class="row">
      <ng-container *ngFor="let card of cards">
        <div class="col-6 col-lg-4">
          <div class="card card--info">
            <div class="card__inner">
              <a [routerLink]="card.routerLink" *ngIf="card?.routerLink && !card?.customUrl" class="card__link"></a>
              <a
                [href]="card.customUrl"
                *ngIf="card?.customUrl"
                [target]="card?.newTab === true ? '_blank' : '_self'"
                class="card__link"
              ></a>
              <app-spice-of-europe-logo *ngIf="card?.isSpice"></app-spice-of-europe-logo>
              <app-destination-icon *ngIf="card?.destinationIcon?.key" [key]="card.destinationIcon!.key"></app-destination-icon>
              <span
                *ngIf="card.image"
                class="card__bg"
                [ngStyle]="{ 'background-image': 'url(' + card.image.url + ')' } | ssrEmptyString"
                [attr.aria-label]="card?.image?.image?.title"
                role="img"
                corporateImageLazyLoad
              ></span>
              <div class="card__content">
                <div class="card__locale" *ngIf="card.locale && card.locale.locale">
                  <i class="icon icon-flag-{{ card.locale.locale }}"></i>
                </div>
                <div class="card__header">
                  <p *ngIf="card.subtitle" class="card__subtitle">{{ card.subtitle }}</p>
                  <h4 class="card__title inner-card-title">{{ card.title }}</h4>
                </div>
                <div class="card__text" [innerHTML]="card.lead"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
