import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-group-card',
  templateUrl: './document-group-card.component.html',
  styleUrls: ['./document-group-card.component.scss'],
})
export class DocumentGroupCardComponent {
  @Input() respStyle = 'resp-style-a';
  @Input() data: any;
  @Input() type: string;
  @Input() color: string;
  isLoggedIn = false;
  isAlreadyFav = false;
  routerLink: string[];
}
