import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-title-lead-link',
  templateUrl: './title-lead-link.component.html',
  styleUrls: ['./title-lead-link.component.scss'],
})
export class TitleLeadLinkComponent extends BaseComponent implements OnInit {
  title: string;
  lead: string;
  link: string;
  linkTitle: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.lead = this.componentObject?.text;
    this.link = this.componentObject?.link;
    this.linkTitle = this.componentObject?.linkTitle;
  }
}
