<section class="plan-cards">
  <div class="section-head">
    <h2 class="head-title" *ngIf="title" id="{{ componentObject | titleToId }}">{{ title }}</h2>
    <p *ngIf="subtitle" class="desc">{{ subtitle }}</p>
  </div>

  <div class="cards">
    <ng-container *ngFor="let card of cards">
      <a
        *ngIf="card && card.customUrl"
        [href]="card.customUrl"
        routerLinkActive="router-link-active"
        [target]="card.newTab ? '_blank' : ''"
        class="card"
        [appBackgroundColor]="'green'"
      >
        <ng-container [ngTemplateOutlet]="cardContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
      </a>
      <a
        *ngIf="card && card.routerLink && !card.customUrl"
        [routerLink]="card.routerLink"
        routerLinkActive="router-link-active"
        class="card"
        [appBackgroundColor]="'green'"
      >
        <ng-container [ngTemplateOutlet]="cardContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
      </a>
    </ng-container>
  </div>
</section>

<ng-template #cardContent let-card="card">
  <i *ngIf="card.functionalIcon" class="icon {{ card.functionalIcon.key }}"></i>
  <h4 *ngIf="card.title" class="title">{{ card.title }}</h4>
  <p *ngIf="card.lead" class="lead">{{ card.lead }}</p>
</ng-template>
