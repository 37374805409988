import { HttpErrorResponse } from '@angular/common/http';

export interface IMetaLimit {
  allRowCount: number;
  currentPage: number;
  fromRow: number;
  maxPage: number;
  rowsOnPage: number;
  toRow: number;
  visibleRowCount: number;
}

export interface IPagerData {
  page: number;
  limit: number;
  count: number;
}

export interface IContentPageData {
  type: string;
  uuid: string;
  versionStatus: string;
  data: {
    body: IComponentData[];
    meta: IComponentData[];
    widget?: IComponentData[];
  };
}

export interface IComponentData {
  type: string;
  uuid: string;
  key: string;
  subComponents: IComponentData[];
  fields: IComponentFieldData[];
  tempUuid?: string;
  isNew?: boolean;
  isValid?: boolean;
  valueDetails?: any;
}

export interface IComponentFieldData {
  type: string;
  inputType: string;
  key: string;
  uuid: string;
  value: any;
  multiple?: boolean;
  asserts?: IComponentFieldDataAsserts;
  referenceSource?: IComponentFieldDataSource;
  inputInfo?: IComponentFieldDataInputInfo;
  properties?: any;
  valueDetails?: any;
}

export interface IComponentFieldDataAsserts {
  NotBlank?: {
    allowNull: boolean;
    message: string;
  };
  Length?: {
    charset: string;
    charsetMessage: string;
    exactMessage: string;
    max: number;
    maxMessage: string;
    min: number;
    minMessage: string;
  };
  Count?: {
    exactMessage: string;
    max: number;
    maxMessage: string;
    min: number;
    minMessage: string;
  };
}

export interface IComponentFieldDataSource {
  url: string;
  key: string;
  label: string;
  availableParameters: any[];
  requiredParameters: any[];
}

export interface IComponentFieldDataInputInfo {
  width: number;
  height: number;
}

export interface IComponentInfo {
  component: IComponentData;
  componentObject: any;
}

export interface IFilterOption {
  id: number;
  text: string;
  checked: boolean;
}

export interface ITripFilters {
  search: string;
  destinations: IFilterOption[];
  targetAudiences: IFilterOption[];
  tripLengths: IFilterOption[];
  scroll?: boolean;
  [key: string]: any;
}

export interface IArticleFilters {
  search: string;
  destinations: IFilterOption[];
  thematics: IFilterOption[];
  scroll?: boolean;
  [key: string]: any;
}

export interface IEventFilters {
  search: string;
  destinations: IFilterOption[];
  dateRange: Date[];
  thematics: IFilterOption[];
  scroll?: boolean;
  isArchive?: boolean;
}

export interface IContentLang {
  label: string;
  locale: string;
  isPublic: boolean;
}

export interface IAttractionFilter {
  type: string;
  label: string;
  isOpen: boolean;
  data: any;
}

export interface IAttractionFilterCategoryObject {
  name: string;
  label: string;
  value: boolean;
}

export interface IAttractionDestinationFilter {
  name: string;
  label: string;
  categories: IAttractionFilterCategoryObject;
}

export interface IAttractionTypeFilter extends IAttractionDestinationFilter {
  value: boolean;
  icon: string;
  isSubCategoriesOpen: boolean;
}

export interface IGinopAgendaObject {
  from: string;
  to: string;
  text: string;
}

export interface IGinopExhibitorObject {
  title: string;
  text: string;
  image: IGinopImage;
}

export interface IFile {
  originalName: string;
  uuid?: string;
  path?: string;
}

export interface WeatherBySettlement {
  measurements: WeatherMeasurements;
  settlement: string;
}

export interface WeatherMeasurements {
  r: number; // tízperces csapadékösszeg (mm)
  t: number; // pillanatnyi hőmérséklet (°C)
  ta: number; // elmúlt 10 perc átlaghőmérséklete (°C)
  tn: number; // elmúlt 10 perc minimumhőmérséklete (°C)
  tx: number; // elmúlt 10 perc maximumhőmérséklete (°C)
  v: number; // pillanatnyi vízszintes látástávolság (m)
  p: number; // pillanatnyi műszerszinti légnyomás (hPa)
  u: number; // pillanatnyi relatív nedvesség (%)
  sg: number; // tízperces átlagos gammadózis (nSv/h)
  sr: number; // tízperces átlagos globálsugárzás (W/m^2)
  suv: number; // tízperces átlagos UV sugárzás (MED/h)
  fs: number; // tízperces átlagos szélsebesség (m/s)
  fsd: number; // szélirány (°)
  fx: number; // tízperces maximális széllökés sebessége (m/s)
  fxd: number; // tízperces maximális széllökés iránya (°)
  fxm: number; // tízperces maximális széllökés perce (')
  fxs: number; // tízperces maximális széllökés másodperce (")
}

export interface ForecastBySettlement {
  forecast: Forecast[];
  settlement: string;
}

export interface Forecast {
  time: string;
  data: ForecastData[];
}

export interface ForecastData {
  name: string;
  unit: string;
  value: string;
}

export interface ForecastReduced {
  Prec12h?: number; // a megelőző 12 óra csapadékösszege (mm)
  Prec6h?: number; // a megelőző 6 óra csapadékösszege (mm)
  Prec?: number; // a megelőző 1 óra csapadékösszege (mm)
  TMax2?: number; // a megelőző 12 óra legmagasabb hőmérséklete (C°)
  Tmax6h?: number; // a megelőző 6 óra legmagasabb hőmérséklete (C°)
  TMin2?: number; // a megelőző 12 óra legalacsonyabb hőmérséklete (C°)
  Tmin6h?: number; // a megelőző 6 óra legalacsonyabb hőmérséklete (C°)
  T2?: number; // hőmérsékelt 2 m-en (C°)
  Prec24h?: number; // a megelőző 24 óra csapadékösszege (mm)
  weather?: number; // weather_type

  /*Kód Jelentés
          1 derült
          2 kissé felhős
          3 közepesen felhős
          4 erősen felhős
          5 borult
          6 fátyolfelhős
          7 ködös
          9 derült, párás
          10 közepesen felhős, párás
          11 borult, párás
          12 erősen fátyolfelhős
          101 szitálás
          102 eső
          202 kiadó eső
          103 zápor
          203 erős zápor
          104 zivatar esővel
          304 zivatar záporral
          105 ónos szitálás
          106 ónos eső
          107 hószállingózás
          108 havazás
          208 erős havazás
          109 hózápor
          209 erős hózápor
          110 havas eső
          310 havas eső záporral
          112 hózivatar*/

  newPtypeWT?: number; // precipitation_types

  /*Kód Jelentés
          0 nincs csapadék
          1 szitálás
          2 eső
          3 zápor
          4 zivatar
          5 ónos szitálás
          6 ónos eső
          7 hószállingózás
          8 havazás
          9 hózápor
          10 havas eső
          11 zivatar jégesővel
          12 hózivatar*/
}

export interface ForecastMapped {
  time: string;
  day: string;
  iconUrl: string;
  iconMsg: string;
  maxCelsius: number;
  rainIconUrl: string;
  rainQuantity: number;
  minCelsius: number;
  minFahrenheit: number;
  maxFahrenheit: number;
}

export interface WeatherIconData {
  top: number;
  left: number;
  region: string;
}

export interface CurrentWeatherData {
  celsius: number;
  fahrenheit: number;
  iconUrl: string;
  iconMsg: string;
  top?: number;
  left?: number;
  region?: string;
}

export interface BundledCurrentWeatherData {
  weatherIcons: CurrentWeatherData[];
  current: CurrentWeatherData;
  forecast?: ForecastMapped[];
}

export interface MappedWeatherData {
  settlement: string;
  forecast: ForecastMapped[];
  current: CurrentWeatherData;
}

export interface WeatherMeta {
  startMonth: string;
  startDay: number;
  endMonth: string;
  endDay: number;
}

export interface MergedForecastAndWeather {
  settlement: string;
  forecast?: Forecast[];
  measurements?: WeatherMeasurements;
}

export interface BundledWeatherData {
  national: BundledCurrentWeatherData;
  selected: BundledCurrentWeatherData;
  meta: WeatherMeta;
}

export interface IMenu {
  id: number;
  label: string;
  color: string;
  menu: Array<any>;
}

export interface ITag {
  tag: string;
  color: string;
}

export interface IBusinessCard {
  title: string;
  firstName: string;
  lastName: string;
  infoType: string;
  email: string;
}

export interface IFaceCard {
  name: string;
  title: string;
  email: string;
  phone: string;
  image: IImage;
}

export interface IInfoCard {
  title: string;
  image: IImage;
  subtitle?: string;
  lead?: string;
  locale?: IContentLang;
  isSpice?: boolean;
  customUrl: string[];
  routerLink: string[];
  newTab: boolean;
  destinationIcon?: {
    key: string;
  };
}

export const FOCUSABLE_ELEMENTS_QUERY = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export interface IContentListParams {
  rowFrom_limit?: number;
  rowTo_limit?: number;
  collection?: string;
  global_filter?: string;
  storyIdeasTags_filter?: Array<number>;
}

export interface IVideo {
  id: number;
  title: string;
  description: string;
  src: string;
  srcName: string;
}

export interface IImageVariant {
  width: number;
  height: number;
  isOriginal: boolean;
  url: string;
  uuid: string;
}

export interface IImageImage {
  description: string;
  title: string;
  uploadedAt: string;
  uuid: string;
}

export interface IImage {
  width: number;
  height: number;
  isOriginal: boolean;
  url: string;
  uuid: string;
  image: IImageImage;
  originalVariant: IImageVariant;
  otherVariants: IImageVariant;
}

export interface IGinopImage {
  inputInfo: {
    width: number;
    height: number;
  };
  inputType: string;
  key: string;
  type: string;
  uuid: string;
  value: IImage;
}

export interface IApplicationFilters {
  search: string;
  destinations: IFilterOption[];
  serviceTypes: IFilterOption[];
  summerServices: IFilterOption[];
}

export interface ResponseBase<T> {
  readonly data: T;
  readonly meta: {
    readonly responseType: string;
    readonly dataCount: number;
    readonly cache?: {
      readonly generated: string;
      readonly key: string;
      readonly field: string | null;
    };
  };
}

export interface Success {
  readonly success: boolean;
}

export interface ErrorData {
  propertyPath: string;
  message: string;
  invalidValue: string;
}

export interface CustomError {
  data: ErrorData[];
  meta: {
    responseType: string;
    dataCount: number;
  };
}

export interface ErrorResponse extends HttpErrorResponse {
  error: CustomError;
}

export interface Rating {
  id: string;
  numberOf: number;
  value?: string;
  text?: string;
}

export interface ComplexRating extends Rating {
  screen2Values: string[];
  screen3Values: string[];
}
