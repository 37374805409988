<section>
  <div #galleryElement class="gallery-carousel">
    <div *ngFor="let item of items">
      <div class="item">
        <span
          *ngIf="item?.image?.url"
          [ngStyle]="{ 'background-image': 'url(' + item.image.url + ')' } | ssrEmptyString"
          [attr.aria-label]="item?.image?.image?.title"
          role="img"
          class="bg"
        ></span>
        <div class="content-wrapper">
          <app-spice-of-europe-logo *ngIf="item?.isSpice"></app-spice-of-europe-logo>
          <div class="title" *ngIf="item?.title?.length">
            {{ item.title }}
            <i class="icon icon-photo"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
