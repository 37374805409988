<section class="page-lead">
  <div class="wrapper">
    <div class="left">
      <div class="lead-text">
        <p>
          Hungary hosts several cultural and music festivals throughout the year. Whether visitors want to see concerts of the world’s
          top-selling recording artists or enjoy amazing performances of classical music, attend cultural events or taste the finest wines
          and food, they can definitely find what they are looking for.
          <span class="more">Read more <i class="icon icon-arrow-down-more-red"></i></span>
        </p>
      </div>
    </div>
    <div class="right">
      <button class="button button-secondary"><i class="icon icon-heart-empty-red icon-before"></i>Add to your list</button>
    </div>
  </div>
</section>
