<div class="popup-title big">SIGN IN</div>
<div class="popup-desc">
  Log in to save your own bucket list. Add places and events from the site to your list and plan your experience in your own way.
</div>

<div class="custom-input">
  <div class="icon-container"><i class="icon icon-login-username"></i></div>
  <input type="text" placeholder="your email address" />
</div>

<div class="custom-input">
  <div class="icon-container"><i class="icon icon-login-password"></i></div>
  <input type="password" placeholder="password" />
</div>

<div class="additional-action-box">
  <div class="column left">
    <div class="custom-checkbox-b">
      <input type="checkbox" id="check_1" />
      <label for="check_1">Remember me</label>
    </div>
  </div>
  <div class="column right">
    <a>Forgot password</a>
  </div>
</div>

<div class="main-buttons">
  <button class="button button-primary" [appBackgroundColor]="'red'">Log in</button>
  <br />
  <button class="button button-secondary">Register</button>
</div>

<div class="social-desc">
  <p>or</p>
  <p>Sign in with your social media account</p>
</div>

<div class="social-buttons">
  <button class="social-login facebook"><i class="icon icon-login-facebook"></i>facebook</button>
  <button class="social-login google"><i class="icon icon-login-google"></i>google</button>
</div>
