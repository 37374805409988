import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TrendencySeoService } from './services/seo.service';
import { TrendencyStorageService } from './services/storage.service';
import { TrendencyUtilsService } from './services/utils.service';

@NgModule({
  imports: [MatSnackBarModule],
  exports: [],
  declarations: [],
  providers: [TrendencyUtilsService, TrendencySeoService, TrendencyStorageService],
})
export class TrendencyUtilsModule {}
