import { Component, OnInit } from '@angular/core';
import { WeatherService } from '../../services/weather.service';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'app-weather-widget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent implements OnInit {
  weatherData$: Observable<{ currentSettlement: string; weather: any; }>;

  constructor(private readonly weatherService: WeatherService) { }

  ngOnInit(): void {
    this.weatherData$ = combineLatest([
      this.weatherService.getCurrentSettlementAsObservable$(),
      this.weatherService.getCurrentSettlementWeatherAsObservable$()
    ]).pipe(
      map(([currentSettlement, weather]) => ({ currentSettlement, weather }))
    );
  }
}
