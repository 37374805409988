import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../src/environments/environment';
const fs = require('fs');
@Injectable()
export class TranslateUniversalLoader implements TranslateLoader, OnDestroy {
  private readonly destroy$ = new Subject();
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly transferState: TransferState,
    private readonly http: HttpClient
  ) {}

  // eslint-disable-next-line rxjs/finnish
  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const key: StateKey<any> = makeStateKey<any>(`transfer-translate-json-${lang}`);
      let localesFolder = '';
      if (isPlatformBrowser(this.platformId)) {
        localesFolder = environment.translation && environment.translation.localesFolder.client;
      } else {
        localesFolder = __dirname + (environment.translation && environment.translation.localesFolder.server);
      }

      // Node oldalon olvassa be a fordítókulcsokat a JSON-ből és mentse el TransferState-ben
      if (isPlatformServer(this.platformId)) {
        const path = `${localesFolder}/${lang}.json`;
        const translation = fs.existsSync(path) ? JSON.parse(fs.readFileSync(path, 'utf8')) : {};
        this.transferState.set(key, translation);
        observer.next(translation);
        observer.complete();
      } else {
        const translationFromServer = this.transferState.get(key, null);
        // Ha lett elmentve fordítás a TransferState-ben, akkor böngésző oldalon szedje ki onnan a fordításokat
        if (translationFromServer && Object.keys(translationFromServer).length) {
          observer.next(translationFromServer);
          observer.complete();
          // Ha nem, akkor kérje le őket a JSON fájlból HTTP hívással
        } else {
          new TranslateHttpLoader(this.http, `${localesFolder}/`, '.json')
            .getTranslation(lang)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (translation) => {
                observer.next(translation);
                observer.complete();
              },
              () => {
                observer.next({});
                observer.complete();
              }
            );
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

export function translateLoaderFactory(platformId: any, transferState: TransferState, http: HttpClient): TranslateUniversalLoader {
  return new TranslateUniversalLoader(platformId, transferState, http);
}

export class TrendencyMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private readonly missingTranslationService: TrendencyMissingTranslationService) {}

  handle(params: MissingTranslationHandlerParams): void {
    if (typeof window !== 'undefined') {
      this.missingTranslationService.addMissingTranslation(params.key);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class TrendencyMissingTranslationService implements OnDestroy {
  private readonly missingTranslationsSubject$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public missingTranslations$: Observable<string[]> = this.missingTranslationsSubject$.asObservable();

  private readonly destroy$ = new Subject();

  public addMissingTranslation(value: string): void {
    const missingTranslations = this.missingTranslationsSubject$.getValue();
    if (missingTranslations.indexOf(value) === -1) {
      missingTranslations.push(value);
    }
    this.missingTranslationsSubject$.next(missingTranslations);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
