import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { IFile } from '../../shared.definitions';
import { BaseComponent } from '../base.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseComponent implements OnInit, OnDestroy {
  buttonText: string;
  link: string;
  document: IFile;
  fileUrl: IFile;
  destroy$ = new Subject<boolean>();

  constructor(private readonly apiService: ApiService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject) {
      this.buttonText = this.componentObject?.buttonText;
      this.link = this.componentObject?.link;

      if (this.componentObject?.document) {
        this.document = this.componentObject?.document;
      }

      if (this.componentObject?.campaignDocument) {
        this.document = this.componentObject?.campaignDocument;
      }

      if (this.document) {
        this.apiService.getFile$(this.document.uuid as string).pipe(takeUntil(this.destroy$)).subscribe((res) => {
          this.fileUrl = res.path;
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
