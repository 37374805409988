<section class="static-weather">
  <div class="container">
    <ul>
      <li class="static-weather__block" tabindex="0">
        <div class="static-weather__inner">
          <img src="/assets/images/icons/average-temperature-icon.svg" alt="" class="static-weather__icon" corporateImageLazyLoad />
          <h3 class="static-weather__title">{{ 'label.average-temperature' | translate | uppercase }}</h3>
          <p class="static-weather-text">{{ 'text.average-temperature' | translate }}</p>
        </div>
        <div class="static-weather__diagram-container">
          <div class="static-weather__diagram-wrapper rightBlur" (scroll)="onDiagramScroll($event)" (touchMove)="onDiagramScroll($event)">
            <div class="static-weather__diagram">
              <img src="/assets/images/icons/average-temperature-diagram.svg" alt="" corporateImageLazyLoad />
            </div>
          </div>
        </div>
      </li>

      <li class="static-weather__block" tabindex="0">
        <div class="static-weather__inner">
          <img src="/assets/images/icons/min-max-temperature-icon.svg" alt="" class="static-weather__icon" corporateImageLazyLoad />
          <h3 class="static-weather__title">{{ 'label.min-max-temperature' | translate | uppercase }}</h3>
          <p class="static-weather-text">{{ 'text.min-max-temperature' | translate }}</p>
        </div>
        <div class="static-weather__diagram-container">
          <div class="static-weather__diagram-wrapper rightBlur" (scroll)="onDiagramScroll($event)" (touchMove)="onDiagramScroll($event)">
            <div class="static-weather__diagram">
              <img src="/assets/images/icons/min-max-temperature-diagram.svg" alt="" class="" corporateImageLazyLoad />
            </div>
          </div>
        </div>
      </li>

      <li class="static-weather__block" tabindex="0">
        <div class="static-weather__inner">
          <img src="/assets/images/icons/average-rainy-icon.svg" alt="" class="static-weather__icon" corporateImageLazyLoad />
          <h3 class="static-weather__title">{{ 'label.average-rainy' | translate | uppercase }}</h3>
          <p class="static-weather-text">{{ 'text.average-rainy' | translate }}</p>
        </div>
        <div class="static-weather__diagram-container">
          <div class="static-weather__diagram-wrapper rightBlur" (scroll)="onDiagramScroll($event)" (touchMove)="onDiagramScroll($event)">
            <div class="static-weather__diagram">
              <img
                [src]="'/assets/images/icons/static-weather/average-rainy-diagram-' + currentLang + '.svg'"
                alt=""
                class=""
                corporateImageLazyLoad
              />
            </div>
          </div>
        </div>
      </li>

      <li class="static-weather__block" tabindex="0">
        <div class="static-weather__inner">
          <img src="/assets/images/icons/sunny-icon.svg" alt="" class="static-weather__icon" corporateImageLazyLoad />
          <h3 class="static-weather__title">{{ 'label.hours-of-sunny' | translate | uppercase }}</h3>
          <p class="static-weather-text">{{ 'text.hours-of-sunny' | translate }}</p>
        </div>
        <div class="static-weather__diagram-container">
          <div class="static-weather__diagram-wrapper rightBlur" (scroll)="onDiagramScroll($event)" (touchMove)="onDiagramScroll($event)">
            <div class="static-weather__diagram">
              <img
                [src]="'/assets/images/icons/static-weather/sunny-diagram-' + currentLang + '.svg'"
                alt=""
                class=""
                corporateImageLazyLoad
              />
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="static-weather__omsz-logo">
      <img class="omsz-logo-img" src="assets/images/weather/hungaromet-logo-eng.webp" alt="hungaromet logo" />
    </div>
  </div>
</section>
