<div
  class="lang-switcher"
  [ngClass]="{ opened: isDropdownOpen, 'is-footer': isFooter }"
  (mouseleave)="toggleDropdown(false)"
  *ngIf="(currentLanguage$ | async) && (languages$ | async)?.length"
>
  <button class="lang-switcher__trigger" (click)="toggleDropdown()">
    <app-flag [language]="currentLanguage$ | async"></app-flag>
    {{ (currentLanguage$ | async)?.label }}
  </button>
  <div class="lang-switcher__dropdown">
    <button class="back-button" (click)="toggleDropdown()">{{ 'label.back' | translate }}</button>
    <ng-container *ngFor="let language of languages$ | async">
      <button
        *ngIf="language.locale !== 'hu'"
        [tabindex]="isDropdownOpen ? 0 : -1"
        (click)="changeLanguage(language)"
        class="lang-switcher__element"
      >
        <app-flag [language]="language"></app-flag>
        {{ language.label }}
      </button>
      <a
        *ngIf="language.locale === 'hu'"
        [tabindex]="isDropdownOpen ? 0 : -1"
        href="https://csodasmagyarorszag.hu/"
        class="lang-switcher__element"
      >
        <app-flag [language]="language"></app-flag>
        {{ language.label }}
      </a>
    </ng-container>
  </div>
</div>
