import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { DateLabelService } from '../../services/date-label.service';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-centered-carousel',
  templateUrl: './centered-carousel.component.html',
  styleUrls: ['./centered-carousel.component.scss'],
})
export class CenteredCarouselComponent extends BaseComponent implements AfterViewInit, OnInit {
  @Input() data: any = require('../../../../assets/mock_jsons/cards-b.json');
  @ViewChild('mainWrapper', { static: true }) mainWrapper: ElementRef;
  @ViewChild('centeredCarouselObjElement', { static: true }) centeredCarouselObjElement: ElementRef;
  @Input() typeB = false;
  actualElemId = 0;
  scrollPos: number;
  wrapperScrollPos: number;
  moveAnimInited = false;
  public thematicIconKey: string;
  SlickSteps: number;

  constructor(private readonly utilsService: TrendencyUtilsService, private readonly dateLabelService: DateLabelService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject.subComponents?.length < 4) {
      this.SlickSteps = 1;
    } else {
      this.SlickSteps = 3;
    }
    this.thematicIconKey = (this.componentObject.thematicIcon && this.componentObject.thematicIcon.key) || '';
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
      const natElem = this.mainWrapper.nativeElement;
      this.wrapperScrollPos = natElem.getBoundingClientRect().y || natElem.getBoundingClientRect().top;
    }
  }

  initSlick(): void {
    $(this.centeredCarouselObjElement.nativeElement).slick({
      dots: false,
      autoplay: false,
      lazyLoad: 'ondemand',
      centerMode: true,
      slidesToShow: this.SlickSteps,
      slidesToScroll: this.SlickSteps,
      speed: 200,
      autoplaySpeed: 3000,
      variableWidth: true,
      arrows: false,
      infinite: true,
      focusOnChange: true,
      responsive: [
        {
          variableWidth: false,
          breakpoint: 1024,
          settings: {
            centerMode: false,
            dots: false,
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ],
    });

    $(this.centeredCarouselObjElement.nativeElement).on('afterChange', (currentSlide: number) => {
      this.actualElemId = currentSlide;
      this.fixAccessibility(currentSlide);
    });
    this.fixAccessibility(0);
  }

  customDot(id: number): void {
    $(this.centeredCarouselObjElement.nativeElement).slick('slickGoTo', id);
  }

  goToPos(event: any): void {
    const srcElement = event.srcElement || event.target;
    if (srcElement && srcElement.getAttribute('data-index')) {
      $(this.centeredCarouselObjElement.nativeElement).slick('slickGoTo', parseInt(srcElement.getAttribute('data-slick-index'), 10));
    }
  }

  next(): void {
    this.actualElemId++;
    if (this.actualElemId === this.data.list.length) {
      this.actualElemId = 0;
    }
    $(this.centeredCarouselObjElement.nativeElement).slick('next');
  }

  prev(): void {
    this.actualElemId--;
    if (this.actualElemId < 0) {
      this.actualElemId = this.data.list.length - 1;
    }

    $(this.centeredCarouselObjElement.nativeElement).slick('prev');
  }

  goto(): void {
    $(this.centeredCarouselObjElement.nativeElement).slick('slickGoTo', this.actualElemId);
  }

  dateLabel(date1: string, date2: string): string {
    return this.dateLabelService.createLabel(date1, date2);
  }

  isCustomDotActive(index: number): boolean {
    return this.actualElemId >= index && this.actualElemId < index + 3;
  }

  private fixAccessibility(currentSlide: number): void {
    if (this.SlickSteps === 3) {
      this.changeTabindex(currentSlide, -1, '0');
      this.changeTabindex(currentSlide, 2, '-1');
    }
  }

  private changeTabindex(currentSlide: number, steps: number, tabindex: string): void {
    const carouselNativeElement = this.centeredCarouselObjElement.nativeElement;
    const slickSlide: HTMLElement = carouselNativeElement.querySelector(`div.slick-slide[data-slick-index="${currentSlide + steps}"]`);
    setTimeout(() => {
      slickSlide.setAttribute('tabindex', tabindex);
    }, 500);
  }
}
