import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TrendencyUtilsService } from '@trendency/utils';
import { SharedService } from '../../services/shared.service';
import { IVideo } from '../../shared.definitions';
import { BaseComponent } from '../base.component';
import { YoutubePlayerComponent } from '../youtube-player/youtube-player.component';

declare let $: any;

@Component({
  selector: 'app-video-player-new',
  templateUrl: './video-player-new.component.html',
  styleUrls: ['./video-player-new.component.scss'],
})
export class VideoPlayerNewComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel') carousel: ElementRef;
  @ViewChild(YoutubePlayerComponent) youtubePlayer: YoutubePlayerComponent;
  videoList: IVideo[] = [];
  currentVideoLink: string | null;
  currentVideo: IVideo;
  currentImage: string;
  isPlaying = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly utilsService: TrendencyUtilsService,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.initContent();
    this.selectVideo(this.videoList[0]);
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser() && this.videoList.length > 1) {
      this.initSlick(this.carousel.nativeElement);
    }
  }

  initContent(): void {
    if (this.componentObject?.videoUrl) {
      this.videoList.push({
        id: 1,
        title: this.componentObject?.videoTitleText,
        description: this.componentObject?.videoDescription,
        src: this.componentObject?.videoUrl,
        srcName: this.getSrcName(this.componentObject?.videoUrl),
      });
    }
    if (this.componentObject?.videoUrl2) {
      this.videoList.push({
        id: 2,
        title: this.componentObject?.videoTitleText2,
        description: this.componentObject?.videoDescription2,
        src: this.componentObject?.videoUrl2,
        srcName: this.getSrcName(this.componentObject?.videoUrl2),
      });
    }
    if (this.componentObject?.videoUrl3) {
      this.videoList.push({
        id: 3,
        title: this.componentObject?.videoTitleText3,
        description: this.componentObject?.videoDescription3,
        src: this.componentObject?.videoUrl3,
        srcName: this.getSrcName(this.componentObject?.videoUrl3),
      });
    }
    if (this.componentObject?.videoUrl4) {
      this.videoList.push({
        id: 4,
        title: this.componentObject?.videoTitleText4,
        description: this.componentObject?.videoDescription4,
        src: this.componentObject?.videoUrl4,
        srcName: this.getSrcName(this.componentObject?.videoUrl4),
      });
    }
    if (this.componentObject?.image) {
      this.currentImage = this.componentObject?.image?.url;
    } else {
      this.currentImage = '';
    }
  }

  changeVideoMobile(video: IVideo): void {
    if (this.sharedService.isMobile()) {
      this.selectVideo(video);
    }
  }

  playToggleMobile(): void {
    if (this.sharedService.isMobile()) {
      if (this.isPlaying) {
        this.youtubePlayer.stopVideo();
      } else {
        this.youtubePlayer.playVideo();
      }
    }
  }

  goTo(link: string): void {
    if (this.sharedService.isStartWithHttpOrHttps(link)) {
      window.open(link, '_blank');
    } else {
      this.router.navigateByUrl(link);
    }
  }

  initSlick(element: any): void {
    $(element).not('.slick-initialized').slick({
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      infinite: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      nextArrow: false,
      prevArrow: false,
    });
  }

  selectVideo(video: IVideo): void {
    const params = new URL(video.src).searchParams;
    this.currentVideo = video;
    this.currentVideoLink = params.get('v');
    if (this.componentObject?.image) {
      this.currentImage = this.componentObject?.image?.url;
    }
    this.isPlaying = false;
  }

  playVideo(): void {
    this.youtubePlayer.playVideo();
    this.isPlaying = true;
    this.currentImage = '';
  }

  stopVideo(): void {
    this.youtubePlayer.stopVideo();
    this.isPlaying = false;
    this.currentImage = '';
  }

  validateYouTubeUrl(urlToParse: string): boolean {
    if (urlToParse) {
      const regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (urlToParse.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  getSrcName(url: string): string {
    return this.validateYouTubeUrl(url) ? 'youtube' : 'teka';
  }
}
