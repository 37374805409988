<section class="place-details" [ngClass]="{ opened: isOpened }">
  <div class="mobile-open-button-wrapper">
    <button class="details-open-button" (click)="openMobileDetails()">Practical infos <i class="icon icon-arrow-down-more-red"></i></button>
  </div>
  <div class="row wrapper main-place-details-content">
    <div class="col-12">
      <h5 class="place-name">Castle of Visegrad</h5>
    </div>

    <div class="column col-12 col-md-6 col-lg-3">
      <div class="small-title">address:</div>
      <p class="text-a">Visegrád, Várhegy, 2025</p>
      <a href="#" class="arrowed-link">How to get there? <i class="icon icon-arrow-right-more-red"></i></a>
      <div class="small-title padding-top">contact:</div>
      <p class="text-a">castlevisegrad@info.hu<br />+36 30 555 4321</p>
      <div class="small-title padding-top">web / social media</div>
      <a class="arrowed-link">visegrad.hu <i class="icon icon-arrow-right-more-red"></i></a>
      <span class="share-iconlist">
        <a><i class="icon icon-facebook-red"></i></a>
        <a><i class="icon icon-twitter-red"></i></a>
        <a><i class="icon icon-instagram-red"></i></a>
      </span>
    </div>

    <div class="column col-12 col-md-6 col-lg-3">
      <div class="small-title">open hours:</div>
      <table class="opening-hours">
        <tbody>
          <tr>
            <td>MON:</td>
            <td><b>10</b> AM - <b>12</b> PM</td>
          </tr>
          <tr>
            <td>TUE:</td>
            <td><b>9</b> AM - <b>18</b> PM</td>
          </tr>
          <tr>
            <td>WED:</td>
            <td><b>9</b> AM - <b>18</b> PM</td>
          </tr>
          <tr>
            <td>THU:</td>
            <td><b>9</b> AM - <b>18</b> PM</td>
          </tr>
          <tr>
            <td>FRI:</td>
            <td><b>9</b> AM - <b>18</b> PM</td>
          </tr>
          <tr>
            <td>SAT:</td>
            <td><b>10</b> AM - <b>19</b> PM</td>
          </tr>
          <tr>
            <td>SUN:</td>
            <td><b>10</b> AM - <b>19</b> PM</td>
          </tr>
        </tbody>
      </table>
      <div class="opening-hours-extra">
        <p>Please consider that the castle is Closed from late November to early March</p>
      </div>
    </div>

    <div class="column col-12 col-md-6 col-lg-3">
      <div class="small-title">accesssibility:</div>
      <div class="icon-list">
        <i class="icon icon-blind"></i>
        <i class="icon icon-disabled"></i>
        <i class="icon icon-deaf"></i>
      </div>
      <div class="small-title">
        spoken languages:
        <div class="icon-list">
          <i class="icon icon-flag-eng"></i>
          <i class="icon icon-flag-ger"></i>
          <i class="icon icon-flag-hun"></i>
          <i class="icon icon-flag-spa"></i>
        </div>
      </div>
      <div class="small-title">paying options:</div>
      <div class="icon-w-desc">
        <i class="icon icon-ccard"></i>
        <div class="text">by paypass, cash</div>
      </div>
      <div class="small-title padding-top">copupon / voucher accepted:</div>
      <div class="icon-w-desc">
        <i class="icon icon-ccard"></i>
        <div class="text">some kind of voucher and coupon</div>
      </div>
    </div>

    <div class="column col-12 col-md-6 col-lg-3">
      <div class="small-title">More information at the nearest tourist information point:</div>
      <div class="tourinform-logo"></div>
      <div class="more-info">
        <p>
          <b>Tourinform Abádszalók</b><br />
          +36 59 357 376<br />
          abadszalok@tourinform.hu<br />
          <br />
          5241 Abádszalók, Deák Ferenc út 1/17.<br />
          <br />
          <b>Open hours:</b><br />
          Monday-Friday 9:00 – 17:00<br />
        </p>
      </div>
    </div>
  </div>
</section>
