/// <reference types="googlemaps" />

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { TrendencyGoogleMapService } from '@trendency/utils/services/google-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-map-button-widget',
  templateUrl: './map-button-widget.component.html',
  styleUrls: ['./map-button-widget.component.scss'],
})
export class MapButtonWidgetComponent implements AfterViewInit, OnDestroy {
  @ViewChild('gmap') gmapElement: ElementRef;
  @Input() buttonLabel: string;
  @Input() buttonUrl: string;
  @Input() lat: string;
  @Input() lng: string;
  @Input() mapUIDisabled = true;

  public map: google.maps.Map;
  public options: google.maps.MapOptions;
  public latLng: google.maps.LatLngLiteral;
  private readonly destroy$ = new Subject();

  constructor(private readonly utilsService: TrendencyUtilsService, private readonly gMapService: TrendencyGoogleMapService) {}

  ngAfterViewInit(): void {
    if (this.lat && this.lng) {
      const latitude = parseFloat(this.lat);
      const longitude = parseFloat(this.lng);
      this.latLng = { lat: +this.lat, lng: +this.lng };
      this.options = {
        disableDefaultUI: true,
        zoom: 10,
        center: {
          lat: latitude,
          lng: longitude,
        },
      };

      if (this.utilsService.isBrowser()) {
        this.gMapService.initGoogle();
        this.gMapService.googleAPIisReady.pipe(takeUntil(this.destroy$)).subscribe((val) => {
          if (val) {
            this.initMap();
          }
        });
      }
    }
  }

  private initMap(): void {
    if (this.utilsService.isBrowser() && this.gmapElement && this.gmapElement.nativeElement) {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.options);
    }
  }

  hasMapData(): string {
    return this.lng && this.lat && this.buttonLabel;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
