import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-fancy-h1',
  templateUrl: './fancy-h1.component.html',
  styleUrls: ['./fancy-h1.component.scss'],
})
export class FancyH1Component extends BaseComponent implements OnInit {
  title: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
  }
}
