<app-header #header [headerMenu]="headerMenu"> </app-header>

<router-outlet></router-outlet>

<button (click)="scrollToTop()" class="to-top" [ngClass]="{ shown: toTopShown }" [attr.aria-label]="'label.to-top' | translate"></button>

<app-sz-2020-logo></app-sz-2020-logo>

<app-footer [footerMenu]="footerMenu" (openHeaderNav)="navContentChange($event)"></app-footer>

<app-cookie-bar></app-cookie-bar>
