import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[corporateButtonLoad]',
})
export class TrendencyButtonLoadDirective {
  private originalText: string | null = null;
  private prev: boolean | null = null;
  private _loadingText: string;

  @Input()
  set loadingText(value: any) {
    this._loadingText = value;
  }

  constructor(private el: ElementRef) {}

  @Input()
  set corporateButtonLoad(loading: boolean) {
    if (this.originalText === null) {
      this.originalText = this.el.nativeElement.innerText;
    }

    if (loading) {
      this.el.nativeElement.innerText = this._loadingText;
      this.el.nativeElement.classList.add('corporate-button-loader');
      this.el.nativeElement.disabled = true;

      this.prev = true;
    } else if (this.prev !== null) {
      this.el.nativeElement.innerText = this.originalText;
      this.el.nativeElement.classList.remove('corporate-button-loader');
      this.el.nativeElement.disabled = false;
    }
  }
}
