<section class="map desktop" id="{{ componentObject | titleToId }}">
  <div *ngIf="componentObject.thematicIcon" [ngClass]="'specicon ' + componentObject.thematicIcon.key" class="specicon"></div>
  <div class="map-container">
    <ng-container *ngIf="!isBudapest" [ngTemplateOutlet]="headSection"></ng-container>
    <div class="map-wrapper" [ngClass]="{ 'greater-budapest': !isBudapest, budapest: isBudapest }">
      <div class="background-container">
        <ng-container *ngIf="isBudapest" [ngTemplateOutlet]="headSection"></ng-container>
        <ng-container *ngFor="let destination of destinations">
          <a
            class="city-button {{ destination?.className }} lang-{{ lang }}"
            *ngIf="destination?.slug"
            [routerLink]="['/' | localize, 'destination' | localize, destination?.slug]"
            [attr.aria-label]="destination?.slug"
          ></a>
          <a
            class="city-button {{ destination?.className }} lang-{{ lang }}"
            *ngIf="destination?.customUrl"
            [href]="destination?.customUrl"
            [target]="destination?.newTab"
            [attr.aria-label]="destination?.customUrl"
          ></a>
          <a
            class="city-button {{ destination?.className }} lang-{{ lang }}"
            *ngIf="destination?.routerLink"
            [routerLink]="destination?.routerLink"
            [attr.aria-label]="destination?.routerLink"
          ></a>
        </ng-container>
      </div>
    </div>
    <div class="button-container">
      <p class="text">
        {{ componentObject?.mapIconSubtitle0 }}
        <br />
        {{ componentObject?.mapIconSubtitle1 }}
        <br />
        {{ componentObject?.mapIconSubtitle2 }}
      </p>
      <button class="change-button" (click)="changeMap()">
        <span class="switch-text">{{ isBudapest ? 'Switch to greater Budapest' : 'Switch to Budapest' }}</span>
        <div class="poser">
          <div [ngClass]="{ 'greater-budapest': !isBudapest, budapest: isBudapest }"></div>
        </div>
      </button>
    </div>
  </div>
</section>

<section class="map mobile">
  <ng-container [ngTemplateOutlet]="headSection"></ng-container>
  <div class="head-container">
    <button class="info-button" [ngClass]="{ active: isBudapest }" (click)="changeMapMobile(true)">
      {{ 'label.budapest' | translate }}
    </button>
    <button class="info-button" [ngClass]="{ active: !isBudapest }" (click)="changeMapMobile(false)">
      {{ 'label.greater.budapest' | translate }}
    </button>
  </div>
  <div #carouselFirst class="map-carousel" [ngClass]="{ active: isBudapest }">
    <ng-container
      [ngTemplateOutlet]="infoWindow"
      [ngTemplateOutletContext]="{ cityData: destination }"
      *ngFor="let destination of destinationsBudapest"
    ></ng-container>
  </div>
  <div #carouselSecond class="map-carousel" [ngClass]="{ active: !isBudapest }">
    <ng-container
      [ngTemplateOutlet]="infoWindow"
      [ngTemplateOutletContext]="{ cityData: destination }"
      *ngFor="let destination of destinationsGreaterBudapest"
    ></ng-container>
  </div>
</section>

<ng-template #headSection>
  <div class="head-section">
    <h2>{{ componentObject?.title | uppercase }}</h2>
    <p class="description">{{ componentObject?.subtitle }}</p>
  </div>
</ng-template>

<ng-template #infoWindow let-cityData="cityData">
  <div class="info-window">
    <a *ngIf="cityData?.slug" [routerLink]="['/' | localize, 'destination' | localize, cityData?.slug]">
      <img [ngSrc]="image?.url | ssrEmptyString" alt="" class="pic" *ngFor="let image of cityData?.images" fill />
      <div class="info-container">
        <div class="city-icon {{ cityData.className }}"></div>
        <p class="city-title">{{ cityData.title }}</p>
      </div>
    </a>

    <a *ngIf="cityData?.customUrl" [href]="cityData?.customUrl" [target]="cityData?.newTab">
      <img [ngSrc]="image.url | ssrEmptyString" alt="" class="pic" *ngFor="let image of cityData?.images" fill />
      <div class="info-container">
        <div class="city-icon {{ cityData.className }}"></div>
        <p class="city-title">{{ cityData.title }}</p>
      </div>
    </a>

    <a *ngIf="cityData?.routerLink" [routerLink]="cityData?.routerLink">
      <img [ngSrc]="image.url | ssrEmptyString" alt="" class="pic" *ngFor="let image of cityData?.images" fill />
      <div class="info-container">
        <div class="city-icon {{ cityData.className }}"></div>
        <p class="city-title">{{ cityData.title }}</p>
      </div>
    </a>
  </div>
</ng-template>
