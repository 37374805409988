import { Component, OnInit } from '@angular/core';
import { RouteMapService } from '../../services/route-map.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-info-cards',
  templateUrl: './info-cards.component.html',
  styleUrls: ['./info-cards.component.scss'],
})
export class InfoCardsComponent extends BaseComponent implements OnInit {
  title: string;
  subtitle: string;
  isGreen: boolean;
  cards: any[];
  filteredCards: any[];
  routerLink: string[];
  showLoadMore: boolean;

  constructor(private readonly routeMap: RouteMapService) {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject.title;
    this.subtitle = this.componentObject.subtitle;
    this.isGreen = this.componentObject.isGreen;
    this.cards = this.componentObject.subComponents.map((card: any) => {
      const sc = card.subComponents && card.subComponents[0];
      return {
        ...card,
        routerLink: this.routeMap.getRoute(sc),
        customUrl: sc.customUrl,
        newTab: card.subComponents[0].newTab,
      };
    });

    if (this.componentObject.loadMore || this.componentObject.loadMore == null) {
      this.filteredCards = this.cards.slice(0, 6);
      this.showLoadMore = this.cards.length > this.filteredCards.length;
    } else {
      this.filteredCards = this.cards;
    }
  }

  loadMore(): void {
    const startIndex = this.filteredCards.length;
    const endIndex = this.filteredCards.length + 6;
    this.filteredCards = this.filteredCards.concat(this.cards.slice(startIndex, endIndex));
    this.showLoadMore = this.cards.length > this.filteredCards.length;
  }
}
