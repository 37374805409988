import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ginop-footer',
  templateUrl: './ginop-footer.component.html',
  styleUrls: ['./ginop-footer.component.scss'],
})
export class GinopFooterComponent implements OnInit {
  @Input() mailto: string;
  pageType: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.pageType = this.route.snapshot.data['pageType'];
    this.mailto = this.mailto != null ? this.mailto : 'event@visithungary.com';
  }
}
