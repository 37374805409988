<section class="type-cards">
  <div class="section-head margin-b">
    <h2>Your Bucket list</h2>
    <p class="desc">
      Anticipation is half the fun! Create the perfect travel plan for your holiday ahead of time! Create your dream holiday and collect
      your favorite activities and destinations here.
    </p>
  </div>

  <div class="wrapper" *ngIf="view === 'desktop'">
    <div class="card-list">
      <ng-container *ngFor="let card of data.list">
        <div class="card-wrapper">
          <app-link-card [data]="card"></app-link-card>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="mobile-wrapper" *ngIf="view === 'mobile'">
    <div class="slider-title">Events you saved</div>
    <div #typeCardsObjPurpleElement class="tiled-cards-obj">
      <ng-container *ngFor="let card of data.list">
        <div *ngIf="card.color === 'purple'">
          <div class="mobile-slide">
            <app-link-card [data]="card" [respStyle]="'resp-style-z'"></app-link-card>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="slider-title">Places you saved</div>
    <div #typeCardsObjRedElement class="tiled-cards-obj">
      <ng-container *ngFor="let card of data.list">
        <div *ngIf="card.color === 'red'">
          <div class="mobile-slide">
            <app-link-card [data]="card" [respStyle]="'resp-style-z'"></app-link-card>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="slider-title">Articles you saved</div>
    <div #typeCardsObjGreenElement class="tiled-cards-obj">
      <ng-container *ngFor="let card of data.list">
        <div *ngIf="card.color === 'green'">
          <div class="mobile-slide">
            <app-link-card [data]="card" [respStyle]="'resp-style-z'"></app-link-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
