import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { RouteMapService } from '../../services/route-map.service';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-detailed-slider',
  templateUrl: './detailed-slider.component.html',
  styleUrls: ['./detailed-slider.component.scss'],
})
export class DetailedSliderComponent extends BaseComponent implements AfterViewInit, OnInit {
  @Input() data: any = require('../../../../assets/mock_jsons/cards-i.json');
  @ViewChild('mainWrapper', { static: true }) mainWrapper: ElementRef;
  @ViewChild('detailedCarouselObjElement', { static: true }) detailedCarouselObjElement: ElementRef;
  actualElemId = 0;
  scrollPos: number;
  wrapperScrollPos: number;
  moveAnimInited = false;

  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonUrl: string;
  cards: any[];

  constructor(private readonly routeMap: RouteMapService, private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject) {
      this.title = this.componentObject.title ? this.componentObject.title : '';
      this.subtitle = this.componentObject.subtitle ? this.componentObject.subtitle : '';
      this.buttonLabel = this.componentObject.buttonLabel ? this.componentObject.buttonLabel : '';
      this.buttonUrl = this.componentObject.buttonUrl ? this.componentObject.buttonUrl : '';

      if (this.componentObject.subComponents) {
        this.cards = this.componentObject.subComponents.map((card: any) => {
          const sc = card.subComponents && card.subComponents[0];
          return {
            ...card,
            routerLink: this.routeMap.getRoute(sc),
          };
        });
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
      const natElem = this.mainWrapper.nativeElement;
      this.wrapperScrollPos = natElem.getBoundingClientRect().y || natElem.getBoundingClientRect().top;
    }
  }

  initSlick(): void {
    $(this.detailedCarouselObjElement.nativeElement).slick({
      dots: true,
      autoplay: false,
      lazyLoad: 'ondemand',
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      speed: 200,
      autoplaySpeed: 3000,
      variableWidth: true,
      arrows: false,
      infinite: true,
      focusOnChange: true,
      responsive: [
        {
          variableWidth: false,
          breakpoint: 1024,
          settings: {
            centerMode: false,
            dots: true,
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ],
    });

    $(this.detailedCarouselObjElement.nativeElement).on('afterChange', (currentSlide: number) => {
      this.actualElemId = currentSlide;
      this.fixAccessibility(currentSlide);
    });
    this.fixAccessibility(0);
  }

  goToPos(event: any): void {
    const srcElement = event.srcElement || event.target;
    if (srcElement && srcElement.getAttribute('data-index')) {
      $(this.detailedCarouselObjElement.nativeElement).slick('slickGoTo', parseInt(srcElement.getAttribute('data-slick-index'), 10));
    }
  }

  next(): void {
    this.actualElemId += 3;

    if (this.actualElemId === this.data.list.length) {
      this.actualElemId = 0;
    } else if (this.actualElemId === this.data.list.length + 1) {
      this.actualElemId = 1;
    } else if (this.actualElemId === this.data.list.length + 2) {
      this.actualElemId = 2;
    }

    this.goto();
  }

  prev(): void {
    this.actualElemId -= 3;

    if (this.actualElemId === -1) {
      this.actualElemId = this.data.list.length - 1;
    } else if (this.actualElemId === -2) {
      this.actualElemId = this.data.list.length - 2;
    } else if (this.actualElemId === -3) {
      this.actualElemId = this.data.list.length - 3;
    }

    this.goto();
  }

  goto(): void {
    $(this.detailedCarouselObjElement.nativeElement).slick('slickGoTo', this.actualElemId);
  }

  private fixAccessibility(currentSlide: number): void {
    this.changeTabindex(currentSlide, -1, '0');
    this.changeTabindex(currentSlide, 2, '-1');
  }

  private changeTabindex(currentSlide: number, steps: number, tabindex: string): void {
    const carouselNativeElement = this.detailedCarouselObjElement.nativeElement;
    const slickSlide: HTMLElement = carouselNativeElement.querySelector(`div.slick-slide[data-slick-index="${currentSlide + steps}"]`);
    setTimeout(() => {
      slickSlide?.setAttribute('tabindex', tabindex);
    }, 500);
  }
}
