import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { SharedService } from '../../services/shared.service';
import { FOCUSABLE_ELEMENTS_QUERY, IFilterOption, ITripFilters } from '../../shared.definitions';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-trip-collection',
  templateUrl: './trip-collection.component.html',
  styleUrls: ['./trip-collection.component.scss'],
})
export class TripCollectionComponent implements OnInit, OnDestroy {
  @ViewChild('tcFilterResults') searchResultsContainer: ElementRef;
  trips: any[];
  activeFilters: ITripFilters;
  maxTripCount: number;
  tripsPerPage: number;
  limitMultiplier: number;
  firstLoaded = false;
  collectionUUID: string;
  showAlertMessage = false;

  private prevScrollPos: any;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly apiService: ApiService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.defaultRequest();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  defaultRequest(): void {
    this.firstLoaded = false;
    this.tripsPerPage = 9;
    this.limitMultiplier = 1;
    const filters: any = {
      search: '',
      destinations: [],
      targetAudiences: [],
      tripLengths: [],
    };
    this.activeFilters = {
      search: '',
      destinations: [],
      targetAudiences: [],
      tripLengths: [],
    };
    this.trips = [];
    this.collectionUUID = this.sharedService.getCollectionUUID(this.route.snapshot, 'Body.Collection.TrippCollection') as string;

    this.apiService
      .getTrips$(0, this.tripsPerPage * this.limitMultiplier, this.collectionUUID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        Object.keys(res.meta.filterOptions.destinationTags_filter).forEach((key) => {
          filters.destinations.push({
            id: +key,
            text: res.meta.filterOptions.destinationTags_filter[key],
            checked: false,
          });
        });
        Object.keys(res.meta.filterOptions.targetAudienceTags_filter).forEach((key) => {
          filters.targetAudiences.push({
            id: +key,
            text: res.meta.filterOptions.targetAudienceTags_filter[key],
            checked: false,
          });
        });
        Object.keys(res.meta.filterOptions.tripLength_filter).forEach((key) => {
          filters.tripLengths.push({
            id: +key,
            text: res.meta.filterOptions.tripLength_filter[key],
            checked: false,
          });
        });
        this.activeFilters = filters;
        this.setFiltersFromQueryParams();
        const queryParams = this.route.snapshot.queryParams;
        this.maxTripCount = res.meta.limit.allRowCount;
        if (
          queryParams['destinationTags_filter'] ||
          queryParams['global_filter'] ||
          queryParams['tripLength_filter'] ||
          queryParams['targetAudienceTags_filter']
        ) {
          this.filterRequest(false);
          this.firstLoaded = true;
        } else {
          this.trips = res.data;
          this.firstLoaded = true;
        }
      });
  }

  filterRequest(isAddition: boolean, shouldBeAlerted = false): void {
    this.showAlertMessage = false;
    if (!this.anyFilterActive()) {
      this.collectionUUID = this.sharedService.getCollectionUUID(this.route.snapshot, 'Body.Collection.TrippCollection') as string;
    } else {
      this.collectionUUID = '';
    }

    this.apiService
      .getTrips$(
        this.tripsPerPage * (this.limitMultiplier - 1),
        this.tripsPerPage * this.limitMultiplier,
        this.collectionUUID,
        this.activeFilters.search,
        this.activeFilters.destinations,
        this.activeFilters.tripLengths,
        this.activeFilters.targetAudiences
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (isAddition) {
          this.trips = [...this.trips, ...res.data];
          this.scrollToPrevPos();
        } else {
          this.trips = res.data;
        }
        this.showAlertMessage = shouldBeAlerted;
        this.maxTripCount = res.meta.limit.allRowCount;
        this.updateQueryParams();
      });
  }

  loadMore(): void {
    this.prevScrollPos = window.scrollY;
    this.limitMultiplier += 1;
    this.filterRequest(true);
  }

  scrollToPrevPos(): void {
    setTimeout(() => {
      document.getElementsByTagName('html')[0].style.setProperty('scroll-behavior', 'unset');
      window.scroll({ top: this.prevScrollPos, behavior: 'auto' });
    }, 0);
  }

  onFilterChange(event: ITripFilters): void {
    this.activeFilters = event;
    this.limitMultiplier = 1;
    this.filterRequest(false, true);
    if (event.scroll) {
      this.scrollToSearchResults();
    }
  }

  private scrollToSearchResults(): void {
    this.searchResultsContainer.nativeElement.scrollIntoView();
    const focusableElements = this.searchResultsContainer.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS_QUERY);
    if (focusableElements?.length) {
      focusableElements[0].focus();
    }
  }

  anyFilterActive(): boolean {
    return (
      this.activeFilters.destinations.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.tripLengths.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.targetAudiences.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.search.length > 0
    );
  }

  removeFilterElement(filterOption: IFilterOption): void {
    filterOption.checked = false;
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest(false);
  }

  clearFilters(): void {
    if (this.activeFilters.destinations.length > 0) {
      this.activeFilters.destinations.forEach((item) => {
        item.checked = false;
      });
    }
    if (this.activeFilters.tripLengths.length > 0) {
      this.activeFilters.tripLengths.forEach((item) => {
        item.checked = false;
      });
    }
    if (this.activeFilters.targetAudiences.length > 0) {
      this.activeFilters.targetAudiences.forEach((item) => {
        item.checked = false;
      });
    }
    this.activeFilters.search = '';
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest(false);
  }

  private updateQueryParams(): void {
    const destinations = this.activeFilters.destinations;
    const tripLengths = this.activeFilters.tripLengths;
    const targetAudiences = this.activeFilters.targetAudiences;

    const queryParams: Params = {};

    if (this.activeFilters.search) {
      queryParams['global_filter'] = this.activeFilters.search;
    }
    if (destinations && destinations.length && destinations.some((f) => f.checked)) {
      queryParams['destinationTags_filter'] = destinations.filter((f) => f.checked).map((f) => f.id);
    }
    if (tripLengths && tripLengths.length && tripLengths.some((f) => f.checked)) {
      queryParams['tripLength_filter'] = tripLengths.filter((f) => f.checked).map((f) => f.id);
    }
    if (targetAudiences && targetAudiences.length && targetAudiences.some((f) => f.checked)) {
      queryParams['targetAudienceTags_filter'] = targetAudiences.filter((f) => f.checked).map((f) => f.id);
    }
    queryParams['scroll'] = false;
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private setFiltersFromQueryParams(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['global_filter']) {
      this.activeFilters.search = queryParams['global_filter'];
    }
    if (queryParams['destinationTags_filter']) {
      const destinationTags_filter = Array.isArray(queryParams['destinationTags_filter'])
        ? queryParams['destinationTags_filter'].map((id) => +id)
        : [+queryParams['destinationTags_filter']];

      this.activeFilters.destinations = this.activeFilters.destinations.map((filter) => ({
        ...filter,
        checked: destinationTags_filter.indexOf(filter.id) !== -1,
      }));
    }
    if (queryParams['tripLength_filter']) {
      const tripLength_filter = Array.isArray(queryParams['tripLength_filter'])
        ? queryParams['tripLength_filter'].map((id) => +id)
        : [+queryParams['tripLength_filter']];

      this.activeFilters.tripLengths = this.activeFilters.tripLengths.map((filter) => ({
        ...filter,
        checked: tripLength_filter.indexOf(filter.id) !== -1,
      }));
    }
    if (queryParams['targetAudienceTags_filter']) {
      const targetAudienceTags_filter = Array.isArray(queryParams['targetAudienceTags_filter'])
        ? queryParams['targetAudienceTags_filter'].map((id) => +id)
        : [+queryParams['targetAudienceTags_filter']];

      this.activeFilters.targetAudiences = this.activeFilters.targetAudiences.map((filter) => ({
        ...filter,
        checked: targetAudienceTags_filter.indexOf(filter.id) !== -1,
      }));
    }
  }
}
