import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateUniversalLoader } from '../../../../../trendency/translations';
import { SharedModule } from '../../../shared/shared.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, SharedModule],
  exports: [FooterComponent],
  providers: [TranslateUniversalLoader],
})
export class FooterModule {}
