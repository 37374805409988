import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberInput]',
})
export class PhoneNumberInputDirective {
  private isDeleting = false;

  constructor(private readonly el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    this.isDeleting = event.key === 'Backspace' || event.key === 'Delete';
  }

  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement;
    let inputValue = inputElement.value;

    if (!this.isDeleting) {
      // Remove all non-numeric characters from the input value
      inputValue = inputValue.replace(/\D/g, '');

      // Ensure the input starts with '+36' (the Hungarian country code)
      if (!inputValue.startsWith('36')) {
        inputValue = '36' + inputValue;
      }

      // Format the phone number as per Hungarian format (06-XX-XXX-XXXX)
      const formattedValue = this.formatPhoneNumber(inputValue);

      inputElement.value = formattedValue;
    }

    this.isDeleting = false;
  }

  private formatPhoneNumber(value: string): string {
    if (value.length <= 2) {
      return value;
    } else if (value.length <= 4) {
      return `+${value.slice(0, 2)}-${value.slice(2)}`;
    } else if (value.length <= 6) {
      return `+${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4)}`;
    } else if (value.length <= 8) {
      return `+${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 7)}-${value.slice(7)}`;
    } else {
      return `+${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 7)}-${value.slice(7, 11)}`;
    }
  }
}
