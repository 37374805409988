import { Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RoutesMap, ROUTES_MAP } from '../routes.map';

@Injectable({
  providedIn: 'root',
})
export class RouteMapService {
  constructor(private readonly localize: LocalizeRouterService) {}

  public getRoute(subComponent: any): string[] | null {
    const key = subComponent?.contentType?.key;
    const slug = subComponent?.contentPage?.slug;

    if (!key || !slug) {
      return null;
    }

    const contentTypeRoute = ROUTES_MAP[key as keyof RoutesMap];
    let route: any = [];

    if (Array.isArray(contentTypeRoute)) {
      route = contentTypeRoute;
    }
    if (contentTypeRoute instanceof String || typeof contentTypeRoute === 'string') {
      route = [contentTypeRoute];
    }

    route.unshift('/');

    const translatedRoute = <string[]>this.localize.translateRoute(route);

    if (slug) {
      translatedRoute.push(slug);
    }

    return translatedRoute?.length ? translatedRoute : null;
  }

  public getRouteByComponentType(componentType: string): string | string[] {
    return ROUTES_MAP[componentType as keyof RoutesMap];
  }
}
