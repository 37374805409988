import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-fancy-h3',
  templateUrl: './fancy-h3.component.html',
  styleUrls: ['./fancy-h3.component.scss'],
})
export class FancyH3Component extends BaseComponent implements OnInit {
  title: string;
  lead: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.lead = this.componentObject?.text;
  }
}
