import { Component } from '@angular/core';

@Component({
  selector: 'app-place-details',
  templateUrl: './place-details.component.html',
  styleUrls: ['./place-details.component.scss'],
})
export class PlaceDetailsComponent {
  isOpened = false;

  openMobileDetails(): void {
    this.isOpened = true;
  }
}
