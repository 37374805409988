<div class="grey-block">
  <div class="wrapper">
    <div class="social col-12">
      <span class="share-iconlist">
        <span class="share-bar-title">{{ 'label.share-this-article' | translate }}</span>
        <a tabindex="0" (click)="facebookShare()" [attr.aria-label]="'label.share-this-article-facebook' | translate">
          <i class="icon icon-facebook-red"></i>
        </a>
        <a tabindex="0" (click)="twitterShare()" [attr.aria-label]="'label.share-this-article-twitter' | translate">
          <i class="icon icon-twitter-red"></i>
        </a>
        <a href="mailto:?subject={{ title }}&body={{ location }}" [attr.aria-label]="'label.send-email' | translate">
          <i class="icon icon-mail-red"></i>
        </a>
      </span>
    </div>
  </div>
</div>
