<div class="section-head discover-now" #mainWrapper>
  <div class="poser">
    <h2 id="{{ componentObject | titleToId }}">{{ title }}</h2>
    <p class="desc keep-resp">{{ subtitle }}</p>
  </div>
</div>

<section class="main-carousel desktop" [ngClass]="{ hidden: view !== 'desktop' }">
  <div
    #mainCarouselObjDesktopElement
    (click)="carouselClick($event)"
    config="[carouselOptions]"
    class="main-carousel-obj-desktop"
    [ngClass]="{ 'move-anim-init': moveAnimInited }"
  >
    <div *ngFor="let block of list">
      <div class="slide">
        <div class="content {{ block.width }}">
          <ng-container *ngFor="let card of block.items">
            <div class="item-block height-{{ card.height }}">
              <app-link-card [localeEventHandling]="false" [data]="card"></app-link-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <a *ngIf="buttonLabel && buttonUrl" [href]="buttonUrl" class="button button-primary button-bottom" [appBackgroundColor]="'red'">{{
    buttonLabel
  }}</a>

  <div class="arrows-container">
    <button class="arrow arrow-prev" (click)="prev()" [attr.aria-label]="'label.prev' | translate">{{ 'label.prev' | translate }}</button>
    <button class="arrow arrow-next" (click)="next()" [attr.aria-label]="'label.next' | translate">{{ 'label.next' | translate }}</button>
  </div>
</section>

<section class="main-carousel mobile" [ngClass]="{ hidden: view !== 'mobile' }">
  <div #mainCarouselObjMobileElement config="[carouselOptions]" class="main-carousel-obj-mobile">
    <ng-container *ngFor="let block of list">
      <ng-container *ngFor="let card of block.items; let k = index">
        <div>
          <div class="mobile-slide">
            <app-link-card [data]="card" [respStyle]="'resp-style-b'"></app-link-card>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <a *ngIf="buttonLabel && buttonUrl" [href]="buttonUrl" class="button button-primary button-bottom" [appBackgroundColor]="'red'">{{
    buttonLabel
  }}</a>
</section>
