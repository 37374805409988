import { Component, Input } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable } from 'rxjs';
import { BaseService } from '../../../core/components/base/base.service';
import { IContentLang } from '../../shared.definitions';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
  @Input() isFooter: boolean;
  isDropdownOpen = false;

  get languages$(): Observable<Array<IContentLang>> {
    return this.baseService.languages$;
  }

  get currentLanguage$(): Observable<IContentLang> {
    return this.baseService.currentLanguage$;
  }

  get isMobileMenuOpen$(): Observable<boolean> {
    return this.baseService.isMobileMenuOpen$;
  }

  constructor(private readonly localizeService: LocalizeRouterService, private readonly baseService: BaseService) {}

  toggleDropdown(val?: boolean): void {
    this.isDropdownOpen = val ?? !this.isDropdownOpen;
  }

  async changeLanguage(language: IContentLang): Promise<void> {
    this.toggleDropdown();
    if (this.baseService.isMobileMenuOpen()) {
      this.baseService.toggleMobileMenu();
    }
    this.localizeService.changeLanguage(language.locale);
  }
}
