import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-brand-logo',
  templateUrl: './brand-logo.component.html',
  styleUrls: ['./brand-logo.component.scss'],
})
export class BrandLogoComponent implements OnInit {
  currentLang: string;
  @Input() inverse: boolean;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }
}
