import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() opened = false;

  Open(): void {
    this.opened = true;
  }

  Close(): void {
    this.opened = false;
  }
}
