<div class="section-look-around-link">
  <div class="wrapper">
    <div class="left">
      <img ngSrc="/assets/images/orszag.webp" alt="Ország" corporateImageLazyLoad fill />
    </div>
    <div class="right">
      <div class="poser">
        <h3 id="{{ componentObject | titleToId }}">{{ title }}</h3>
        <p>{{ lead }}</p>
        <div class="button-wrapper">
          <a
            *ngIf="buttonLabel && buttonUrl"
            [href]="buttonUrl"
            class="button button-primary"
            [appBackgroundColor]="'red'"
            target="_blank"
            >{{ buttonLabel }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
