import { Component } from '@angular/core';
import { TrendencyUtilsService } from '../../../../../trendency/utils';
import { ApiService } from '../../services/api.service';
import { IFile } from '../../shared.definitions';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-market-research',
  templateUrl: './market-research.component.html',
  styleUrls: ['./market-research.component.scss'],
})
export class MarketResearchComponent extends BaseComponent {
  constructor(private readonly apiService: ApiService, private readonly trendencyUtilsService: TrendencyUtilsService) {
    super();
  }

  onClickToggleAccordion(e: any): void {
    const parent = e.target.closest('.accordion__wrapper');
    if (parent.classList.contains('active')) {
      parent.classList.remove('active');
    } else {
      parent.classList.add('active');
    }
  }

  getFilePathFromDocument(document: IFile): void {
    if (this.trendencyUtilsService.isBrowser() && document?.uuid) {
      this.apiService.getFile$(document?.uuid).subscribe((res) => {
        if (res?.path) {
          window.open(res?.path, '_blank');
        }
      });
    }
  }
}
