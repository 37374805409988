import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseComponent } from '../components/base.component';
import { COMPONENT_MAP } from '../content.map';
import { IComponentData, IComponentInfo } from '../shared.definitions';

@Injectable({ providedIn: 'root' })
export class ComponentMapService {
  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) {}

  private readonly mainPageHeroSubject$: BehaviorSubject<IComponentInfo | null> = new BehaviorSubject<IComponentInfo | null>(null);
  public mainPageHero$: Observable<IComponentInfo | null> = this.mainPageHeroSubject$.asObservable();

  public generateComponents(components: IComponentData[], viewContainerRef: ViewContainerRef, withClear = true): void {
    if (withClear) {
      viewContainerRef.clear();
    }
    components.forEach((component) => {
      const componentRef = this.generateComponent(viewContainerRef, component.type);
      const componentObject = this.getComponentAsObject(component);
      if (componentRef) {
        componentRef.instance.component = component;
        componentRef.instance.componentObject = componentObject;
      }
    });
  }

  public generateComponent(viewContainerRef: ViewContainerRef, componentType: string): ComponentRef<BaseComponent> | null {
    const type: Type<BaseComponent> = COMPONENT_MAP[componentType] || null;
    let componentRef: ComponentRef<BaseComponent> | null = null;
    if (type) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
      componentRef = viewContainerRef.createComponent(componentFactory);
    }
    return componentRef;
  }

  public getComponentAsObjectByType(componentDatas: IComponentData[], componentType: string): any {
    const component = componentDatas.find((c) => c.type === componentType);
    return this.getComponentAsObject(component);
  }

  public getComponentAsObjectByUuid(componentDatas: IComponentData[], uuid: string): any {
    const component = componentDatas.find((c) => c.uuid === uuid);
    return this.getComponentAsObject(component);
  }

  private getComponentAsObject(component: IComponentData | undefined): any {
    const result: any = {};

    if (component) {
      if (component?.valueDetails) {
        Object.keys(component.valueDetails).forEach((key) => {
          if (key !== 'title' && key !== 'slug') {
            result[key] = component.valueDetails[key];
          }
        });
      }
      component.fields.forEach((field) => {
        const value = field.properties || field.value;
        if (field.valueDetails) {
          const detailKeys = Object.keys(field.valueDetails);
          detailKeys.forEach((key) => {
            value[key] = field.valueDetails[key];
          });
        }
        result[field.key] = value;
      });
      if (component.subComponents.length) {
        result.subComponents = [];
        component.subComponents.forEach((subComponent) => {
          const subElem = this.getComponentAsObject(subComponent);
          result.subComponents.push(subElem);
        });
      }
    }

    return result;
  }

  setMainPageHeroComponent(component: IComponentData, componentObject: any): void {
    this.mainPageHeroSubject$.next({ component, componentObject });
  }
}
