import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {
  // dev hívás megvalósítása: http://csodasmagyarorszag-api.dev.trendency.hu/publicapi/hu/summer-accomodation/UUID/applications/en
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    if (req.url.includes('/summer-accomodation/')) {
      const domainReplacement = environment.domainReplacement;
      const newUrl = req.url.replace(RegExp(`^(.+)${domainReplacement![0]}(.+)$`, 'g'), `$1${domainReplacement![1]}$2`);
      newReq = req.clone({ url: newUrl });
    }
    return next.handle(newReq);
  }
}
