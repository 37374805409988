import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appBackgroundColor]',
})
export class BackgroundColorDirective implements OnInit {
  @Input('appBackgroundColor') color: string;

  constructor(private readonly el: ElementRef) {}

  ngOnInit(): void {
    if (this.color === '6499D1' || this.color === '70A3D7') {
      this.color = 'blue-light';
    }
    if (this.color === '7C51A1') {
      this.color = 'purple';
    }
    if (this.color === '38BA9B') {
      this.color = 'green';
    }
    if (this.color === 'EF4E69') {
      this.color = 'red';
    }
    if (this.color === 'F2994A') {
      this.color = 'orange';
    }
    this.el.nativeElement.style.backgroundImage = "url('assets/images/colors/" + this.color + ".svg')";
  }
}
