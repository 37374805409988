import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appYearInput]',
})
export class YearInputDirective {
  @Input('appYearInput') yearLimit: number;

  constructor(private readonly el: ElementRef, private readonly ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;
    const numericValue = parseInt(inputValue, 10);

    if (!isNaN(numericValue)) {
      // Limit the input to 4 digits
      if (numericValue >= 10000) {
        inputElement.value = inputValue.slice(0, 4);
      }

      // Ensure the value doesn't exceed the current year
      if (this.yearLimit && numericValue > this.yearLimit) {
        inputElement.value = this.yearLimit.toString();
      }
      this.ngControl.control?.setValue(parseInt(inputElement.value), { emitEvent: false });
    }
  }
}
