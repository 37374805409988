<section class="listing" #mainWrapper>
  <div class="section-head">
    <h2 class="head-title" *ngIf="this.componentObject.title">{{ this.componentObject.title }}</h2>
    <p class="desc" *ngIf="this.componentObject.lead">{{ this.componentObject.lead }}</p>
  </div>
  <div class="wrapper">
    <div class="left">
      <div class="search-box">
        <div class="filter-box-title">{{ 'label.filter' | translate }}</div>
        <div class="search-inner-box">
          <i class="icon icon-search-green"></i>
          <label class="cdk-visually-hidden" for="ac-search">{{ 'label.filter-search-placeholder' | translate }}</label>
          <input
            class="text-input"
            type="search"
            placeholder="{{ 'label.filter-search-placeholder' | translate }}"
            (keydown.enter)="onSearch()"
            [formControl]="searchControl"
            id="ac-search"
          />
          <button class="icon icon-arrow-right-green submit-search-btn" (click)="onSearch()"></button>
        </div>
      </div>
      <div class="filter-box">
        <ng-container *ngIf="activeFilters?.destinations?.length">
          <div class="filter-box-section">
            <div class="filter-box-title">{{ 'label.destinations' | translate }}</div>
            <button class="clear-all-filter" *ngIf="destinationFilterActive()" (click)="onFilterClear('destinations')">
              <i class="icon icon-close-red"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
            <ng-container *ngFor="let filterOption of activeFilters.destinations">
              <div class="option" (click)="onOptionClick(filterOption)">
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="activeFilters?.serviceTypes?.length">
          <div class="filter-box-section">
            <div class="filter-box-title">{{ 'label.service-types' | translate }}</div>
            <button class="clear-all-filter" *ngIf="serviceTypesFilterActive()" (click)="onFilterClear('serviceTypes')">
              <i class="icon icon-close-red"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
            <ng-container *ngFor="let filterOption of activeFilters.serviceTypes">
              <div class="option" (click)="onOptionClick(filterOption)">
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="activeFilters?.summerServices?.length">
          <div class="filter-box-section">
            <div class="filter-box-title">{{ 'label.service-types' | translate }}</div>
            <button class="clear-all-filter" *ngIf="summerServicesFilterActive()" (click)="onFilterClear('summerServices')">
              <i class="icon icon-close-red"></i>
              {{ 'label.clear-filter' | translate }}
            </button>
            <ng-container *ngFor="let filterOption of activeFilters.summerServices">
              <div class="option" (click)="onOptionClick(filterOption)">
                <div class="checker" [ngClass]="{ checked: filterOption.checked }">
                  <div class="check"></div>
                </div>
                <span class="text">{{ filterOption.text }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="right">
      <div class="campaign-head" *ngIf="this.title">
        <h3>{{ this.title }}</h3>
      </div>
      <ng-container *ngIf="applications.length === 0">
        {{ 'CAMPAIGN.noresult' | translate }}
      </ng-container>
      <ng-container *ngFor="let result of applications">
        <a *ngIf="result.url" [href]="result.url" class="result-link-elem" target="_blank">
          <div class="tag">
            <ng-container *ngFor="let serviceType of result.serviceTypes; let last = last">
              <span>{{ serviceType.title }}<span *ngIf="!last || result.destinationTag">, </span></span>
            </ng-container>
            <ng-container *ngIf="result.destinationTag">
              <span>{{ result.destinationTag.label }}</span>
            </ng-container>
          </div>
          <div class="name">{{ result.partnerName }}</div>
        </a>
        <a *ngIf="result.accomodationWebPageEn" [href]="result.accomodationWebPageEn" class="result-link-elem" target="_blank">
          <div class="tag">
            <ng-container *ngIf="result.typeAccommodation">{{ 'CAMPAIGN.type.accommodation' | translate }}, </ng-container>
            <ng-container *ngIf="result.typeCateringUnit">{{ 'CAMPAIGN.type.cateringunit' | translate }}, </ng-container>
            <ng-container *ngIf="result.typeAttraction">{{ 'CAMPAIGN.type.attraction' | translate }}, </ng-container>
            <ng-container *ngIf="result.destinationTag">{{
              'CAMPAIGN.destination.' + result.destinationTag.slug | translate
            }}</ng-container>
            <ng-container *ngIf="!result.destinationTag">{{ 'CAMPAIGN.destination.other' | translate }}</ng-container>
          </div>
          <div class="name">{{ result.accomodationName }}</div>
          <img *ngIf="result.isKpp" class="kpp-image" ngSrc="/assets/images/kpp.webp" alt="KPP" fill />
        </a>
      </ng-container>
      <div class="bottom-button-box">
        <button
          class="button load-button button-secondary"
          [ngClass]="{ hidden: applicationMaxLength <= applicationLength }"
          (click)="loadMore()"
        >
          {{ 'label.load-more' | translate }} ({{ applicationMaxLength - applicationLength }})
        </button>
      </div>
    </div>
  </div>
</section>
