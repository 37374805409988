<footer [appBackgroundColor]="'blue'">
  <section class="wrapper desktop">
    <div class="desktop-top">
      <div class="desktop-top__left">
        <app-brand-logo></app-brand-logo>
      </div>
      <div class="desktop-top__right">
        <app-language-switcher [isFooter]="true"></app-language-switcher>
        <app-social-icons [isRoundedDesktop]="true"></app-social-icons>
      </div>
    </div>

    <div class="desktop-mid">
      <ng-container *ngFor="let item of menu">
        <ng-container
          [ngTemplateOutlet]="menuTree"
          [ngTemplateOutletContext]="{ menuTree: item.menu, label: item.label }"
          *ngIf="item?.menu"
        ></ng-container>
      </ng-container>
    </div>

    <div class="desktop-bottom">
      <div class="logos-box">
        <ng-container [ngTemplateOutlet]="mtuLink"></ng-container>
        <app-spice-of-europe-link></app-spice-of-europe-link>
      </div>
      <div class="contact-info">
        <ng-container [ngTemplateOutlet]="footerTitle"></ng-container>
        <ng-container [ngTemplateOutlet]="footerLead"></ng-container>
      </div>
    </div>
  </section>

  <section class="wrapper mobile">
    <div class="mobile-footer">
      <div class="mobile-footer-top">
        <app-brand-logo></app-brand-logo>
      </div>

      <div class="main-links">
        <ng-container *ngFor="let item of menu">
          <ng-container
            [ngTemplateOutlet]="openNavItemButton"
            [ngTemplateOutletContext]="{ id: item.id, label: item.label, color: item.color, menuTree: item.menu }"
            *ngIf="item?.menu"
          ></ng-container>
        </ng-container>
      </div>

      <div class="mobile-footer-content">
        <ng-container [ngTemplateOutlet]="footerTitle"></ng-container>
        <ng-container [ngTemplateOutlet]="footerLead"></ng-container>
      </div>

      <div class="row logos-box">
        <div class="column col-6">
          <ng-container [ngTemplateOutlet]="mtuLink"></ng-container>
        </div>
        <div class="column col-6">
          <app-spice-of-europe-link></app-spice-of-europe-link>
        </div>
      </div>
    </div>
  </section>
  <app-footer-bottom [isSocialActive]="true"></app-footer-bottom>
</footer>

<ng-template #menuTree let-menuTree="menuTree" let-label="label">
  <div *ngIf="menuTree?.tree?.length" class="column">
    <p class="menu-headline">{{ label | translate }}</p>
    <ul *ngFor="let item of menuTree.tree">
      <li>
        <a *ngIf="item?.customUrl" [href]="item.customUrl" [target]="item.targetBlank ? '_blank' : '_self'">
          <span class="link">{{ item.label }}</span>
        </a>
        <a *ngIf="item?.routerLink" [routerLink]="item.routerLink">
          <span class="link">{{ item.label }}</span>
        </a>
      </li>
      <li *ngFor="let child of item.children">
        <a *ngIf="child?.customUrl" class="link small" [href]="child.customUrl" [target]="child.targetBlank ? '_blank' : '_self'">{{
          child.label
        }}</a>
        <a *ngIf="child?.routerLink" class="link small" [routerLink]="child.routerLink">{{ child.label }}</a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #openNavItemButton let-id="id" let-label="label" let-color="color" let-menuTree="menuTree">
  <button class="main-link" [class]="color" [appBackgroundColor]="color" (click)="openHeaderNavContent(id)" *ngIf="menuTree?.tree?.length">
    {{ label | translate }}
    <i class="icon icon-arrow-right-button-white"></i>
  </button>
</ng-template>

<ng-template #mtuLink>
  <a class="link" href="https://mtu.gov.hu" target="_blank" [attr.aria-label]="'label.hungarian-tourism-agency' | translate"
    ><i class="icon icon-mtu"></i
  ></a>
</ng-template>

<ng-template #footerTitle>
  <p class="menu-headline" *ngIf="(footerData$ | async)?.footerContactTitle">{{ (footerData$ | async).footerContactTitle }}</p>
</ng-template>

<ng-template #footerLead>
  <div
    *ngIf="(footerData$ | async)?.footerContactLead"
    class="text-linklike no-text-transform"
    [innerHTML]="(footerData$ | async).footerContactLead"
  ></div>
</ng-template>
