<section class="link-collection">
  <div class="container">
    <div class="link-collection__wrapper">
      <h3 class="link-collection__title" *ngIf="title">{{ title }}</h3>

      <div class="panel panel--main">
        <div class="panel__wrapper" *ngFor="let panel of subComponents">
          <a class="panel__title" (click)="scrollTo(getNiceId(panel.title))">{{ panel.title }}</a>
          <p class="panel__description" *ngIf="panel?.description" [innerHTML]="panel.description"></p>
        </div>
      </div>

      <div class="panel panel--sub" *ngFor="let panel of subComponents">
        <h4 class="panel__headline" [id]="getNiceId(panel.title)" *ngIf="panel?.title">{{ panel.title }}</h4>
        <div class="panel__wrapper" *ngFor="let subPanel of panel.subComponents">
          <a [href]="subPanel?.link" class="panel__title" target="_blank" *ngIf="subPanel?.buttonText && subPanel?.link">{{
            subPanel.buttonText
          }}</a>
          <button
            class="panel__title"
            *ngIf="subPanel?.buttonText && subPanel?.document"
            (click)="getFilePathFromDocument(subPanel.document)"
          >
            {{ subPanel.buttonText }}
          </button>
          <p class="panel__description" *ngIf="subPanel?.description" [innerHTML]="subPanel.description"></p>
        </div>
      </div>
    </div>
  </div>
</section>
