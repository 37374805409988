import { Component, Input, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RouteMapService } from '../../services/route-map.service';
import { SharedService } from '../../services/shared.service';
import { ITag } from '../../shared.definitions';

@Component({
  selector: 'app-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss'],
})
export class LinkCardComponent implements OnInit {
  @Input() respStyle = 'resp-style-a';
  @Input() data: any;
  @Input() localeEventHandling = true;
  isLoggedIn = false;
  isAlreadyFav = false;
  internalLink: string[];
  externalLink: string;
  tag: ITag;
  newTab: boolean;

  constructor(
    private readonly sharedService: SharedService,
    private readonly routeMap: RouteMapService,
    private readonly localize: LocalizeRouterService
  ) {}

  ngOnInit(): void {
    if (this.data.subComponents) {
      const subComponent = this.data.subComponents && this.data.subComponents[0];
      if (subComponent.customUrl !== null) {
        this.externalLink = subComponent.customUrl;
        this.newTab = subComponent.newTab;
      } else {
        this.internalLink = this.routeMap.getRoute(subComponent) as string[];
      }
    }
    if (this.data.event) {
      this.internalLink = [this.localize.translateRoute('/'), this.localize.translateRoute('events'), this.data.event.slug];
    }

    this.tag = this.sharedService.getTagColorAndValue(this.data);
  }

  favButtonAction(): void {
    if (this.localeEventHandling === false) {
      return;
    }

    if (this.isLoggedIn) {
      if (this.isAlreadyFav) {
        this.setActiveDeleteElementPopup(true);
      }
    } else {
      this.setActiveLoginPopup(true);
    }
  }

  setActiveLoginPopup(active: boolean): void {
    this.sharedService.setActiveLoginPopup(active);
  }

  setActiveDeleteElementPopup(active: boolean): void {
    this.sharedService.setActiveDeleteElementPopup(active);
  }
}
