import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-fancy-h2',
  templateUrl: './fancy-h2.component.html',
  styleUrls: ['./fancy-h2.component.scss'],
})
export class FancyH2Component extends BaseComponent implements OnInit {
  isCentered: boolean;
  title: string;
  lead: string;
  link: string;
  linkText: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.component.type === 'Body.TitleMiddleText') {
      this.isCentered = true;
    }
    this.title = this.componentObject?.title;
    this.lead = this.componentObject?.text;
    this.link = this.componentObject?.link;
    this.linkText = this.componentObject?.linkTitle;
  }
}
