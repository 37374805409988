import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';

interface ITag {
  label: string;
}

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.scss'],
})
export class TripInfoComponent extends BaseComponent implements OnInit {
  public meta: any;
  public destinationTags: ITag[];
  public targetAudienceTags: ITag[];

  constructor(private readonly route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.meta = this.route.snapshot.data['data'].data.meta;
    this.iniTags();
  }

  getIconLabel(key: string): string {
    return `label.${key}`;
  }

  iniTags(): void {
    const relationsList = this.meta.filter((item: any) => item.type === 'Meta.Relation.Trip');
    if (relationsList.length && relationsList.length > 0) {
      for (const field of relationsList[0].fields) {
        if (field.key === 'destinationTags') {
          this.destinationTags = field.value !== null ? field.value : [];
        }
        if (field.key === 'targetAudienceTags') {
          this.targetAudienceTags = field.value !== null ? field.value : [];
        }
      }
    }
  }
}
