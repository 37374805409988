import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-article-lead',
  templateUrl: './article-lead.component.html',
  styleUrls: ['./article-lead.component.scss'],
})
export class ArticleLeadComponent extends BaseComponent implements OnInit {
  label: string;
  url: string;
  lat: string;
  lng: string;
  lead: string;
  shortenedLead: string;
  showReadMore: boolean;
  showReadLess: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.lead = this.componentObject.lead;
    this.shortenedLead = this.shortenText();

    if (this.componentObject && this.componentObject.subComponents && this.componentObject.subComponents.length > 0) {
      this.label = this.componentObject.subComponents[0].buttonLabel;
      this.url = this.componentObject.subComponents[0].buttonUrl;
      this.lat = this.componentObject.subComponents[0].lat;
      this.lng = this.componentObject.subComponents[0].lng;
    }
  }

  onReadMore(): void {
    this.shortenedLead = this.lead;
    this.showReadMore = false;
    this.showReadLess = true;
  }

  onReadLess(): void {
    this.shortenedLead = this.shortenText();
  }

  private shortenText(): string {
    const words = this.lead.split(' ');
    if (words.length > 80) {
      this.showReadMore = true;
      this.showReadLess = false;
      return words.slice(0, 80).join(' ') + ' ... </p>';
    }
    return this.lead;
  }
}
