/// <reference types="googlemaps" />
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { TrendencyGoogleMapService } from '@trendency/utils/services/google-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-full-width-map',
  templateUrl: './full-width-map.component.html',
  styleUrls: ['./full-width-map.component.scss'],
})
export class FullWidthMapComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('gmap') gmapElement: ElementRef;
  @Input() lat = 1;
  @Input() lng = 1;
  @Input() mapUIDisabled = true;

  latLng: any;
  public map: google.maps.Map;
  public options: google.maps.MapOptions;
  public googleMapsUrl: string;
  private readonly destroy$ = new Subject();

  constructor(private readonly utilsService: TrendencyUtilsService, private readonly gMapService: TrendencyGoogleMapService) {}

  ngOnInit(): void {
    if (this.lat && this.lng) {
      this.googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.lat},${this.lng}`;
    }
  }

  ngAfterViewInit(): void {
    this.latLng = { lat: +this.lat, lng: +this.lng };
    this.options = {
      disableDefaultUI: this.mapUIDisabled,
      zoom: 13,
      center: this.latLng,
    };

    if (this.utilsService.isBrowser()) {
      this.gMapService.initGoogle();
      this.gMapService.googleAPIisReady.pipe(takeUntil(this.destroy$)).subscribe((val) => {
        if (val && this.lat && this.lng) {
          this.initMap(this.latLng);
        }
      });
    }
  }

  private initMap(latLng: google.maps.LatLngLiteral): void {
    if (this.utilsService.isBrowser()) {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.options);

      const image = {
        url: '/assets/images/icons/pin-big.svg',
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(40, 48),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(20, 48),
      };

      new google.maps.Marker({
        map: this.map,
        position: latLng,
        icon: image,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
