<section class="market-research" *ngIf="componentObject?.subComponents?.length">
  <div class="container">
    <h2 class="market-research__title" *ngIf="componentObject?.title">{{ componentObject.title }}</h2>
    <div class="accordion">
      <div class="accordion__wrapper" *ngFor="let item of componentObject.subComponents">
        <div class="accordion__header">
          <button
            class="accordion__title accordion__title--full"
            (click)="onClickToggleAccordion($event)"
            *ngIf="!item?.customUrl && item?.title"
          >
            {{ item.title }}
          </button>
          <a [href]="item?.customUrl" class="accordion__title" target="_blank" *ngIf="item?.customUrl && item?.title">{{ item.title }}</a>

          <button class="accordion__opener" (click)="onClickToggleAccordion($event)"></button>
        </div>

        <div class="accordion__body" *ngIf="item?.subComponents?.length">
          <button class="accordion__row" *ngFor="let row of item.subComponents" (click)="getFilePathFromDocument(row.document)">
            <span class="accordion__name" *ngIf="row?.title">{{ row.title }}</span>
            <span class="accordion__file-size" *ngIf="row?.document?.size">{{ row.document.size | filesize }}</span>
          </button>
        </div>

        <div class="accordion__body accordion__description" [innerHTML]="item.description" *ngIf="item?.description?.length"></div>
      </div>
    </div>
  </div>
</section>
