<div *ngIf="this.component?.uuid" id="{{ componentObject | titleToId }}"></div>
<section class="map desktop">
  <div *ngIf="componentObject?.thematicIcon" [ngClass]="'specicon ' + componentObject.thematicIcon.key" class="specicon"></div>

  <div class="map-container">
    <ng-container [ngTemplateOutlet]="mapHeadline"></ng-container>

    <div [ngClass]="['map-wrapper', lang]">
      <button
        *ngFor="let destination of destinations"
        class="city-button {{ destination?.className }}"
        (click)="openWindow(destination)"
        [attr.aria-label]="destination?.title + ' ' + ('label.open-info-window' | translate)"
      >
        <div class="poser">
          <div class="bg"></div>
          <div class="hover citymarker"></div>
        </div>
      </button>
    </div>

    <div #floatingInfoWindow>
      <ng-container
        [ngTemplateOutlet]="infoWindow"
        [ngTemplateOutletContext]="{ cityData: cityData, isCloseButtonActive: true }"
      ></ng-container>
    </div>

    <div class="bottom-row">
      <div class="left"></div>
      <div class="right">
        <div class="small-map-poser">
          <p>
            <ng-container *ngIf="componentObject?.mapIconSubtitle0">{{ componentObject.mapIconSubtitle0 }}</ng-container>
            <br />
            <ng-container *ngIf="componentObject?.mapIconSubtitle1">{{ componentObject.mapIconSubtitle1 }}</ng-container>
            <br />
            <ng-container *ngIf="componentObject?.mapIconSubtitle2">{{ componentObject.mapIconSubtitle2 }}</ng-container>
          </p>
          <i
            *ngIf="componentObject?.mapIcon?.key"
            class="icon {{ componentObject.mapIcon ? componentObject.mapIcon.key : 'no-key-found' }}"
          ></i>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="map mobile">
  <ng-container [ngTemplateOutlet]="mapHeadline"></ng-container>

  <div #carousel class="map-carousel">
    <ng-container
      [ngTemplateOutlet]="infoWindow"
      [ngTemplateOutletContext]="{ cityData: destination }"
      *ngFor="let destination of destinations"
    ></ng-container>
  </div>
</section>

<ng-template #mapHeadline>
  <div class="section-head" *ngIf="componentObject?.title || componentObject?.subtitle">
    <h2 *ngIf="componentObject?.title">{{ componentObject.title }}</h2>
    <p *ngIf="componentObject?.subtitle" class="desc">{{ componentObject.subtitle }}</p>
  </div>
</ng-template>

<ng-template #infoWindow let-cityData="cityData" let-isCloseButtonActive="isCloseButtonActive">
  <div class="info-window" [class.is-floating]="isCloseButtonActive">
    <div class="top-pics">
      <button class="close-button" (click)="closeWindow()" [attr.aria-label]="'label.close' | translate" *ngIf="isCloseButtonActive">
        <i class="icon icon-close-white"></i>
      </button>
      <img
        [ngSrc]="image.url | ssrEmptyString"
        [alt]="image?.image?.title"
        class="pic"
        [class.one]="count === 1"
        *ngFor="let image of cityData?.images; count as count"
        fill
      />
      <div
        class="city-icon"
        *ngIf="lastSelectedId"
        [ngStyle]="{ 'background-image': 'url(\'/assets/images/cities-notitle/' + lastSelectedId + '.svg\')' } | ssrEmptyString"
      ></div>
    </div>

    <div class="text-content" *ngIf="cityData?.title || cityData?.mapLead">
      <h4 class="lead" *ngIf="cityData?.title">{{ cityData.title }}</h4>
      <p *ngIf="cityData?.mapLead">{{ cityData?.mapLead }}</p>
      <p *ngIf="!cityData?.mapLead && cityData?.lead">{{ cityData?.lead }}</p>
    </div>

    <div class="row distances" *ngIf="cityData?.distanceFromBudapestViaCar || cityData?.distanceFromBudapestViaTransport">
      <div class="col-12 header">{{ 'label.distance-from-budapest' | translate }}</div>
      <div class="col-6" *ngIf="cityData?.distanceFromBudapestViaCar">
        <i class="icon icon-car-black"></i>{{ cityData.distanceFromBudapestViaCar }}
      </div>
      <div class="col-6" *ngIf="cityData?.distanceFromBudapestViaTransport">
        <i class="icon icon-train"></i>{{ cityData.distanceFromBudapestViaTransport }}
      </div>
    </div>

    <a
      [routerLink]="['/' | localize, 'destination' | localize, cityData?.slug]"
      [attr.aria-label]="('destination' | localize) + '/' + cityData?.slug"
      *ngIf="cityData?.slug"
      class="button button-primary"
      [appBackgroundColor]="'red'"
      >{{ 'label.explore' | translate }}
    </a>
    <a
      [href]="cityData?.customUrl"
      *ngIf="cityData?.customUrl"
      [attr.aria-label]="cityData?.customUrl"
      [target]="cityData?.newTab"
      [appBackgroundColor]="'red'"
      class="button button-primary"
      >{{ 'label.explore' | translate }}
    </a>
    <a
      [routerLink]="cityData?.routerLink"
      [attr.aria-label]="cityData?.routerLink"
      *ngIf="cityData?.routerLink"
      [appBackgroundColor]="'red'"
      class="button button-primary"
      >{{ 'label.explore' | translate }}
    </a>
  </div>
</ng-template>
