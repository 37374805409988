import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DateLabelService {
  private readonly timeZone = 'Europe/Budapest';
  private currentLang = this.translateService.currentLang;

  constructor(private readonly translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = this.translateService.currentLang;
      moment.locale(this.currentLang);
    });
    moment.locale(this.currentLang);
  }

  getValidDateFormat(date: Date | string): Date {
    return date instanceof Date ? date : new Date(date.replace(' ', 'T'));
  }

  createLabelSingle(date: string | Date): string {
    const labelDate = this.getValidDateFormat(date);
    const fullFormat = moment.localeData(this.currentLang).longDateFormat('LL');
    return moment.utc(labelDate).tz(this.timeZone).format(fullFormat);
  }

  createLabel(date1: string | Date, date2: string | Date): string {
    const from = this.getValidDateFormat(date1);
    const till = this.getValidDateFormat(date2);

    const fromYear = moment.utc(from).tz(this.timeZone).format('YYYY');
    const fromMonth = moment.utc(from).tz(this.timeZone).format('MMMM');
    const fromDay = moment.utc(from).tz(this.timeZone).format('DD');

    const tillYear = moment.utc(till).tz(this.timeZone).format('YYYY');
    const tillMonth = moment.utc(till).tz(this.timeZone).format('MMMM');
    const tillDay = moment.utc(till).tz(this.timeZone).format('DD');

    let labelHtml = '';
    if (from.getFullYear() === till.getFullYear()) {
      if (from.getMonth() === till.getMonth()) {
        if (from.getDate() === till.getDate()) {
          labelHtml = `${fromDay} ${fromMonth}, ${fromYear}`;
        } else {
          labelHtml = `${fromDay}-${tillDay} ${fromMonth}, ${fromYear}`;
        }
      } else {
        labelHtml = `${fromDay} ${fromMonth} - ${tillDay} ${tillMonth}, ${fromYear}`;
      }
    } else {
      labelHtml = `${fromDay} ${fromMonth}, ${fromYear} - ${tillDay} ${tillMonth}, ${tillYear}`;
    }
    return labelHtml;
  }

  formatTime(time: string): string {
    return time.slice(0, 5);
  }

  getYearMonth(date: Date): string {
    return moment.utc(this.getValidDateFormat(date)).tz(this.timeZone).format('YYYY-MM');
  }

  fetchTranslatedMonthNameFromMoment(date: Date | string): string {
    return moment.utc(this.getValidDateFormat(date)).tz(this.timeZone).format('MMMM');
  }

  fetchTranslatedDayNameFromMoment(date: Date | string): string {
    return moment.utc(this.getValidDateFormat(date)).tz(this.timeZone).format('dddd');
  }
}
