import { Component, Input } from '@angular/core';
import { IInfoCard } from '../../shared.definitions';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-info-card-collection',
  templateUrl: './info-card-collection.component.html',
  styleUrls: ['./info-card-collection.component.scss'],
})
export class InfoCardCollectionComponent extends BaseComponent {
  @Input() cards: IInfoCard[];

  constructor() {
    super();
  }
}
