import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { TrendencyLoadingModule } from '../../../trendency/loading';
import { TrendencyUtilsModule } from '../../../trendency/utils';
import { GinopFooterComponent } from '../features/ginop/components/ginop-footer/ginop-footer.component';
import { GinopHeaderComponent } from '../features/ginop/components/ginop-header/ginop-header.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { AnchorBlockComponent } from './components/anchor-block/anchor-block.component';
import { ApplicationCollectionComponent } from './components/application-collection/application-collection.component';
import { ArticleCollectionComponent } from './components/article-collection/article-collection.component';
import { ArticleContentComponent } from './components/article-content-to-delete/article-content.component';
import { ArticleLeadComponent } from './components/article-lead/article-lead.component';
import { B2bCollectionComponent } from './components/b2b-collection/b2b-collection.component';
import { BigpicCarouselComponent } from './components/bigpic-carousel/bigpic-carousel.component';
import { BrandLogoComponent } from './components/brand-logo/brand-logo.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BusinessCardComponent } from './components/business-card/business-card.component';
import { ButtonComponent } from './components/button/button.component';
import { CardWDescComponent } from './components/card-w-desc/card-w-desc.component';
import { CenteredCarouselComponent } from './components/centered-carousel/centered-carousel.component';
import { ContactCollectionComponent } from './components/contact-collection/contact-collection.component';
import { ContentImageComponent } from './components/content-image/content-image.component';
import { CookieBarComponent } from './components/cookie-bar/cookie-bar.component';
import { DestinationIconComponent } from './components/destination-icon/destination-icon.component';
import { DetailedCardComponent } from './components/detailed-card/detailed-card.component';
import { DetailedSliderComponent } from './components/detailed-slider/detailed-slider.component';
import { DocumentGroupCardComponent } from './components/document-group-card/document-group-card.component';
import { EventCollectionFilterComponent } from './components/event-collection-filter/event-collection-filter.component';
import { EventCollectionComponent } from './components/event-collection/event-collection.component';
import { FaceCollectionComponent } from './components/face-collection/face-collection.component';
import { FancyH1Component } from './components/fancy-h1/fancy-h1.component';
import { FancyH2Component } from './components/fancy-h2/fancy-h2.component';
import { FancyH3Component } from './components/fancy-h3/fancy-h3.component';
import { FilterCollectionComponent } from './components/filter-collection/filter-collection.component';
import { FilterEventComponent } from './components/filter-event/filter-event.component';
import { FilterItineraryComponent } from './components/filter-itinerary/filter-itinerary.component';
import { FlagComponent } from './components/flag/flag.component';
import { FooterBottomComponent } from './components/footer-bottom/footer-bottom.component';
import { FullWidthMapComponent } from './components/full-width-map/full-width-map.component';
import { GalleryCarouselComponent } from './components/gallery-carousel/gallery-carousel.component';
import { H1PlusLeadComponent } from './components/h1-plus-lead/h1-plus-lead.component';
import { InfoCardCollectionComponent } from './components/info-card-collection/info-card-collection.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { InstagramBlockComponent } from './components/instagram-block/instagram-block.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LinkCardComponent } from './components/link-card/link-card.component';
import { LinkCollectionComponent } from './components/link-collection/link-collection.component';
import { LookAroundLinkComponent } from './components/look-around-link/look-around-link.component';
import { CardGroupComponent } from './components/main-carousel/components/card-group/card-group.component';
import { MainCarouselComponent } from './components/main-carousel/main-carousel.component';
import { MapButtonWidgetComponent } from './components/map-button-widget/map-button-widget.component';
import { MapFullWidthComponent } from './components/map-full-width/map-full-width.component';
import { MapComponent } from './components/map/map.component';
import { MarketResearchComponent } from './components/market-research/market-research.component';
import { MoveAroundComponent } from './components/move-around/move-around.component';
import { PageHeadNewComponent } from './components/page-head-new/page-head-new.component';
import { PageHeadSimpleComponent } from './components/page-head-simple/page-head-simple.component';
import { PageHeadComponent } from './components/page-head/page-head.component';
import { PageLeadComponent } from './components/page-lead/page-lead.component';
import { PlaceDetailsComponent } from './components/place-details/place-details.component';
import { PlanCardsComponent } from './components/plan-cards/plan-cards.component';
import { PodcastComponent } from './components/podcast/podcast.component';
import { PopupLoginComponent } from './components/popup/components/popup-login/popup-login.component';
import { PopupComponent } from './components/popup/popup.component';
import { PracticalInfoComponent } from './components/practical-info/practical-info.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { SeasonCardsComponent } from './components/season-cards/season-cards.component';
import { ShareBarComponent } from './components/share-bar/share-bar.component';
import { SocialIconsComponent } from './components/social-icons/social-icons.component';
import { SpiceMapNewComponent } from './components/spice-map-new/spice-map-new.component';
import { SpiceMapComponent } from './components/spice-map/spice-map.component';
import { SpiceOfEuropeLinkComponent } from './components/spice-of-europe-link/spice-of-europe-link.component';
import { SpiceOfEuropeLogoComponent } from './components/spice-of-europe-logo/spice-of-europe-logo.component';
import { Sz2020LogoComponent } from './components/sz-2020-logo/sz-2020-logo.component';
import { TeamBoxComponent } from './components/team-box/team-box.component';
import { TextComponent } from './components/text/text.component';
import { TiledCardsComponent } from './components/tiled-cards/tiled-cards.component';
import { TitleLeadLinkComponent } from './components/title-lead-link/title-lead-link.component';
import { TitleTextComponent } from './components/title-text/title-text.component';
import { TripCardsComponent } from './components/trip-cards/trip-cards.component';
import { TripCollectionComponent } from './components/trip-collection/trip-collection.component';
import { TripDayComponent } from './components/trip-day/trip-day.component';
import { TripInfoComponent } from './components/trip-info/trip-info.component';
import { TripProgramComponent } from './components/trip-program/trip-program.component';
import { TypeCardsComponent } from './components/type-cards/type-cards.component';
import { VideoPlayerNewComponent } from './components/video-player-new/video-player-new.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { WeatherInfoStaticComponent } from './components/weather-info-static/weather-info-static.component';
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component';
import { BackgroundColorDirective } from './directives/background-color.directive';
import { PhoneNumberInputDirective } from './directives/phone-number-input.directive';
import { TaxNumberFormatDirective } from './directives/tax-number-format.directive';
import { YearInputDirective } from './directives/year-input.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { FilesizePipe } from './pipes/filesize.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SsrEmptyStringPipe } from './pipes/ssr-empty-string.pipe';
import { TitleToIdPipe } from './pipes/title-to-id.pipe';
import { WeatherWidgetComponent } from './components/weather-widget/weather-widget.component';

const BODY_COMPONENTS = [
  AnchorBlockComponent,
  TitleTextComponent,
  TextComponent,
  PageHeadComponent,
  BigpicCarouselComponent,
  MoveAroundComponent,
  SeasonCardsComponent,
  PlanCardsComponent,
  TripCardsComponent,
  MapComponent,
  ArticleLeadComponent,
  MainCarouselComponent,
  ShareBarComponent,
  InfoCardsComponent,
  DetailedSliderComponent,
  ContentImageComponent,
  GalleryCarouselComponent,
  TripInfoComponent,
  TripDayComponent,
  ActivitiesComponent,
  LookAroundLinkComponent,
  DocumentGroupCardComponent,
  TripCollectionComponent,
  ArticleCollectionComponent,
  CenteredCarouselComponent,
  EventCollectionComponent,
  EventCollectionFilterComponent,
  PracticalInfoComponent,
  InstagramBlockComponent,
  PageHeadNewComponent,
  VideoPlayerComponent,
  ButtonComponent,
  WeatherInfoStaticComponent,
  PageHeadSimpleComponent,
  GinopHeaderComponent,
  GinopFooterComponent,
  SpiceMapComponent,
  SpiceOfEuropeLogoComponent,
  SpiceOfEuropeLinkComponent,
  FooterBottomComponent,
  SocialIconsComponent,
  BrandLogoComponent,
  ContactCollectionComponent,
  BusinessCardComponent,
  TeamBoxComponent,
  FaceCollectionComponent,
  LinkCollectionComponent,
  TitleLeadLinkComponent,
  FancyH1Component,
  FancyH2Component,
  FancyH3Component,
  H1PlusLeadComponent,
  InfoCardCollectionComponent,
  B2bCollectionComponent,
  Sz2020LogoComponent,
  PodcastComponent,
  YoutubePlayerComponent,
  VideoPlayerNewComponent,
  MarketResearchComponent,
  DestinationIconComponent,
  SpiceMapNewComponent,
  ApplicationCollectionComponent,
];

@NgModule({
  declarations: [
    DateFormatPipe,
    LinkCardComponent,
    CardGroupComponent,
    PageLeadComponent,
    RecommendationComponent,
    FilterCollectionComponent,
    FilterEventComponent,
    TiledCardsComponent,
    FilterItineraryComponent,
    BreadcrumbComponent,
    FullWidthMapComponent,
    DetailedCardComponent,
    ArticleContentComponent,
    GalleryCarouselComponent,
    ContentImageComponent,
    PlaceDetailsComponent,
    CardWDescComponent,
    MapButtonWidgetComponent,
    PopupComponent,
    PopupLoginComponent,
    TypeCardsComponent,
    MapFullWidthComponent,
    CookieBarComponent,
    LookAroundLinkComponent,
    TripProgramComponent,
    ...BODY_COMPONENTS,
    SsrEmptyStringPipe,
    SafePipe,
    FlagComponent,
    LanguageSwitcherComponent,
    BackgroundColorDirective,
    FilesizePipe,
    TaxNumberFormatDirective,
    YearInputDirective,
    PhoneNumberInputDirective,
    TitleToIdPipe,
    WeatherWidgetComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterModule,
    TrendencyUtilsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatIconModule,
    YouTubePlayerModule,
    TrendencyLoadingModule,
    NgOptimizedImage,
  ],
  exports: [
    TrendencyUtilsModule,
    TranslateModule,
    LocalizeRouterModule,
    PageLeadComponent,
    RecommendationComponent,
    FilterCollectionComponent,
    FilterEventComponent,
    TiledCardsComponent,
    LinkCardComponent,
    FilterItineraryComponent,
    BreadcrumbComponent,
    FullWidthMapComponent,
    DetailedCardComponent,
    ArticleContentComponent,
    GalleryCarouselComponent,
    ContentImageComponent,
    PlaceDetailsComponent,
    CardWDescComponent,
    MapButtonWidgetComponent,
    PopupComponent,
    PopupLoginComponent,
    TypeCardsComponent,
    MapFullWidthComponent,
    ActivitiesComponent,
    CookieBarComponent,
    LookAroundLinkComponent,
    RouterModule,
    DateFormatPipe,
    ...BODY_COMPONENTS,
    SsrEmptyStringPipe,
    SafePipe,
    FlagComponent,
    LanguageSwitcherComponent,
    BackgroundColorDirective,
    YouTubePlayerModule,
    FilesizePipe,
    TaxNumberFormatDirective,
    YearInputDirective,
    PhoneNumberInputDirective,
    TitleToIdPipe,
    WeatherWidgetComponent
  ],
  providers: [TitleToIdPipe],
})
export class SharedModule {}
