import { Component, Input } from '@angular/core';
import { TrendencyUtilsService } from '../../../../../trendency/utils';

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss'],
})
export class FooterBottomComponent {
  currentYear = new Date().getFullYear();
  @Input() isSocialActive = false;

  constructor(private readonly trendencyUtilsService: TrendencyUtilsService) {}

  getGeneratedSlug(slug: string): string {
    return this.trendencyUtilsService.generateSlug(slug);
  }
}
