import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouteMapService } from '../../../shared/services/route-map.service';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-base',
  templateUrl: 'base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  @ViewChild('header', { static: true }) header: HeaderComponent;

  toTopShown: boolean;
  headerMenu: any;
  footerMenu: any;

  private readonly destroy$ = new Subject();

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.OnScroll();
  }

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly routeMapService: RouteMapService) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      const menu = res?.['data'];
      if (menu?.length && menu[0]?.data) {
        this.headerMenu = {};
        this.footerMenu = {};
        Object.keys(menu[0].data).forEach((key) => {
          const tree: any[] = menu[0].data[key].map((menuItem: any) => {
            menuItem.children = menuItem.children.map((child: any) => this.addRouterLinkToMenuItems(child));
            return this.addRouterLinkToMenuItems(menuItem);
          });
          if (key.includes('header')) {
            const recommendations = menu.find((item: any) => item.groupKey === key);
            recommendations.data = recommendations.data.map((recommendationItem: any) => {
              return {
                ...this.addRouterLinkToMenuItems(recommendationItem),
                templateUrl: `url(${recommendationItem.thumbnail.url})`,
              };
            });
            const separatedTree: any = { left: [], right: [] };
            const separatorIndex = tree.findIndex((item) => item.relatedType === 'ColumnSeparator');
            if (separatorIndex > -1) {
              separatedTree.left = tree.slice(0, separatorIndex);
              separatedTree.right = tree.slice(separatorIndex + 1);
            } else {
              separatedTree.left = tree;
            }
            const recommendationsArrayHalf = Math.ceil(recommendations.data.length / 2);
            this.headerMenu[key] = {
              tree: separatedTree,
              recommendationsLeft: recommendations && recommendations.data.slice(0, recommendationsArrayHalf),
              recommendationsRight: recommendations && recommendations.data.slice(recommendationsArrayHalf, recommendations.length),
            };
          }
          if (key.includes('footer')) {
            this.footerMenu[key] = {
              tree,
            };
          }
        });
      }
    });
  }

  OnScroll(): void {
    const top = window.pageYOffset - 0;
    this.toggleHeadFix(top);
  }

  toggleHeadFix(top: number): void {
    const topTriggerPos = window.innerHeight * 1.5;
    this.toTopShown = top > topTriggerPos;
  }

  scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  addRouterLinkToMenuItems(menuItem: any): any {
    const subComponent = {
      contentType: { key: menuItem.relatedType },
      contentPage: { slug: menuItem.related && menuItem.related.slug },
    };
    return {
      ...menuItem,
      routerLink: menuItem.relatedType === 'CustomUrl' ? null : this.routeMapService.getRoute(subComponent),
      customUrl: menuItem.customUrl,
      targetBlank: menuItem.targetBlank,
    };
  }

  navContentChange(event: any): void {
    this.header.toggleNavContantOutCall(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
