import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';

declare let $: any;

@Component({
  selector: 'app-type-cards',
  templateUrl: './type-cards.component.html',
  styleUrls: ['./type-cards.component.scss'],
})
export class TypeCardsComponent implements OnInit, AfterViewInit {
  @Input() data: any = require('../../../../assets/mock_jsons/cards-k.json');
  @ViewChild('typeCardsObjPurpleElement') typeCardsObjPurpleElement: ElementRef;
  @ViewChild('typeCardsObjRedElement') typeCardsObjRedElement: ElementRef;
  @ViewChild('typeCardsObjGreenElement') typeCardsObjGreenElement: ElementRef;
  view = '';

  constructor(private readonly utilsService: TrendencyUtilsService) {}

  id = new Date().getTime() + Math.floor(Math.random() * 100000);
  options = {
    dots: true,
    autoplay: false,
    lazyLoad: 'ondemand',
    centerMode: true,
    slidesToShow: 2,
    speed: 200,
    autoplaySpeed: 3000,
    variableWidth: true,
    arrows: false,
    infinite: true,
    accessibility: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: false,
        },
      },
    ],
  };

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkView();
  }

  ngOnInit(): void {
    this.checkView();
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  checkView(): void {
    if (this.utilsService.isBrowser()) {
      if (window.innerWidth < 1024) {
        this.view = 'mobile';
      } else {
        this.view = 'desktop';
      }
      this.initSlick();
    }
  }

  initSlick(): void {
    $(this.typeCardsObjRedElement.nativeElement).slick(this.options);
    $(this.typeCardsObjGreenElement.nativeElement).slick(this.options);
    $(this.typeCardsObjPurpleElement.nativeElement).slick(this.options);
  }
}
