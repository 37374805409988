import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { RouteMapService } from '../../services/route-map.service';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-season-cards',
  templateUrl: './season-cards.component.html',
  styleUrls: ['./season-cards.component.scss'],
})
export class SeasonCardsComponent extends BaseComponent implements AfterViewInit, OnInit {
  @ViewChild('seasonCaruselElement', { static: true }) seasonCaruselElement: ElementRef;

  title: string;
  subtitle: string;
  thematicIconKey: string;
  cards: any[];

  constructor(private readonly routeMap: RouteMapService, private readonly utilsService: TrendencyUtilsService) {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject.title;
    this.subtitle = this.componentObject.subtitle;
    this.thematicIconKey = (this.componentObject.thematicIcon && this.componentObject.thematicIcon.key) || '';
    this.cards = this.componentObject.subComponents.map((card: any) => {
      const sc = card.subComponents && card.subComponents[0];
      return {
        ...card,
        customUrl: sc.customUrl,
        routerLink: this.routeMap.getRoute(sc),
        newTab: sc.newTab,
      };
    });
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  initSlick(): void {
    $(this.seasonCaruselElement.nativeElement).slick({
      dots: false,
      autoplay: false,
      lazyLoad: 'ondemand',
      centerMode: false,
      slidesToShow: 4,
      speed: 200,
      autoplaySpeed: 3000,
      variableWidth: true,
      arrows: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            slidesToShow: 1,
            infinite: true,
          },
        },
      ],
    });
  }
}
