<section class="practical-info">
  <div class="wrapper">
    <div class="info-items" [ngClass]="{ 'content-right': componentObject.leftRightPosition }">
      <div class="info-content">
        <h3 class="info-title">{{ componentObject.title }}</h3>
        <div class="info-lead content-text" [innerHTML]="componentObject.lead"></div>

        <a
          [href]="componentObject.buttonUrl"
          [target]="componentObject.targetBlank ? '_blank' : '_self'"
          *ngIf="componentObject.buttonUrl !== null"
        >
          <div class="button-wrapper">
            <button class="button" [appBackgroundColor]="'red'">{{ componentObject.buttonLabel }}</button>
          </div>
        </a>
      </div>
      <div class="info-separator"></div>
      <div class="info-media" id="{{ componentObject | titleToId }}">
        <i [ngClass]="'info-icon icon ' + componentObject.practicalInfoIcon?.key" *ngIf="componentObject.practicalInfoIcon !== null"></i>
      </div>
    </div>
  </div>
</section>
