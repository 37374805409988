import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTaxNumberFormat]',
})
export class TaxNumberFormatDirective {
  constructor(private readonly ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const updatedValue = (event.currentTarget as HTMLInputElement)?.value?.replace(/[^0-9]/g, '') || '';
    this.ngControl.control?.setValue(updatedValue, { emitEvent: false });

    if (updatedValue && updatedValue.length >= 8 && updatedValue.length < 13) {
      const taxNumberPattern = /^(\d{8})(\d{1})(\d{2})$/;
      const formattedValue = updatedValue.replace(taxNumberPattern, '$1-$2-$3');
      if (event.currentTarget instanceof HTMLInputElement) {
        (event.currentTarget as HTMLInputElement).value = formattedValue;
      }
      this.ngControl.control?.setValue(formattedValue, { emitEvent: false });
    } else {
      event.preventDefault();
    }
  }
}
