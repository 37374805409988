<section class="face-collection" *ngIf="cards?.length">
  <div class="container">
    <h2 class="face-collection__title" *ngIf="title">{{ title }}</h2>
    <span class="face-collection__subtitle" *ngIf="subtitle">{{ subtitle }}</span>

    <div class="face-collection__content">
      <div class="fc-card" *ngFor="let card of cards">
        <img
          *ngIf="card?.image?.url"
          [ngSrc]="card.image.url"
          [alt]="card.image.image.title"
          class="fc-card__image"
          [width]="card.image.width"
          [height]="card.image.height"
        />
        <div class="fc-card__content">
          <div class="fc-card__top">
            <span class="fc-card__name" *ngIf="card.name">{{ card.name }}</span>
            <span class="fc-card__title" *ngIf="card.title">{{ card.title }}</span>
          </div>
          <div class="fc-card__bottom">
            <div class="fc-card__hover-info">
              <a [href]="'mailto:' + card.email" class="fc-card__email" *ngIf="card.email">{{ card.email }}</a>
              <a [href]="'tel:' + card.phone" class="fc-card__phone" *ngIf="card.phone">{{ card.phone }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
