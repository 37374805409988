import { Component, OnInit } from '@angular/core';
import { IFaceCard } from '../../shared.definitions';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-face-collection',
  templateUrl: './face-collection.component.html',
  styleUrls: ['./face-collection.component.scss'],
})
export class FaceCollectionComponent extends BaseComponent implements OnInit {
  title: string;
  subtitle: string;
  cards: IFaceCard[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.title = this.componentObject?.title;
    this.subtitle = this.componentObject?.subtitle;
    this.cards = this.componentObject?.subComponents;
  }
}
