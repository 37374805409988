import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { RouteMapService } from '../../services/route-map.service';
import { BaseComponent } from '../base.component';

declare let $: any;

@Component({
  selector: 'app-spice-map',
  templateUrl: './spice-map.component.html',
  styleUrls: ['./spice-map.component.scss'],
})
export class SpiceMapComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('carouselFirst', { static: true }) carouselFirst: ElementRef;
  @ViewChild('carouselSecond', { static: true }) carouselSecond: ElementRef;

  lang: string;
  destinations: any[];
  destinationsBudapest: any[];
  destinationsGreaterBudapest: any[];
  isBudapest = true;
  greaterBudapestRoutes = ['airport', 'dunakanyar', 'etyek', 'godollo', 'szentendre', 'velence'];
  budapestRoutes = [
    'buda-hills',
    'obuda',
    'margaret-island',
    'parliament',
    'city-park',
    'castle',
    'jewish-quarter',
    'palace-quarter',
    'gellert',
    'palace-arts',
  ];

  constructor(
    private readonly utilsService: TrendencyUtilsService,
    private readonly translate: TranslateService,
    private readonly routeMap: RouteMapService
  ) {
    super();
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.initDestinations();
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick(this.carouselFirst.nativeElement);
    }
  }

  initDestinations(): void {
    this.destinationsBudapest = [];
    this.destinationsGreaterBudapest = [];

    if (this.componentObject?.subComponents) {
      for (const dest of this.componentObject.subComponents) {
        const sc = dest?.subComponents && dest?.subComponents[0];
        const slug = dest?.greaterBudapestDestination?.slug;
        const obj = {
          ...dest,
          className: slug,
          routerLink: this.routeMap.getRoute(sc),
          customUrl: sc?.customUrl,
          newTab: sc?.newTab,
        };

        if (this.greaterBudapestRoutes.includes(slug)) {
          this.destinationsGreaterBudapest.push(obj);
        }

        if (this.budapestRoutes.includes(slug)) {
          this.destinationsBudapest.push(obj);
        }
      }
    }

    if (this.componentObject.destinationLisztFerencInternationalAirport) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.destinationLisztFerencInternationalAirport,
        className: 'airport',
      });
    }
    if (this.componentObject.destinationDanubeBand) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.destinationDanubeBand,
        className: 'dunakanyar',
      });
    }
    if (this.componentObject.destinationEtyek) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.destinationEtyek,
        className: 'etyek',
      });
    }
    if (this.componentObject.destinationGodollo) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.destinationGodollo,
        className: 'godollo',
      });
    }
    if (this.componentObject.destinationEtyek) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.destinationEtyek,
        className: 'szentendre',
      });
    }
    if (this.componentObject.destinationLakeVelence) {
      this.destinationsGreaterBudapest.push({
        ...this.componentObject.destinationLakeVelence,
        className: 'velence',
      });
    }
    if (this.componentObject.destinationBudapestBudaHills) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestBudaHills,
        className: 'buda-hills',
      });
    }
    if (this.componentObject.destinationBudapestObuda) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestObuda,
        className: 'obuda',
      });
    }
    if (this.componentObject.destinationBudapestMargaretIsland) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestMargaretIsland,
        className: 'margaret-island',
      });
    }
    if (this.componentObject.destinationBudapestParliamentAndDowntown) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestParliamentAndDowntown,
        className: 'parliament',
      });
    }
    if (this.componentObject.destinationBudapestAndrassyAvenueCityPark) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestAndrassyAvenueCityPark,
        className: 'city-park',
      });
    }
    if (this.componentObject.destinationBudapestCasteDistrict) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestCasteDistrict,
        className: 'castle',
      });
    }
    if (this.componentObject.destinationBudapestJewishQuarter) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestJewishQuarter,
        className: 'jewish-quarter',
      });
    }
    if (this.componentObject.destinationBudapestPalaceQuarter) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestPalaceQuarter,
        className: 'palace-quarter',
      });
    }
    if (this.componentObject.destinationBudapestGellertHillAndTaban) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestGellertHillAndTaban,
        className: 'gellert',
      });
    }
    if (this.componentObject.destinationBudapestPalaceOfArts) {
      this.destinationsBudapest.push({
        ...this.componentObject.destinationBudapestPalaceOfArts,
        className: 'palace-arts',
      });
    }
    this.destinations = this.destinationsBudapest;
  }

  changeMap(): void {
    if (this.isBudapest) {
      this.isBudapest = false;
      this.destinations = this.destinationsGreaterBudapest;
    } else {
      this.isBudapest = true;
      this.destinations = this.destinationsBudapest;
    }
  }

  changeMapMobile(isBudapest: boolean): void {
    if (isBudapest !== this.isBudapest) {
      this.isBudapest = isBudapest;
      this.initSlick(this.carouselSecond.nativeElement);
      if (this.isBudapest) {
        this.destinations = this.destinationsBudapest;
      } else {
        this.destinations = this.destinationsGreaterBudapest;
      }
    }
  }

  initSlick(element: any): void {
    $(element).not('.slick-initialized').slick({
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      infinite: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      nextArrow: false,
      prevArrow: false,
    });
  }
}
