import { Component } from '@angular/core';

@Component({
  selector: 'app-filter-event',
  templateUrl: './filter-event.component.html',
  styleUrls: ['./filter-event.component.scss'],
})
export class FilterEventComponent {
  filterOpened = [false, false, false];

  toggleFilter(id: number): void {
    this.filterOpened[id] = !this.filterOpened[id];

    for (let i = 0; i < this.filterOpened.length; i++) {
      if (i !== id) {
        this.filterOpened[i] = false;
      }
    }
  }

  closeAllFilter(): void {
    for (let i = 0; i < this.filterOpened.length; i++) {
      this.filterOpened[i] = false;
    }
  }
}
