import { Directive, AfterViewInit, Input, ElementRef } from '@angular/core';
import { TrendencyUtilsService } from '../../utils/services/utils.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[trendencyIframeLazyLoad]'
})
export class TrendencyIframeLazyLoadDirective implements AfterViewInit {

  private src: string;

  constructor(
    private el: ElementRef,
    private utilsService: TrendencyUtilsService
  ) { }

  @Input()
  set youtubeSrc(src: string) {
    this.src = src;
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.src = this.utilsService.convertYouTubeLinkToEmbed(this.src) + '?autoplay=1';
      if (this.src) {
        const id = this.src.split('embed/')[1] && this.src.split('embed/')[1].substr(0, 11);
        if (id) {
          const thumbnailImage = `http://img.youtube.com/vi/${id}/sddefault.jpg`;
          const image = new Image();
          image.src = thumbnailImage;
          image.style.maxWidth = window.getComputedStyle(this.el.nativeElement).getPropertyValue('width');
          image.style.maxHeight = window.getComputedStyle(this.el.nativeElement).getPropertyValue('height');
          image.onload = () => {
            this.el.nativeElement.appendChild(image);
            this.el.nativeElement.classList.add('trendency-iframe-loader-placeholder');
          };
          this.el.nativeElement.addEventListener('click', () => this.createIframe());
        }
      }
    }
  }

  private createIframe() {
    this.el.nativeElement.classList.remove('trendency-iframe-loader-placeholder');
    const iframe = document.createElement('iframe');
    iframe.allowFullscreen = true;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.src = this.src;
    this.el.nativeElement.innerHTML = '';
    this.el.nativeElement.appendChild(iframe);
  }
}
