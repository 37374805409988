<section class="b2b-collection">
  <div class="container">
    <div class="b2b-collection__controls">
      <div class="b2b-collection__inputs">
        <label class="cdk-visually-hidden" for="search">{{ 'SEARCH.keyword' | translate }}</label>
        <input type="search" id="search" [formControl]="search" [placeholder]="'SEARCH.keyword' | translate" />
      </div>

      <div class="b2b-collection__filters" *ngIf="filters?.length">
        <button *ngFor="let filter of filters" (click)="handleFilter(filter)" [class.checked]="filter.checked">{{ filter.name }}</button>
      </div>
    </div>

    <div class="row" *ngIf="data?.length">
      <div class="col-6 col-lg-4" *ngFor="let card of data">
        <app-card-w-desc [data]="card" [isFullWidth]="true"></app-card-w-desc>
      </div>
    </div>

    <button class="button button-primary" *ngIf="isLoadMoreButtonNeed" (click)="onClickLoadMore()">
      {{ 'label.load-more' | translate }}
    </button>
  </div>
</section>
