import { Routes } from '@angular/router';
import { BaseComponent } from './core/components/base/base.component';
import { BaseResolver } from './core/components/base/base.resolver';

export const appRoutes: Routes = [
  {
    path: 'b2b',
    loadChildren: () => import('src/app/features/b2b/b2b.module').then((m) => m.B2bModule),
  },
  {
    path: 'international-mice-cocktail',
    loadChildren: () => import('src/app/features/mice-cocktail/mice-cocktail.module').then((m) => m.MiceCocktailModule),
  },
  {
    path: 'b2b-events',
    loadChildren: () => import('src/app/features/ginop/ginop.module').then((m) => m.GinopModule),
  },
  {
    path: '',
    component: BaseComponent,
    resolve: { data: BaseResolver },
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'kiemeltpartner',
        loadChildren: () => import('src/app/features/priority-partner/priority-partner.module').then((m) => m.PriorityPartnerModule),
      },
      {
        path: 'weather',
        loadChildren: () => import('src/app/features/weather-page/weather-page.module').then((m) => m.WeatherPageModule),
      },
      {
        path: '',
        loadChildren: () => import('src/app/features/form-elements/form-elements.module').then((m) => m.FormElementsModule),
      },
      {
        path: 'urlap-lista',
        loadChildren: () => import('src/app/features/form-list/form-list.module').then((m) => m.FormListModule),
      },
      {
        path: 'articles/:slug',
        loadChildren: () => import('src/app/features/article-page/article-page.module').then((m) => m.ArticlePageModule),
      },
      {
        path: 'events/:slug',
        loadChildren: () => import('src/app/features/event-detail-page/event-detail-page.module').then((m) => m.EventDetailPageModule),
      },
      {
        path: 'trips/:slug',
        loadChildren: () => import('src/app/features/trip/trip.module').then((m) => m.TripModule),
      },
      {
        path: 'collection/:slug',
        loadChildren: () => import('src/app/features/collection-page/collection-page.module').then((m) => m.CollectionPageModule),
      },
      {
        path: 'category/:slug',
        loadChildren: () =>
          import('src/app/features/category-collection-page/category-collection-page.module').then((m) => m.CategoryCollectionPageModule),
      },
      {
        path: 'downloads',
        loadChildren: () => import('src/app/features/downloads/downloads.module').then((m) => m.DownloadsModule),
      },
      {
        path: 'downloads/:type/:slug',
        loadChildren: () => import('src/app/features/download-list/download-list.module').then((m) => m.DownloadListModule),
      },
      {
        path: 'destination/:slug',
        loadChildren: () => import('src/app/features/destination-page/destination-page.module').then((m) => m.DestinationPageModule),
      },
      {
        path: 'search',
        children: [
          {
            path: '',
            loadChildren: () => import('src/app/features/search/search.module').then((m) => m.SearchModule),
          },
        ],
      },
      {
        path: 'attraction-finder',
        children: [
          {
            path: '',
            loadChildren: () => import('src/app/features/attraction-finder/attraction-finder.module').then((m) => m.AttractionFinderModule),
          },
        ],
      },
      {
        path: 'bucket-list',
        loadChildren: () => import('src/app/features/bucket-list/bucket-list.module').then((m) => m.BucketListModule),
      },
      {
        path: '404',
        loadChildren: () => import('src/app/core/components/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
      },
      {
        path: ':slug',
        loadChildren: () => import('src/app/features/static-page/static-page.module').then((m) => m.StaticPageModule),
      },
      {
        path: '**',
        data: { skipRouteLocalization: true },
        redirectTo: '404',
      },
    ],
  },
];
