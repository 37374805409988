<div class="social-icons" [class.rounded-desktop]="isRoundedDesktop" [class.rounded-mobile]="isRoundedMobile">
  <a [href]="(footerData$ | async).youtubeUrl | safe : 'url'" target="_blank" aria-label="Youtube" *ngIf="(footerData$ | async)?.youtubeUrl"
    ><i class="icon icon-youtube-white"></i
  ></a>
  <a
    [href]="(footerData$ | async).facebookUrl | safe : 'url'"
    target="_blank"
    aria-label="Facebook"
    *ngIf="(footerData$ | async)?.facebookUrl"
    ><i class="icon icon-facebook-white"></i
  ></a>
  <a [href]="(footerData$ | async).twitterUrl | safe : 'url'" target="_blank" aria-label="Twitter" *ngIf="(footerData$ | async)?.twitterUrl"
    ><i class="icon icon-twitter-white"></i
  ></a>
  <a
    [href]="(footerData$ | async).instagramUrl | safe : 'url'"
    target="_blank"
    aria-label="Instagram"
    *ngIf="(footerData$ | async)?.instagramUrl"
    ><i class="icon icon-instagram-white"></i
  ></a>
  <a
    [href]="(footerData$ | async).linkedinUrl | safe : 'url'"
    target="_blank"
    aria-label="LinkedIn"
    *ngIf="(footerData$ | async)?.linkedinUrl && isShowLinkedIn"
    ><i class="icon icon-linkedin-white"></i
  ></a>
</div>
