<section class="article-content">
  <div class="wrapper">
    <div class="left">
      <div class="content">
        <p>
          In reality, the Solomon Tower is part of the whole, too, which can be found on the base of the Várhegy as the Lower Castle.
          Nowadays, it requires some imagination to connect the two different sights which are even treated separately. The reconstructed
          layout gives a little help with this.
          <br /><br />
          In every year, thousands of visitors goes to the Citadel, the highest sight of Visegrád, which has an extraordinary view on the
          Danube Bend from its terrace and which hosts many exciting exhibitions – mainly about the Middle Ages.
        </p>
        <h3>History of Visegrad</h3>
        <p>
          Yet, why most visitors of Visegrád know and really adore the Citadel is the panorama from the top.
          <br /><br />
          From the peak of the mountain, you get a view on the city and the Danube Bend that you will never forget for the rest of your life
          for sure. And if you have been here before, you just have to walk out to the terrace of the Citadel to be gripped by the
          unforgettable experience again.
        </p>
      </div>
    </div>
    <div class="right"></div>
  </div>
</section>
