import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from '../../../core/components/base/base.service';

@Component({
  selector: 'app-sz-2020-logo',
  templateUrl: './sz-2020-logo.component.html',
  styleUrls: ['./sz-2020-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Sz2020LogoComponent implements OnInit, OnDestroy {
  isStatic = false;
  sub: Subscription;
  constructor(private readonly baseService: BaseService, private readonly router: Router) {}

  get sz2020Logo$(): Observable<boolean> {
    return this.baseService.sz2020Logo$;
  }

  ngOnInit(): void {
    this.sub = this.router.events.subscribe((event) => {
      if (
        this.baseService.isSz2020LogoActive() &&
        (event instanceof NavigationError ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof ActivationEnd)
      ) {
        this.baseService.setSz2020Logo(false);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const footer = document.querySelector('footer') as HTMLElement;
    const bodyHeight = document.documentElement.offsetHeight;
    const current = document.documentElement.scrollTop;
    this.isStatic = bodyHeight + current > footer.offsetTop;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
