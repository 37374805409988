<section class="contact-collection">
  <div class="container">
    <h2 class="contact-collection__title" *ngIf="title">{{ title }}</h2>
    <span class="contact-collection__subtitle" *ngIf="subtitle">{{ subtitle }}</span>

    <div class="contact-collection__content" *ngIf="cards" [class.centered]="cards.length === 1">
      <app-business-card *ngFor="let card of cards" [card]="card"></app-business-card>
    </div>

    <div #contactSlider class="contact-collection__slider">
      <ng-container *ngIf="cards?.length">
        <app-business-card *ngFor="let card of cards" [card]="card"></app-business-card>
      </ng-container>
    </div>
  </div>
</section>
