<div class="page-head">
  <div [ngStyle]="{ backgroundImage: 'url(' + imageUrl + ')' }" class="page-head__image"></div>
  <div class="custom-gradient"></div>
  <div class="wrapper">
    <h1 class="heading">
      <span class="title">{{ title }}</span>
      <span id="icon-arrow" class="icon icon-arrow-down-white"></span>
    </h1>
    <div [ngClass]="'specicon ' + thematicIconKey" class="specicon"></div>
  </div>
</div>
