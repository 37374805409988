import { Location } from '@angular/common';
import { Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../src/environments/environment';
import {LocalizeParser, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';

export class LocalizeUniversalLoader extends LocalizeParser {

  constructor(
    translateService: TranslateService,
    location: Location,
    localizeRouterSettings: LocalizeRouterSettings,
  ) {
    super(translateService, location, localizeRouterSettings);
  }

  public load(routes: Routes): Promise<any> {
    return new Promise(resolve => {
      this.locales = environment.translation && environment.translation.locales;
      this.prefix = environment.translation && environment.translation.prefix;
      this.init(routes).then(resolve);
    });
  }
}

export function localizeLoaderFactory(
  translateService: TranslateService, location: Location, localizeRouterSettings: LocalizeRouterSettings) {
    return new LocalizeUniversalLoader(translateService, location, localizeRouterSettings);
}

export function setDefaultLanguage(languages: string[]) {
  return languages[0];
}
