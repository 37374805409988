import { Pipe, PipeTransform } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';

@Pipe({
  name: 'ssrEmptyString',
})
export class SsrEmptyStringPipe implements PipeTransform {
  constructor(private readonly utilsService: TrendencyUtilsService) {}

  transform(value: any): any {
    if (!this.utilsService.isBrowser()) {
      return '';
    }
    return value;
  }
}
