<div class="map-button-wrapper" *ngIf="hasMapData()">
  <div #gmap class="map-container"></div>

  <a [href]="buttonUrl" class="map-link" target="_blank" *ngIf="hasMapData()">
    <button class="button">
      <span class="inner-button" [appBackgroundColor]="'red'">
        <i class="icon icon-pin-white"></i>
        {{ buttonLabel }}
      </span>
    </button>
  </a>
</div>
