import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  @ViewChild('video', { static: false }) video: ElementRef<HTMLVideoElement>;
  _url: string;
  type: string;

  @Input() autoplay = true;

  @Input() set url(newUrl: string) {
    this.setVideoTypeByUrl(newUrl);
    this._url = newUrl;
  }

  get url(): string {
    if (!this.utilsService.isBrowser()) {
      return '';
    }
    return this._url;
  }

  constructor(private readonly utilsService: TrendencyUtilsService) {}

  pause(): void {
    const videoElement = this.video.nativeElement;
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }

  setVideoTypeByUrl(url: string): void {
    if (url) {
      this.type = url.split('.').pop() as string;
    }
  }
}
