import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ginop-header',
  templateUrl: './ginop-header.component.html',
  styleUrls: ['./ginop-header.component.scss'],
})
export class GinopHeaderComponent implements OnInit {
  @Input() small = false;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() image: any;
  imageUrl: string;
  imageAlt: string;

  ngOnInit(): void {
    if (this.image && typeof this.image === 'string') {
      this.imageUrl = this.image;
    }
    if (this.image && typeof this.image === 'object' && this.image?.value?.url) {
      this.imageUrl = this.image.value.url;
      this.imageAlt = this.image?.value?.image?.title;
    }
  }
}
